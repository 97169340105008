<section class="title-bar insurance-bar">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-7 left-text">
                <h2 data-aos="fade-up" data-aos-delay="200">{{ window.dictionary.get("tutte_convenzioni") }}</h2>
            </div>
        </div>
    </div>
</section>
<section class="sec-inner-banner" data-aos="fade-up">
    <img [src]="getCover()" alt="">
</section>
<section class="sec-insurance-boxes" data-aos="fade-up">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4 aos-init aos-animate sm-box" *ngFor="let punto_forza of getInfoGenerali('punti_forza'); index as i" data-aos="fade-up" data-aos-delay="{{(i+1)*100}}">
                <h3><i class="fas fa-badge-check text-3xl text-brand mr-3"></i> {{punto_forza.title}}</h3>
                <p>{{punto_forza.description}}</p>
            </div>
        </div>
    </div>
</section>
<section class="sec-assurance" *ngIf="window.info_data.visualizza_convenzioni">
    <div class="container">
        <h2 class="sec-title text-center aos-init aos-animate" data-aos="fade-up">{{ window.dictionary.get("nostre_convenzioni") }}</h2>
        <div class="row d-flex justify-content-center">
            <div class="col-12" *ngFor="let convenzione of getConvenzioni().slice().reverse(); index as i">
                <app-convenzione-card [convenzione]="convenzione" [box]="true"></app-convenzione-card>
            </div>
        </div>
    </div>
</section>
<section class="sec-faq-question" data-aos="fade-up" [hidden]="!getInfoGenerali('faqs').length">
    <div class="container">
        <h2 class="sec-title text-center aos-init aos-animate" data-aos="fade-up">{{window.dictionary.get("domande_frequenti")}}</h2>
        <div class="faq-question">
            <div class="accordion" id="faq">
                <div class="card" *ngFor="let faq of getInfoGenerali('faqs'); index as i">
                    <div class="card-header" id="faqhead{{i+1}}" (click)="collapseFaq[i] = !collapseFaq[i]">
                        <a class="btn btn-header-link cursor-pointer"  [ngClass]="{'collapsed': !collapseFaq[i]}" data-toggle="collapse" data-target="#faq{{i+1}}"
                        aria-expanded="false" aria-controls="faq{{i+1}}">{{faq.title}}</a>
                    </div>

                    <div id="faq{{i+1}}" class="collapse" [ngClass]="{'collapsed': !collapseFaq[i], 'show': !!collapseFaq[i]}" aria-labelledby="faqhead{{i+1}}" data-parent="#faq">
                        <div class="card-body">{{faq.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pre-footer">
    <div class="container">
        <div class="banr-icon">
            <img src="./assets/icon/robot.svg" alt="">
        </div>
        <h5 data-aos="fade-up">{{window.dictionary.get("assicurazione_digitale")}}</h5>
        <div class="inline-btn">
            <a href="tel:{{window.info_data.telefono1}}" class="btn btn-outline-dark" data-aos="fade-up" data-aos-delay="150"><i class="mr-1 fa-fas fa-phone fal"></i>
                {{window.info_data.telefono1}}
            </a>
            <a [routerLink]="'/professioni'" class="btn btn-outline-primary" data-aos="fade-up" data-aos-delay="300">{{window.dictionary.get("vedi_mestieri")}}</a>
        </div>
    </div>
</section>