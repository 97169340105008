import { Component } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent {
    window:any=<any>window;
    AOS:any=<any>this.window.AOS;
    queryparams:any = () => decodeURI(this.window.location.href)
      .split('?')[1]?.split('&')
      .map(param => param.split('='))
      .reduce((values, [ key, value ]) => {
          values[ key ] = value
          return values
      }, {}) || {};
  
    changeQueryParam:any = (values:any = {}) => new Object({ ...this.queryparams(), ...values });
    filter:any = {
        text: ""
    };
    tags:any = [];
    limit_page = 12;
    constructor() {}

    getNews = (pt:any = false) => this.window.collection_data?.filter(b => b.configuration.type == 'blog' && 
        (!pt || b.public_token == pt) && 
        (!this.filter.text || b.configuration.title?.toLowerCase()?.includes(this.filter.text.toLowerCase())) && 
        (!this.tags.length || b.configuration.tags?.some(t => this.tags.includes(t))))
        ?.sort((a:any, b:any) => a.creation_timestamp > b.creation_timestamp ? 1 : a.creation_timestamp < b.creation_timestamp ? -1 : 0) || [];

    getTags = () => this.window.collection_data?.filter(b => b.configuration.type == 'blog')?.reduce((a:any, b:any) => {
        a = a || [];
        a.push(...b.configuration.tags?.filter(e => !a.includes(e)));
        return a;
    }, []) || [];

    ngAfterContentInit(): void {
        this.AOS.init({
            disable: 'mobile',
            duration: 800,
            anchorPlacement: 'center-bottom',
            once: true,
        });
        this.window.scrollTo(0,0);
    };

    selectTag = (tag:any = false) => {
        if(!tag) return;
        let index = this.tags.indexOf(tag);
        if(index < 0 || isNaN(index)) this.tags.push(tag);
        else this.tags.splice(index, 1);
    };

    getPage = () => this.queryparams().blog_page || 0;

    getPages = () => this.getNews().reduce((a:any, b:any, i:any) => {
        a = a || [];
        const index = Math.floor(i/this.limit_page);
        a[index] = a[index] || [];
        a[index].push(b);
        return a;
    }, []) || [];

    getPagination = () => {
        const pages = this.getPages(),
            start = Math.max(0, this.getPage()-2),
            end = Math.min(pages.length-1, this.getPage()+2);
        return [...Array(end - start + 1).keys()].map(x => x + start);
    };
}