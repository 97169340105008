import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-convenzione-modal',
  templateUrl: './convenzione-modal.component.html',
  styleUrls: ['./convenzione-modal.component.css']
})
export class ConvenzioneModalComponent {
    window:any=<any>window;
    $:any=<any>this.window.$;
    rand_pacchetto_img:any = "";
    convenzione:any = {
        name: "",
        configuration: {
          documents: [],
          professioni: []
        }
    };

    show = (selector:any) => this.$(selector).fadeIn(300);
    hide = (selector:any) => this.$(selector).fadeOut(300);

    getLink = (link:any) => `${this.window.config.urls.url_media}/get.php?private_token=${this.window.usher.getPrivateToken()}&name=${link}`;
    
    getPicture = (name:any) => name ? `${this.window.config.urls.url_media}/public/${name}` : './assets/icon/robot.svg';

    constructor(
        private route:ActivatedRoute,
        private router:Router
    ) {
        this.window.plussimple_signal.signaller.addEventListener('convenzione', (ev:any) => {
            this.convenzione = this.window.collection_data.find(e => e.configuration.type == 'convenzione' && e.public_token == ev.detail.public_token) || this.convenzione;
            
        });
    }

    ngAfterContentInit() {
        this.rand_pacchetto_img = this.getImagePacchetto();
        this.route.queryParams.subscribe((p:any) => this.closeModal());
    }

    closeModal = () => this.$("#Convenzione_modal").modal('hide');

    getPacchetti = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'pacchetto' && (!pt || b.public_token == pt)) || [];

    //getProfessioni = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'professione' && (!pt || b.public_token == pt)) || [];

    getImagePacchetto = () => {
        this.convenzione.configuration.pacchetti = this.convenzione.configuration.pacchetti || [];
        let len = this.convenzione.configuration?.pacchetti?.length || 1,
            ind = Math.floor((Math.random()*1000)%len);
        return this.getPacchetti(this.convenzione.configuration.pacchetti[ind])[0]?.configuration?.cover;
    };

    /*getPacchettiProfessioni = (pt:any = undefined) => this.convenzione.configuration.professioni.reduce((a:any, b:any) => {
        a = a || [];
        a.push(...this.getPacchetti().filter(e => e.configuration.professioni.includes(b) && !a.find(el => el.public_token == e.public_token)));
        return a;
    }, []) || [];*/

    getPacchettiConvenzione = () => this.convenzione.configuration.pacchetti?.map(e => this.getPacchetti(e)[0]) || [];

    getDocuments = () => {
        //if(!this.window.usher.isLogged() || !this.window.usher.get()?.manager_configuration?.convenzioni?.includes(this.convenzione.public_token)) return [];
        return this.convenzione.configuration.documents;
    };

    openQuestionario = (pacchetto:any) => this.window.plussimple_signal.emit({name: 'questionario', detail: { pacchetto: pacchetto }});

}