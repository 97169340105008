<div class="modal fade lg-modal-custom" id="Convenzione_modal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal"><i class="fa fa-long-arrow-left"></i></button>
            </div>
            <div class="modal-body">
                <h3>{{ convenzione.name }}</h3>
                <div class="modal-banr">
                    <div class="modal-bg-img">
                        <img [src]="getPicture(rand_pacchetto_img)" alt="">
                    </div>
                    <!--<div class="modal-overlay text-center">
                        <h4>{{ convenzione.name }}</h4>
                    </div>-->
                </div>
                <div class="img-card">
                    <ul>
                        <li *ngFor="let pacchetto of getPacchettiConvenzione().slice().reverse()">
                            <div class="top-img cursor-pointer" (click)="openQuestionario(pacchetto)">
                                <img [src]="'assets/img/icons-colored/' + pacchetto.configuration.icon" alt="">
                            </div>
                            <div class="card-detail cursor-pointer" (click)="openQuestionario(pacchetto)">
                                <p>{{ pacchetto.name }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="modal-details text-center">
                    <!--<h2>{{ convenzione.name }}</h2>-->
                    <p id="convenzione-description" [innerHtml]="convenzione.configuration.description" class="text-justify"></p>
                </div>
                <div class="modal-logo text-center">
                    <a routerLink="">
                        <img [src]="getPicture(convenzione.configuration.image)" alt="">
                    </a>
                </div>

                <div>
                    <a target="_blank" [download]="document.name" [href]="getLink(document.name)" *ngFor="let document of getDocuments(); index as i">
                        <div class="col-12 col-md-4 sm-box">
                            <h3><i class="text-3xl fal pr-3 fas fa-paperclip mr-2"></i>{{ document.name }}</h3>
                        </div>
                    </a>
                </div>
                
            </div>
        </div>
    </div>
</div>