import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-convenzione-documents',
  templateUrl: './convenzione-documents.component.html',
  styleUrls: ['./convenzione-documents.component.css']
})
export class ConvenzioneDocumentsComponent {
  window:any=<any>window;
  AOS:any=<any>this.window.AOS;
  $:any=<any>this.window.$;

  convenzione_model:any = {
    name: "",
    configuration: {
      documents: []
    }
  };
  constructor(
    private route:ActivatedRoute,
    private router:Router
    ) {
    this.window.plussimple_signal.signaller.addEventListener('convenzione', (ev:any) => {
      this.convenzione_model = this.window.collection_data.find(e => e.configuration.type == 'convenzione' && e.public_token == ev.detail.public_token) || this.convenzione_model;
    });
  }

  ngAfterContentInit(): void {
    this.arrangeView();
    this.route.queryParams.subscribe((p:any) => this.closeModal());
  }

  queryparams:any = () => decodeURI(this.window.location.search)
    .replace('?', '')
    .split('&')
    .map(param => param.split('='))
    .reduce((values, [ key, value ]) => {
      values[ key ] = value
      return values
  }, {});

  arrangeView() {
    this.AOS.init({
      disable: 'mobile',
      duration: 800,
      anchorPlacement: 'center-bottom',
      once: true,
    });
  };

  getConvenzione = () => this.convenzione_model;

  getLink = (doc:any) => `${this.window.config.urls.url_media}/get.php?private_token=${this.window.usher.getPrivateToken()}&name=${doc.link}`;

    downloadDocument = (doc:any) => this.window.open(this.getLink(doc), "_blank");

  openModal = () => this.$("app-convenzione-documents").fadeIn(300);
  closeModal = () => this.$("app-convenzione-documents").fadeOut(300);

    getDocuments = () => {
        if(!this.window.usher.isLogged() || !this.window.usher.get()?.manager_configuration?.convenzioni?.includes(this.getConvenzione().public_token)) return [];
        return this.getConvenzione().configuration.documents;
    };

  show = (selector:any) => this.$(selector).fadeIn(300);
  hide = (selector:any) => this.$(selector).fadeOut(300);
}