import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.css']
})
export class NewsCardComponent {
    @Input() news:any = {};
    
    window:any = <any>window;
    queryparams:any = () => decodeURI(this.window.location.href)
      .split('?')[1]?.split('&')
      .map(param => param.split('='))
      .reduce((values, [ key, value ]) => {
          values[ key ] = value
          return values
      }, {}) || {};
  
    changeQueryParam:any = (values:any = {}) => new Object({ ...this.queryparams(), ...values });
    constructor() {}

    getHumanizedDate = (timestamp) => this.window.moment.duration(this.window.moment(timestamp) - this.window.moment()).humanize(true) || "";

    getTags = () => this.news.configuration.tags?.reduce((a:any, b:any) => {
        a = a || ``;
        if(!!a) a += " - ";
        a += b;
        return a;
    }, ``) || "";
}