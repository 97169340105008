<section class="title-bar">
    <div class="container">
        <h1 data-aos="fade-up" data-aos-delay="100">{{window.dictionary.get("area_cliente")}}</h1>
        <h2 data-aos="fade-up" data-aos-delay="200">{{ getNome() }}</h2>
    </div>
</section>

<section class="sec-profile">
    <div class="container">
        <div class="profile-row">
            <div class="profile-column sm-column" data-aos="fade-up" data-aos-delay="100">
                <div class="profile-sidebar">
                    <div class="profile-user-box">
                        <div class="profile-img">
                            <label class="m-auto d-table" for="profile_img">
                                <img width="200" height="200" class="profile-img" [src]="getLink()">
                            </label>
                            <input type="file" class="d-none" id="profile_img" (change)="getProfileImage($event)" />
                        </div>
                    </div>	
                    <div class="profile-user-content">
                        <div class="profile-accordion">
                            <div class="accordion-row" class="mb-2" *ngIf="window.info_data.visualizza_convenzioni">
                                <a role="button" (click)="show('app-new-convenzione')" class="cursor-pointer">{{window.dictionary.get("aggiungi_convenzione")}} +</a>
                            </div>
                            <div class="accordion-row">
                                <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" (click)="info_expanded = !info_expanded">
                                    {{window.dictionary.get("informazioni")}} <i class="fa" [ngClass]="{'fa-chevron-down': !info_expanded, 'fa-chevron-up': !!info_expanded}"></i>
                                </a>
                                <div class="collapse show" id="collapseExample">
                                    <div class="card-body">
                                        <p class="pb-0 pt-3">
                                            <strong>{{window.dictionary.get("username")}}</strong>
                                            <input type="text" class="profile-input" name="test" disabled [(ngModel)]="getUser().username">
                                        </p>
                                        <p class="pb-0 pt-3">
                                            <strong>{{window.dictionary.get("email")}}</strong>
                                            <input type="email" class="profile-input" name="test" disabled [(ngModel)]="getUser().email">
                                        </p>

                                        <!-- SE AZIENDA -->
                                        <p class="pb-0 pt-3" [hidden]="getUser().configuration.type != 'giuridica'">
                                            <strong>{{window.dictionary.get("ragione_sociale")}}</strong>
                                            <input type="text" class="profile-input" name="ragione_sociale" (change)="updateOnChange()" [(ngModel)]="getUser().configuration.ragione_sociale">
                                        </p>
                                        <p class="pb-0 pt-3" [hidden]="getUser().configuration.type != 'giuridica'">
                                            <strong>{{window.dictionary.get("p_iva")}}</strong>
                                            <input type="text" class="profile-input" name="p_iva" (change)="updateOnChange()" [(ngModel)]="getUser().configuration.p_iva">
                                        </p>

                                        <!-- SE PERSONA FISICA -->
                                        <p class="pb-0 pt-3" [hidden]="getUser().configuration.type != 'fisica'">
                                            <strong>{{window.dictionary.get("nome")}}</strong>
                                            <input type="text" class="profile-input" name="nome" (change)="updateOnChange()" [(ngModel)]="getUser().configuration.nome">
                                        </p>
                                        <p class="pb-0 pt-3" [hidden]="getUser().configuration.type != 'fisica'">
                                            <strong>{{window.dictionary.get("cognome")}}</strong>
                                            <input type="text" class="profile-input" name="cognome" (change)="updateOnChange()" [(ngModel)]="getUser().configuration.cognome">
                                        </p>
                                        <p class="pb-0 pt-3" [hidden]="getUser().configuration.type != 'fisica'">
                                            <strong>{{window.dictionary.get("codice_fiscale")}}</strong>
                                            <input type="text" class="profile-input" name="codice_fiscale" (change)="updateOnChange()" [(ngModel)]="getUser().configuration.cf">
                                        </p>


                                        <p class="pb-0 pt-3">
                                            <strong>{{window.dictionary.get("telefono")}}</strong>
                                            <input type="number" class="profile-input" name="telefono" (change)="updateOnChange()" [(ngModel)]="getUser().configuration.telefono">
                                        </p>
                                        <p class="pb-0 pt-3">
                                            <strong>{{window.dictionary.get("indirizzo")}}</strong>
                                            <input type="text" class="profile-input" name="indirizzo" (change)="updateOnChange()" [(ngModel)]="getUser().configuration.indirizzo">
                                        </p>
                                        <p class="pb-0 pt-3">
                                            <strong>{{window.dictionary.get("cap")}}</strong>
                                            <input type="text" class="profile-input" name="cap" (change)="updateOnChange()" [(ngModel)]="getUser().configuration.cap">
                                        </p>
                                        <p class="pb-0 pt-3">
                                            <strong>{{window.dictionary.get("citta")}}</strong>
                                            <input type="text" class="profile-input" name="citta" (change)="updateOnChange()" [(ngModel)]="getUser().configuration.citta">
                                        </p>
                                        <p class="pb-0 pt-3 mb-0">
                                            <strong>{{window.dictionary.get("provincia")}}</strong>
                                            <input type="text" class="profile-input" name="provincia" (change)="updateOnChange()" [(ngModel)]="getUser().configuration.provincia">
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-row">
                                <a class="# cursor-pointer" (click)="window.usher.logout()">
                                    {{window.dictionary.get("esci")}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>	
            </div>
            <div class="profile-column lg-column">
                <div class="profile-custom-counter" data-aos="fade-up" data-aos-delay="100">
                    <div class="top-title">
                        <h2>{{window.dictionary.get("mie_assicurazioni")}}</h2>
                    </div>	
                    <div class="profile-box-row">
                        <p [hidden]="!!getUser().manager_configuration?.assicurazioni?.length">{{window.dictionary.get("no_assicurazioni")}}</p>
                        <div class="profile-box-column" *ngFor="let assicurazione of getUser().manager_configuration?.assicurazioni; index as i">
                            <div class="card round-card">
                                <div class="card-body">
                                    <div class="card-head d-flex">
                                    </div>
                                    <h5>{{ assicurazione.pacchetto.name }}</h5>
                                    <p class="card-text">{{ window.dictionary.get('effettuata_il') + " " + window.moment(assicurazione.creation_timestamp).format('DD-MM-YYYY') }}</p>
                                    <p class="card-text">{{ window.dictionary.get('pagamento_di') + " " + window.middleware.utils.formatPrice(assicurazione.price) }}</p>
                                </div>
                                <div class="card-footer text-center">
                                    <a [routerLink]="" [queryParams]="changeQueryParam({'assicurazione': assicurazione.id})" (click)="openAssicurazione(assicurazione.id)" class="btn btn-primary" tabindex="0">Visualizza dettagli</a>
                                </div>
                            </div>
                        </div>
                        <!--<div class="profile-box-column">
                            <div class="card round-card">
                                <div class="card-body">
                                    <div class="card-head d-flex">
                                        <div class="card-icon mr-auto">
                                            <img src="./assets/img/Tattoo-artist.png">
                                        </div>
                                        <div class="card-detail text-right">
                                            <p>80,00</p>
                                            <span>/mese</span>
                                        </div>
                                    </div>
                                    <h5>Pacchetto tatuatore</h5>
                                    <p class="card-text">Validita 13/06/2021</p>
                                </div>
                                <div class="card-footer text-center">
                                    <a href="#" data-toggle="modal" data-target="#myModal" class="btn btn-primary" tabindex="0">Visualizza dettagli</a>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </div>
                <div class="profile-custom-counter" data-aos="fade-up" data-aos-delay="200">
                    <div class="top-title">
                        <h2>{{window.dictionary.get("miei_preventivi_saldare")}}</h2>
                    </div>	
                    <div class="profile-box-row">
                        <p [hidden]="!!getPreventivi(false).length">{{window.dictionary.get("no_preventivi")}}</p>
                        <div class="profile-box-column" *ngFor="let preventivo of getPreventivi(false); index as i">
                            <div class="card round-card">
                                <div class="card-body">
                                    <div class="card-head d-flex">
                                    </div>
                                    <h5>{{ getPacchetto(preventivo.pacchetto.public_token).name || preventivo.pacchetto.name }}</h5>
                                    <p class="card-text">{{ window.dictionary.get('richiesto_il') + " " + window.moment(preventivo.creation_timestamp).format('DD-MM-YYYY') }}</p>
                                </div>
                                <div class="card-footer text-center">
                                    <a [routerLink]="" [queryParams]="changeQueryParam({'preventivo': preventivo.id})" (click)="openPreventivo(preventivo.id)" class="btn btn-primary" tabindex="0">Visualizza dettagli</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="profile-custom-counter" data-aos="fade-up" data-aos-delay="200">
                    <div class="top-title">
                        <h2>{{window.dictionary.get("miei_preventivi_attesa")}}</h2>
                    </div>	
                    <div class="profile-box-row">
                        <p [hidden]="!!getPreventivi().length">{{window.dictionary.get("no_preventivi")}}</p>
                        <div class="profile-box-column" *ngFor="let preventivo of getPreventivi(); index as i">
                            <div class="card round-card">
                                <div class="card-body">
                                    <div class="card-head d-flex">
                                    </div>
                                    <h5>{{ getPacchetto(preventivo.pacchetto.public_token).name || preventivo.pacchetto.name }}</h5>
                                    <p class="card-text">{{ window.dictionary.get('richiesto_il') + " " + window.moment(preventivo.creation_timestamp).format('DD-MM-YYYY') }}</p>
                                </div>
                                <div class="card-footer text-center">
                                    <a [routerLink]="" [queryParams]="changeQueryParam({'preventivo': preventivo.id})" (click)="openPreventivo(preventivo.id)" class="btn btn-primary" tabindex="0">Visualizza dettagli</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="profile-custom-counter" data-aos="fade-up" data-aos-delay="300" *ngFor="let convenzione of getUser().manager_configuration?.convenzioni; index as i" [hidden]="!window.info_data.visualizza_convenzioni || !window.usher.get().manager_configuration?.convenzioni?.length || !getConvenzione(convenzione).public_token">
                    <div class="top-title">
                        <h2>{{ window.dictionary.get("allegati") + " " + getConvenzione(convenzione).name }}</h2>
                    </div>
                    <!--<div class="row d-flex justify-content-center" *ngIf="window.mode != 'convenzione'">
                        <div class="col-12 slick-active" [hidden]="!getConvenzione(convenzione).public_token">
                            <div class="full-card pb-0 pt-1" data-aos="fade-up">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <h4 class="mb-3 double-height-h4">{{ getConvenzione(convenzione).name}}</h4>
                                        <p class="convenzione-description" [innerHtml]="getDescription(convenzione)"></p>
                                        <div class="row">
                                            <div class="col-12">
                                                <img class="convenzione-image" [src]="getPicture(getConvenzione(convenzione).configuration.image)" alt="">
                                            </div>
                                        </div>
                                        <a class="line-link cursor-pointer" data-aos="fade-up" (click)="showConvenzione(convenzione)">
                                            <span>{{window.dictionary.get("vedi_documenti")}}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->
                    <div class="row d-flex justify-content-center"><!-- *ngIf="window.mode == 'convenzione'" -->
                        <div class="col-12 cursor-pointer" *ngFor="let document of getConvenzione(convenzione)?.configuration?.documents; index as i">
                            <span class="w-100" (click)="downloadDocument(document)">
                                <h3><i class="text-3xl fal pr-3 fas fa-paperclip mr-2"></i>{{ document.name }}</h3>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="profile-custom-counter" data-aos="fade-up" data-aos-delay="300" *ngIf="!!window.info_data.visualizza_convenzioni && !window.usher.get().manager_configuration?.convenzioni?.length">
                    <p>{{window.dictionary.get("no_convenzioni")}}</p>
                </div>
            </div>
        </div>
        
    </div>
</section>

<div class="modal lg-modal-custom first" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal"><i class="fa fa-long-arrow-left"></i></button>
            </div>
            <div class="modal-body custom-product-modal">
                <section class="title-bar insurance-bar space-remove">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-7 left-text">
                                <h2 data-aos="fade-up" data-aos-delay="200">Assurance Complémentaire Santé TNS</h2>
                                <span>Validita 13/06/2021</span>
                            </div>
                            <div class="col-lg-4 col-md-5 right-text">
                                <h2 data-aos="fade-up" data-aos-delay="200">35,50 €</h2>
                                <h1 data-aos="fade-up" data-aos-delay="100">Tarif min. mensuel</h1>
                                <a href="" class="btn btn-primary ovelap-btn">Richiedi un preventivo</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="sec-popup-img">
                    <img src="./assets/img/popup-img.png">
                </section>
                <section class="sec-popup-text">
                    <h2>Lorem Ipsum</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros. Etiam dui eros, imperdiet quis vehicula sit amet, faucibus fermentum nulla. Vestibulum hendrerit elit ut augue porta aliquam. Nam fringilla venenatis turpis ut condimentum. Vestibulum venenatis sodales nibh vel iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros. Etiam dui eros, imperdiet quis vehicula sit amet, faucibus fermentum nulla. Vestibulum hendrerit elit ut augue porta aliquam. Nam fringilla venenatis turpis ut condimentum. Vestibulum venenatis sodales nibh vel iaculis.</p>
                </section>
                <section class="sec-popup-text sec-popup-boxes">
                <h2>I tuoi allegati</h2>
                <div class="row">
                    <div class="col-12 col-md-4 sm-box">
                        <h3>
                            <i class="text-3xl fal pr-3 fas fa-paperclip"></i> Lorem ipsum dolor sit
                        </h3>	
                    </div>
                    <div class="col-12 col-md-4 sm-box">
                        <h3>
                            <i class="text-3xl fal pr-3 fas fa-paperclip"></i> Lorem ipsum dolor sit
                        </h3>	
                    </div>
                    <div class="col-12 col-md-4 sm-box">
                        <h3>
                            <i class="text-3xl fal pr-3 fas fa-paperclip"></i> Lorem ipsum dolor sit
                        </h3>	
                    </div>
                </div>
                </section>
                <section class="sec-popup-boxes">
                    <h2>Garanzie offerte</h2>
                    <div class="row">
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="100">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>	
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="200">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="300">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="100">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>	
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="200">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="300">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div class="modal lg-modal-custom second-modal" id="myModal1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal"><i class="fa fa-long-arrow-left"></i></button>
            </div>
            <div class="modal-body custom-product-modal second-product-modal">
                <section class="title-bar insurance-bar space-remove">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-7 left-text">
                                <h2 data-aos="fade-up" data-aos-delay="200">Pacchetto benssere</h2>
                                <span>Validita 13/06/2021</span>
                            </div>
                            <div class="col-lg-4 col-md-5 right-text">
                                <h2 data-aos="fade-up" data-aos-delay="200">15,00 €</h2>
                                <h1 data-aos="fade-up" data-aos-delay="100">minimo / mese</h1>
                                <a href="" class="btn btn-primary ovelap-btn">Richiedi un preventivo</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="sec-popup-img">
                    <img src="./assets/img/popup-img.png">
                </section>
                <section class="sec-popup-text">
                    <h2>Lorem Ipsum</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros. Etiam dui eros, imperdiet quis vehicula sit amet, faucibus fermentum nulla. Vestibulum hendrerit elit ut augue porta aliquam. Nam fringilla venenatis turpis ut condimentum. Vestibulum venenatis sodales nibh vel iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros. Etiam dui eros, imperdiet quis vehicula sit amet, faucibus fermentum nulla. Vestibulum hendrerit elit ut augue porta aliquam. Nam fringilla venenatis turpis ut condimentum. Vestibulum venenatis sodales nibh vel iaculis.</p>
                </section>
                <section class="sec-popup-boxes">
                    <h2>Garanzie offerte</h2>
                    <div class="row">
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="100">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>	
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="200">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="300">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="100">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>	
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="200">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                        <div class="col-12 col-md-4 aos-init aos-animate sm-box" data-aos="fade-up" data-aos-delay="300">
                            <h3><i class="text-3xl fal pr-3 fa-car"></i> Lorem ipsum dolor sit</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lobortis ultricies. Aenean ornare, velit sed sodales tincidunt, mauris diam vulputate arcu, eu posuere nisl ante quis dui. Nunc quis dolor eros.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 bottom-button text-center">
                            <a href="" class="btn btn-primary">Richiedi un preventivo</a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>