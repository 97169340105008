import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pacchetto-convenzione-card',
  templateUrl: './pacchetto-convenzione-card.component.html',
  styleUrls: ['./pacchetto-convenzione-card.component.css']
})
export class PacchettoConvenzioneCardComponent {
    @Input() pacchetto:any = {
        description: "",
        name: "",
        public_token: "",
        configuration: {}
    };

    window:any=<any>window;
    AOS:any=<any>this.window.AOS;
    $:any=<any>this.window.$;
    Typed:any=<any>this.window.Typed;
    changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });
    queryparams:any = () => this.route.snapshot.params;

    constructor(
    private route:ActivatedRoute,
    private router:Router
    ) {};

    ngAfterViewInit(): void {
        this.arrangeView();
    };

    arrangeView() {};
    
    getGaranzie = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'garanzia' && (!pt || b.public_token == pt)) || [];

    calculateTax = (condizione:any = {}) => {
        if(isNaN(condizione.tasso)) return Infinity;
        if(condizione.tasso == 0 || !condizione.tasso) return condizione.minimo;
        return Math.max(condizione.minimo || 0, parseFloat(condizione.fatturato || 0)*parseFloat(condizione.tasso || 1)/100);
    };

    getMinPrice = () => this.pacchetto.configuration?.garanzie?.reduce((a:any, b:any) => {
        a = a || 0;
        let garanzia = this.getGaranzie(b)[0] || {},
            temp_tables = this.getGaranzie(b)[0].configuration.tables || [],
            tables = [],
            minimo = 0;

        if(temp_tables.length) {
            tables = temp_tables.map(e => e.values.sort((c:any, d:any) => this.calculateTax(c) >= this.calculateTax(d) ? 1 : -1));
            minimo = this.calculateTax(tables.sort((c:any, d:any) => this.calculateTax(c[0]) >= this.calculateTax(d[0]) ? 1 : -1)[0][0]);
        }

        if(!!garanzia.configuration.tax && garanzia.configuration.tax != 0) minimo += minimo*(garanzia.configuration.tax/100);

        let costo = garanzia.configuration.base_price + (minimo == Infinity ? 0 : minimo);

        if(!this.pacchetto.configuration.single_garanzia) return a += costo;
        return a = (!a || a > costo) ? costo : a;
    }, 0)?.toFixed(2) || 0;

    openQuestionario = () => this.window.plussimple_signal.emit({name: 'questionario', detail: {pacchetto: this.pacchetto}});

    getDescription = () => this.pacchetto?.configuration?.description?.length > 200 ? `${this.pacchetto.configuration?.description?.substr(0, 200)}...` : this.pacchetto?.configuration?.description;
  
    show = (selector:any) => this.$(selector).fadeIn(300);
    hide = (selector:any) => this.$(selector).fadeOut(300);
}