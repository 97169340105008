<!--<div class="collapse show" id="collapseCookies">
<div class="sticky-cookies" id="cookies">
    <div class="cookies-wrap">
        <div class="left-colum">
            <h4>A propos des cookies sur ce site</h4>
            <p>Nous utilisons les cookies pour collecter et analyser des informations sur les performances et
                l'utilisation du site, pour fournir des fonctionnalités de médias sociaux et pour améliorer et
                personnaliser le contenu et les publicités.</p>
        </div>
        <div class="right-colum">
            <button type="button" class="btn-full" data-toggle="collapse" data-target="#collapseCookies" aria-expanded="false" aria-controls="collapseExample">Autoriser tous les cookies</button>

            <button type="button" class="btn-full" data-toggle="modal"
            data-target="#Convenzione_modal">Nier tous</button>
            
            <button type="button" class="btn-full btn-transparent" data-toggle="modal"
                data-target="#cookiesModal">Paramètres des cookies</button>
        </div>
    </div>
</div>
</div>-->
<!-- Modal -->
<div class="cookies-modal modal fade" id="cookiesModal" aria-labelledby="cookiesModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">{{ window.dictionary.get('info_cookie') }}</h3>
            </div>
            <div class="modal-body">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="Parametres-tab" data-toggle="tab" href="#Parametres" role="tab"
                            aria-controls="home" aria-selected="true">{{ window.dictionary.get('impostazioni_cookie') }}</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="Declaration-tab" data-toggle="tab" href="#Declaration" role="tab"
                            aria-controls="profile" aria-selected="false">{{ window.dictionary.get('dichiarazione_cookie') }}</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="Parametres" role="tabpanel"
                        aria-labelledby="Parametres-tab">
                        <div class="cus-content">
                            <p>{{ window.dictionary.get('descrizione_cookie_1') }}</p>
                            <div class="inline-btns">
                                <button type="button" class="btn btn-light" (click)="acceptAll()">{{ window.dictionary.get('consenti_cookie') }}</button>
                                <button type="button" class="btn btn-light" (click)="denyAll()">{{ window.dictionary.get('cookie_button2') }}</button>
                            </div>
                            <ul>
                                <li>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="cookies.necessari">
                                        <label class="custom-control-label" for="customSwitch1">
                                            <p><strong>{{ window.dictionary.get('cookie_richiesti') }}</strong></p>
                                            <p>{{ window.dictionary.get('descrizione_cookie_2') }}</p>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch2" [(ngModel)]="cookies.analytics">
                                        <label class="custom-control-label" for="customSwitch2">
                                            <p><strong>{{ window.dictionary.get('cookie_analitici') }}</strong></p>
                                            <p>{{ window.dictionary.get('cookie_analitici_2') }}</p>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch3" [(ngModel)]="cookies.marketing">
                                        <label class="custom-control-label" for="customSwitch3">
                                            <p><strong>{{ window.dictionary.get('cookie_marketing') }}</strong></p>
                                            <p>{{ window.dictionary.get('cookie_marketing_2') }}</p>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--<div class="tab-pane fade" id="Declaration" role="tabpanel" aria-labelledby="Declaration-tab">
                        <div class="cus-content">
                            <p>{{ window.dictionary.get('descrizione_cookie_1') }}</p>
                            <h4>{{ window.dictionary.get('cookie_richiesti') }}</h4>
                            <p>{{ window.dictionary.get('descrizione_cookie_2') }}</p>
                            <div class="cus-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nom</th>
                                            <th>Nom d'hôte</th>
                                            <th>Chemin</th>
                                            <th>Chemin</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>prospect</td>
                                            <td>www.plussimple.fr</td>
                                            <td>/</td>
                                            <td>Session</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">
                                                <p>Utilisé pour sauvegarder vos préférences de parcours.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>cookiehub</td>
                                            <td>.plussimple.fr</td>
                                            <td>/</td>
                                            <td>365 jours</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">
                                                <p>Utilisé par CookieHub pour stocker des informations indiquant si les
                                                    visiteurs ont donné ou refusé l'utilisation des catégories de
                                                    cookies utilisées sur le site.</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4>Cookies analytiques</h4>
                            <p>Les cookies analytiques nous aident à améliorer notre site Web en collectant et en
                                rapportant des informations sur son utilisation.</p>
                            <div class="cus-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nom</th>
                                            <th>Nom d'hôte</th>
                                            <th>Chemin</th>
                                            <th>Chemin</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>_ga</td>
                                            <td>.plussimple.fr</td>
                                            <td>/</td>
                                            <td>365 jours</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">
                                                <p>Contient un identifiant unique utilisé par Google Analytics pour
                                                    déterminer que deux appels distincts appartiennent au même
                                                    utilisateur au cours des sessions de navigation.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>_gid</td>
                                            <td>.plussimple.fr</td>
                                            <td>/</td>
                                            <td>1 jour</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">
                                                <p>Contient un identifiant unique utilisé par Google Analytics pour
                                                    déterminer que deux appels distincts appartiennent au même
                                                    utilisateur au cours des sessions de navigation.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>_gat_*</td>
                                            <td>.plussimple.fr</td>
                                            <td>/</td>
                                            <td>1 heure</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">
                                                <p>Utilisé par Google Analytics pour limiter le taux de demandes
                                                    (limiter la collecte de données sur les sites à fort trafic).</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4>Cookies marketing</h4>
                            <p>Les cookies marketing sont utilisés pour suivre les visiteurs sur les sites Web afin de
                                permettre aux éditeurs d'afficher des publicités pertinentes et attrayantes.</p>
                            <div class="cus-table">
                                <table class="mb-0">
                                    <thead>
                                        <tr>
                                            <th>Nom</th>
                                            <th>Nom d'hôte</th>
                                            <th>Chemin</th>
                                            <th>Chemin</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>intercom-id-leyw2xzw</td>
                                            <td>.plussimple.fr</td>
                                            <td>/</td>
                                            <td>270 jours, 1 heure</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">
                                                <p>Permet recueillir des informations afin de de mieux établir et gérer
                                                    notre relation clientèle avec Intercom.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>intercom-session-leyw2xzw</td>
                                            <td>.plussimple.fr</td>
                                            <td>/</td>
                                            <td>7 jours</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">
                                                <p>Permet recueillir des informations afin de de mieux établir et gérer
                                                    notre relation clientèle avec Intercom.</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-full" data-dismiss="modal" (click)="save()">{{ window.dictionary.get('salva_impostazioni') }}</button>
            </div>
        </div>
    </div>
</div>