<div class="login-page">
	<div class="container">
		<div class="inner-login-content">
			<div class="login-logo mb-2" data-aos="fade-up" data-aos-delay="100">
				<a class="back-page cursor-pointer" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></a>
				<a (click)="closeModal()">
					<app-logo></app-logo>
				</a>
			</div>
			<div class="login-title" data-aos="fade-up" data-aos-delay="100">
				<h3>{{ window.dictionary.get('convenzione') + " " + getConvenzione().name }}</h3>
			</div>
			<form class="login-form" data-aos="fade-up" data-aos-delay="100">
				
				<p class="mb-5 description-text" [innerHtml]="getConvenzione().configuration.description"></p>
                <div class="col-12 cursor-pointer" *ngFor="let document of getDocuments(); index as i">
                    <span class="w-100" (click)="downloadDocument(document)"><!-- [href]="getLink(document)" -->
                        <h3><i class="text-3xl fal pr-3 fas fa-paperclip mr-2"></i>{{ document.name }}</h3>
                    </span>
                </div>
				
			</form>
		</div>
	</div>
</div>