<div class="lg-modal-custom first position-absolute" id="addclientModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></button>
            </div>
            <div class="modal-body custom-product-modal">
                <section class="title-bar insurance-bar space-remove">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-7 left-text">
                                <h2>{{ window.dictionary.get('aggiungi_nuovo_cliente') }}</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="sec-popup-text">
                    <h4>{{ window.dictionary.get('inserisci_dati') }}</h4>

                    <form>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="nome">{{ window.dictionary.get('nome') }}*</label>
                                    <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="temp.name">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="surname">{{ window.dictionary.get('cognome') }}*</label>
                                    <input type="text" class="form-control" id="surname" name="surname" [(ngModel)]="temp.surname">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="email">{{ window.dictionary.get('email') }}*</label>
                                    <input type="email" class="form-control" id="email" name="email" [(ngModel)]="temp.email">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="phone">{{ window.dictionary.get('telefono') }}</label>
                                    <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="temp.phone">
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

                <button class="btn btn-primary float-right mt-3" (click)="confirm()">{{ window.dictionary.get('aggiungi') }}</button>
            </div>
        </div>
    </div>
</div>