<div class="login-page">
	<div class="container">
		<div class="inner-login-content">
			<div class="login-logo mb-2" data-aos="fade-up" data-aos-delay="100">
				<a class="back-page cursor-pointer" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></a>
				<a (click)="closeModal()">
					<app-logo></app-logo>
				</a>
			</div>
			<div class="login-title" data-aos="fade-up" data-aos-delay="100">
				<h3>{{window.dictionary.get('benvenuto')}}, <br> {{window.dictionary.get('inserisci_credenziali')}}</h3>
			</div>
			<form class="login-form" action="" data-aos="fade-up" data-aos-delay="100">
				<div class="form-group">
					<label>{{window.dictionary.get('email')}}</label>
					<input type="text" class="form-control" id="login-username" name="login-username" placeholder="{{window.dictionary.get('email')}}" [(ngModel)]="login_model.username">
				</div>
				<div class="form-group">
					<label>{{window.dictionary.get('password')}}</label>
					<input type="password" class="form-control" id="login-password" name="login-password" placeholder="{{window.dictionary.get('password')}}" [(ngModel)]="login_model.password">
					<a [routerLink]="" (click)="show('app-restore-password')" class="forgot"><i>{{window.dictionary.get('password_dimenticata')}}</i></a>
				</div>
				<div class="form-group" (click)="login()">
					<a class="btn btn-primary cursor-pointer text-white">{{window.dictionary.get('accedi')}}</a>
				</div>
				<div class="form-group" (click)="switchModal()">
					<p>{{window.dictionary.get('non_sei_registrato')}} <a class="cursor-pointer hover-underline">{{window.dictionary.get('crea_account')}}</a></p>
				</div>
			</form>
		</div>
	</div>
</div>