<div class="lg-modal-custom first position-absolute" id="questionarioModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></button>
            </div>
            <div class="modal-body custom-product-modal">
                <section class="title-bar insurance-bar space-remove">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-7 left-text">
                                <h2>{{pacchetto.name}}</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="sec-popup-img">
                    <img class="w-100" [src]="getPicture(pacchetto.configuration.cover, '/inner-banner.jpg')">
                </section>

                <section class="sec-popup-text" [innerHtml]="pacchetto.configuration.description"></section>

                <section class="sec-popup-text sec-popup-boxes" *ngIf="!!pacchetto.configuration.documents?.length">
                    <h2>{{ window.dictionary.get('documenti') }}</h2>
                    <div class="row">
                        <div class="col-12 col-md-4 sm-box" *ngFor="let doc of pacchetto.configuration.documents; index as i">
                            <a [href]="getLink(doc)" target="_blank">
                                <h3><i class="text-3xl fal pr-3 fas fa-paperclip"></i> {{doc.name}}</h3>
                            </a>
                        </div>
                    </div>
                </section>
                <p>{{ window.dictionary.get('spiegazione_necessita_valutazione') }}</p>

                <section class="sec-popup-boxes" *ngIf="!pacchetto.configuration.single_garanzia">
                    <h2>{{ window.dictionary.get('garanzie_indispensabili') }}</h2>
                    <div *ngFor="let garanzia of pacchetto.configuration.garanzie; index as i">
                        <app-table-inputs [garanzia]="getGaranzia(garanzia)" [model_param]="model_params[garanzia]"></app-table-inputs>
                    </div>
                </section>

                <section class="sec-popup-boxes" *ngIf="!!pacchetto.configuration.single_garanzia">
                    <h2>{{ window.dictionary.get('garanzie_indispensabili') }}</h2>
                    <div *ngFor="let garanzia of pacchetto.configuration.garanzie; index as i">
                        <div class="form-group form-check">
                            <input [(ngModel)]="temp_model_indispensabili.value" (change)="clearModels()" type="radio" class="form-check-input" [id]="'indispensabile_' + garanzia" name="radio_indispensabile" [value]="garanzia">
                            <label class="form-check-label" [for]="'indispensabile_' + garanzia">{{ getGaranzia(garanzia).name }}</label>
                        </div>
                    </div>
                    <app-table-inputs *ngIf="!!temp_model_indispensabili.value" [garanzia]="getGaranzia(temp_model_indispensabili.value)" [model_param]="model_params[temp_model_indispensabili.value]"></app-table-inputs>
                    </section>

                <section class="sec-popup-boxes" *ngIf="!!pacchetto.configuration.garanzie_opzionali?.length">
                    <h2>{{ window.dictionary.get('garanzie_opzionali') }}</h2>
                    <div *ngFor="let garanzia of pacchetto.configuration.garanzie_opzionali; index as i">
                        <div class="form-group form-check">
                            <input [(ngModel)]="temp_model_opzionali[garanzia]" (change)="handleOpzionale(garanzia)" type="checkbox" class="form-check-input" [id]="'opzionale_' + garanzia" [name]="'opzionale_' + garanzia">
                            <label class="form-check-label" [for]="'opzionale_' + garanzia">{{ getGaranzia(garanzia).name }}<b style="margin-left: 10px;" [hidden]="!!getGaranzia(garanzia).configuration.tables?.length || !temp_model_opzionali[garanzia]">+{{ window.middleware.utils.formatPrice(+getGaranzia(garanzia).configuration.base_price || 0) }}</b></label>
                        </div>
                    </div>
                    <app-table-inputs *ngFor="let garanzia_opzionale of temp_model_opzionali | keyvalue; index as i" [hidden]="!temp_model_opzionali[garanzia_opzionale.key]" [garanzia]="getGaranzia(garanzia_opzionale.key)" [model_param]="model_params[garanzia_opzionale.key]"></app-table-inputs>
                </section>

                <form [hidden]="!getQuestionarioVarianti().length || !showDomandePreliminari()">
                    <h2>{{ window.dictionary.get('domande_preliminari') }}</h2>
                    <div class="form-row">
                        <div class="col-md-6 col-sm-12" *ngFor="let question of getQuestionarioVarianti(); index as i">
                            <div class="form-group">
                                <label [ngClass]="{'warning-class-text': !isQuestionCompleted(question)}">
                                    <span *ngIf="!!showQuestionPrice(question) && showQuestionPrice(question) == 'bloccante'" class="mr-1" [title]="window.dictionary.get('scelta_valutazione')">
                                        <img src="assets/icon/warning.svg" width="20" height="20" alt="">
                                    </span>
                                    {{ question?.title + " " + (question?.optional ? window.dictionary.get('opzionale') : "*") }}
                                    <span *ngIf="!!showQuestionPrice(question)">({{ showQuestionPrice(question) }})</span>
                                </label>
                                <span class="cursor-pointer ml-2" [title]="question?.description" [hidden]="!question?.description"><i class="fas fa-question-circle"></i></span>
                                <textarea class="form-control" rows="3" [id]="'testo_lungo_' + question?.id" [hidden]="question?.type != 'testo_libero_lungo'" [(ngModel)]="temp_model_varianti[question?.id]" [ngClass]="{'warning-class': !question?.optional && !temp_model_varianti[question?.id]}" [name]="'testo_lungo_' + question?.id"></textarea>
                                <input class="form-control" type="text" [hidden]="question?.type != 'testo_libero_corto'" [id]="'testo_corto_' + question?.id" [(ngModel)]="temp_model_varianti[question?.id]" [ngClass]="{'warning-class': !question?.optional && !temp_model_varianti[question?.id]}" [name]="'testo_corto_' + question?.id">
                                <input class="form-control" type="number" min="0" [max]="getMaxTableAnswer(question)" [hidden]="question?.type != 'testo_numerico'" [id]="'testo_numerico_' + question?.id" [(ngModel)]="temp_model_varianti[question?.id]" [ngClass]="{'warning-class': isNotCompleted(question)}" [name]="'testo_numerico_' + question?.id">

                                <select class="form-control" [hidden]="question?.type != 'risposta_singola' && question?.type != 'booleano'" [id]="'singola_' + question?.id" [(ngModel)]="temp_model_varianti[question.id]" [ngClass]="{'warning-class': !question?.optional && !temp_model_varianti[question?.id]}" [name]="'singola_' + question?.id">
                                    <option [ngValue]="answer.id" *ngFor="let answer of question?.answers; index as i">{{answer.text}}</option>
                                </select>

                                <div class="form-group form-check" [hidden]="question?.type != 'risposta_multipla'" *ngFor="let answer of question?.answers; index as i">
                                    <input [(ngModel)]="temp_model_varianti[question.id + '|' + i]" type="checkbox" class="form-check-input" [id]="'check_' + question?.id + '_' + i" [name]="'check_' + question?.id + '_' + i">
                                    <label class="form-check-label" [for]="'check_' + question?.id + '_' + i">{{answer.text}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                    </div>
                </form>

                <section class="sec-popup-boxes" [hidden]="!window.middleware.questionari.showConfiguratori(questionari_varianti, temp_model_varianti)">
                    <div [hidden]="!!pacchetto.configuration.single_garanzia" *ngFor="let garanzia of pacchetto.configuration.garanzie; index as i">
                        <app-configuratore-garanzia [incremento]="getIncrementoQuestionari(garanzia)" [garanzia]="getGaranzia(garanzia)" [model_param]="model_params[garanzia]" [table_selected]="{}"></app-configuratore-garanzia>
                    </div>
                    <div [hidden]="!pacchetto.configuration.single_garanzia">
                        <app-configuratore-garanzia [incremento]="getIncrementoQuestionari(temp_model_indispensabili.value)" [garanzia]="getGaranzia(temp_model_indispensabili.value)" [model_param]="model_params[temp_model_indispensabili.value]" [table_selected]="{}"></app-configuratore-garanzia>
                    </div>
                    <div *ngFor="let garanzia of pacchetto.configuration.garanzie_opzionali; index as i">
                        <app-configuratore-garanzia [hidden]="!temp_model_opzionali[garanzia]" [incremento]="getIncrementoQuestionari(garanzia)" [garanzia]="getGaranzia(garanzia)" [model_param]="model_params[garanzia]" [table_selected]="{}"></app-configuratore-garanzia>
                    </div>
                </section>
                
                <section class="sec-popup-boxes mt-4" *ngIf="!!getQuestionariLength() && !!showQuestionario()">
                    <h2>{{ window.dictionary.get('compila_questionari') }}</h2>

                    <form>
                        <div class="form-row" *ngFor="let questionario of questionari | keyvalue: sortQuestionari; index as i" [hidden]="!showNextStep(i)">
                            <div class="col-12">
                                <h5 class="w-100">{{ questionario.value.name }}</h5>
                            </div>
                            <div class="col-12">
                                <h6>{{ questionario.value.configuration?.description }}</h6>
                            </div>
                            <div class="col-md-6 col-sm-12" *ngFor="let question of getQuestions(questionario.value); index as i"><!--questionario.value.configuration?.questions-->
                                <div class="form-group">
                                    <label [ngClass]="{'warning-class-text': !isQuestionCompleted(question)}">
                                        <span *ngIf="!!showQuestionPrice(question) && showQuestionPrice(question) == 'bloccante'" class="mr-1" [title]="window.dictionary.get('scelta_valutazione')">
                                            <img src="assets/icon/warning.svg" width="20" height="20" alt="">
                                        </span>
                                        {{ question.title + " " + (question.optional ? window.dictionary.get('opzionale') : "*") }}
                                        <span *ngIf="!!showQuestionPrice(question)">({{ showQuestionPrice(question) }})</span>
                                    </label>
                                    <span class="cursor-pointer ml-2" [title]="question.description" [hidden]="!question.description"><i class="fas fa-question-circle"></i></span>
                                    <textarea class="form-control" (mouseout)="onBlur(question)" rows="3" [id]="'testo_lungo_' + question.id" [hidden]="question.type != 'testo_libero_lungo'" [(ngModel)]="temp_model[question.id]" [ngClass]="{'warning-class': !question.optional && !temp_model[question.id]}" [name]="'testo_lungo_' + question.id"></textarea>
                                    <input class="form-control" (mouseout)="onBlur(question)"  type="text" [hidden]="question.type != 'testo_libero_corto'" [id]="'testo_corto_' + question.id" [(ngModel)]="temp_model[question.id]" [ngClass]="{'warning-class': !question.optional && !temp_model[question.id]}" [name]="'testo_corto_' + question.id">
                                    <input class="form-control" (mouseout)="onBlur(question)"  type="number" min="0" [hidden]="question.type != 'testo_numerico'" [id]="'testo_numerico_' + question.id" [(ngModel)]="temp_model[question.id]" [ngClass]="{'warning-class': isNotCompleted(question)}" [name]="'testo_numerico_' + question.id">

                                    <select class="form-control" (mouseout)="onBlur(question)"  [hidden]="question.type != 'risposta_singola' && question.type != 'booleano'" [id]="'singola_' + question.id" [(ngModel)]="temp_model[question.id]" [ngClass]="{'warning-class': !question.optional && !temp_model[question.id]}" [name]="'singola_' + question.id">
                                        <option [ngValue]="answer.id" *ngFor="let answer of question.answers; index as i">{{answer.text}}</option>
                                    </select>

                                    <div class="form-group form-check" [hidden]="question.type != 'risposta_multipla'" *ngFor="let answer of question.answers; index as i" (mouseout)="onBlur(question)" >
                                        <input [(ngModel)]="temp_model[question.id + '|' + i]" type="checkbox" class="form-check-input" [id]="'check_' + question.id + '_' + i" [name]="'check_' + question.id + '_' + i">
                                        <label class="form-check-label" [for]="'check_' + question.id + '_' + i">{{answer.text}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <hr>
                            </div>
                        </div>
                    </form>

                </section>

                <section class="sec-popup-text">
                    <h2>{{ window.dictionary.get('note') }}</h2>

                    <form>
                        <div class="form-row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="note_aggiuntive">{{ window.dictionary.get('note_aggiuntive') }}</label>
                                    <textarea class="form-control" name="note_aggiuntive" id="note_aggiuntive" cols="30" rows="3" [(ngModel)]="note.text"></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

                <section class="sec-popup-text" *ngIf="window.config.appname == 'plussimple' && !!window.usher.isLogged()">
                    <hr>
                </section>

                <section class="sec-popup-text" *ngIf="window.config.appname == 'plussimple' && !!window.usher.isLogged()">
                    <h2>{{ window.dictionary.get('seleziona_cliente') }}</h2>
                    <form>
                        <div class="form-row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="note_aggiuntive">{{ window.dictionary.get('seleziona') }}</label>
                                    <select class="form-control" id="select_cliente" [(ngModel)]="temp_cliente" name="select_cliente" (keyup)="updateView()">
                                        <option [ngValue]="{}">-</option>
                                        <option [ngValue]="client" *ngFor="let client of window.usher.get()?.public_configuration?.clients; index as i">{{ client.name + " " + client.surname + " - " + client.email }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                    <h5>{{ window.dictionary.get('aggiungi_cliente') }}</h5>
                    <form [hidden]="temp_cliente.id">
                        <div class="form-row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="nome">{{ window.dictionary.get('nome') }}*</label>
                                    <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="temp_model_cliente.name">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="surname">{{ window.dictionary.get('cognome') }}*</label>
                                    <input type="text" class="form-control" id="surname" name="surname" [(ngModel)]="temp_model_cliente.surname">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="email">{{ window.dictionary.get('email') }}*</label>
                                    <input type="email" class="form-control" id="email" name="email" [(ngModel)]="temp_model_cliente.email">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="phone">{{ window.dictionary.get('telefono') }}</label>
                                    <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="temp_model_cliente.phone">
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

                <div class="col-12 left-text">
                    <span [hidden]="!checkPrice()">
                        <h2>{{ window.middleware.utils.formatPrice(getFinalPrice()) }}</h2>
                        <h1>{{ window.dictionary.get('premio_proposto') }}</h1>
                        <p>{{ window.config.appname == 'smafbroker' ? window.dictionary.get("info_preventivo") : window.dictionary.get("info_preventivo_simple")}}</p>
                    </span>
                    <p [hidden]="checkPrice()">
                        {{ getFinalPrice() }}
                    </p>
                    <button class="btn btn-primary float-right mt-3" (click)="confirm()">{{ window.dictionary.get("invia_richiesta_preventivo") }}</button> <!-- window.dictionary.get("acquista") -->
                </div>
            </div>
        </div>
    </div>
</div>