import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  window:any=<any>window;
  title = this.window.config.title;
  $:any=this.window.$;

  expanded:any = false;
  changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });

  constructor(private router:Router,
    private route:ActivatedRoute)
  {}

  show = (selector:any) => this.$(selector).fadeIn(300);
  hide = (selector:any) => this.$(selector).fadeOut(300);

  isHome = () => this.router.url == "/" || this.router.url == "/home";

  getGaranzie = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'garanzia' && (!pt || b.public_token == pt)) || [];
  getPacchetti = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'pacchetto' && (!pt || b.public_token == pt)) || [];
  getProfessioni = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'professione' && (!pt || b.public_token == pt)) || [];
  getProfessioniPacchetti = (pt:any = undefined) => this.getProfessioni(pt).reduce((a:any, b:any) => {
	a = a || [];
	if(!a.find(e => e.public_token == b.public_token) && this.getPacchetti().some(el => el.configuration.professioni.includes(b.public_token))) a.push(b);
	return a;
  }, []) || [];

  getPicture = (pic:any) => `${this.window.config.urls.url_media}/get.php?private_token=${this.window.usher.getPrivateToken()}&name=${pic}`;

  getDocuments = (pic:any) => `${this.window.config.urls.url_media}/public/${pic}`;

  getPacchettiHeader = () => {
    let pacchetti = this.window.collection_data?.filter(b => b.configuration.type == 'pacchetto') || [];
    return pacchetti.slice(0, (pacchetti.length >= 6 ? 6 : pacchetti.length));
  };
  getProfessioniHeader = () => {
    let professioni = this.window.collection_data?.filter(b => b.configuration.type == 'professione') || [];
    return professioni.slice(0, (professioni.length >= 6 ? 6 : professioni.length));
  }

  openCookiesModal = () => this.window.plussimple_signal.emit({name: 'open-modal-cookies', detail: {}});

    openLink = (params:any) => {
        try {
            let popUp = window.open(params.url, params.target || '_blank');
            popUp.focus();
        } catch (e) {
            console.log(e);
            window.location.href=params.url;
        }
    }

    openDocument = (type:any) => {
        let text = "";
        if(type == 'termini_condizioni') {
            if(this.window.public_info_data.type_termini == 'link') return this.openLink({url: `${this.window.config.urls.url_media}/public/${this.window.public_info_data.termini}`});
            text = this.window.public_info_data.termini_testo;
        }
        if(type == 'privacy_policy') {
            if(this.window.public_info_data.type_privacy == 'link') return this.openLink({url: `${this.window.config.urls.url_media}/public/${this.window.public_info_data.privacy}`});
            text = this.window.public_info_data.privacy_testo;
        }
        if(type == 'cookie_policy') {
            if(this.window.public_info_data.type_cookie == 'link') return this.openLink({url: `${this.window.config.urls.url_media}/public/${this.window.public_info_data.cookie}`});
            text = this.window.public_info_data.cookie_testo;
        }
        if(type == 'note_legali') {
            if(this.window.public_info_data.type_note_legali == 'link') return this.openLink({url: `${this.window.config.urls.url_media}/public/${this.window.public_info_data.note_legali}`});
            text = this.window.public_info_data.note_legali_testo;
        }
        this.window.plussimple_signal.emit({name: 'open-rich-text', detail: {
            title: this.window.dictionary.get(type),
            rich_text: text,
            text_class: "text-left"
        }});
    }

    scrollTo = (selector:any, options:any = {}) => this.window.document.querySelector(selector)?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest", ...options});

    getNome = () => ((!!this.window.usher.get()?.configuration?.nome || !!this.window.usher.get()?.configuration?.cognome) ? `${this.window.usher.get()?.configuration?.nome} ${this.window.usher.get()?.configuration?.cognome}` : this.window.usher.get()?.public_configuration?.nome) || "Nome Cognome";
}