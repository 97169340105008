import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-configuratore-garanzia',
  templateUrl: './configuratore-garanzia.component.html',
  styleUrls: ['./configuratore-garanzia.component.css']
})
export class ConfiguratoreGaranziaComponent {
    @Input() garanzia:any = {};
    @Input() table_selected:any = {};
    @Input() incremento:any = {};
    @Input() model_param:any = {};

    window:any=<any>window;
    $:any=<any>this.window.$;
    changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });
    queryparams:any = () => this.route.snapshot.params;
    selected:any = "";

    constructor(
        private route:ActivatedRoute,
        private router:Router
    ) {
        this.window.plussimple_signal.signaller.addEventListener('clear-configuratore', this.initModel.bind(this));
        this.window.plussimple_signal.signaller.addEventListener('table-selected', this.populateTable.bind(this));
    };

    populateTable = (ev:any) => {
        if(ev?.detail?.pt != this.garanzia.public_token) return;
        this.table_selected = ev?.detail?.table;
    };

    ngOnDestroy() {
        this.window.plussimple_signal.signaller.removeEventListener('clear-configuratore', this.initModel.bind(this));
        this.window.plussimple_signal.signaller.removeEventListener('table-selected', this.populateTable.bind(this));
    };

    initModel = () => {
        this.table_selected = {};
        if(!this.model_param) return;
        for(const key in this.model_param) {
            this.model_param[key] = null;
        }
    };

    getAgenzia = (pt:any) => this.window.collection_agenzie?.find(el => el.public_token == pt) || {};

    getLink = (name:any) => !!name ? `${this.window.config.urls.url_media}/public/${name}` : './assets/icon/robot.svg';

    getMassimali = () => this.garanzia.configuration.tables?.reduce((a:any, b:any) => {
        a = a || [];
        b.values.forEach(el => !a.includes(el.massimale) ? a.push(el.massimale) : "");
        return a;
    }, []).sort((a:any, b:any) => a >= b ? 1 : -1);

    getTables = () => {
        if(Object.values(this.model_param || {})?.some(e => !e)) return [];
        let keys = this.window.middleware.tables.getKeys(this.garanzia);
        return this.garanzia.configuration.tables?.filter(el => {
            let i = 0;
            for(; i<el.values?.length; i++) {
                let val = el.values[i];
                if(keys.every(k => val[k] == (this.model_param || {})[k])) return true;
            }
            return false;
        });
    };

    invalidFatturato = (table:any) => {
        let price = this.window.middleware.tables.getIncrementedPrice(this.garanzia, table, this.model_param.fatturato, this.incremento, true, this.model_param).price;
        return price == undefined;
    };

    showPrice = (table:any) => {
        let price = +(this.window.middleware.tables.getIncrementedPrice(this.garanzia, table, this.model_param.fatturato, this.incremento, true, this.model_param).incremented_price);
        return price != Infinity && !isNaN(price);
    };

    printPrice = (table:any) => {
        let price = +(this.window.middleware.tables.getIncrementedPrice(this.garanzia, table, this.model_param.fatturato, this.incremento, true, this.model_param).incremented_price);
        if(price == Infinity || isNaN(price)) return "";
        return `${this.window.dictionary.get('premio_partire')}: ${this.window.middleware.utils.formatPrice(+this.window.middleware.tables.getIncrementedPrice(this.garanzia, table, this.model_param.fatturato, this.incremento, true, this.model_param).incremented_price || 0)}`;
    };

    selectTable = (table:any) => {
        this.table_selected = table;
        let result = table.id ? this.window.middleware.tables.getIncrementedPrice(this.garanzia, table, this.model_param.fatturato, this.incremento, true, this.model_param) : {price: Infinity, id: ""},
            selected = {
                table: {
                    ...table,
                    price: result.price,
                    id_value: result.id,
                    params: {
                        ...this.model_param
                    }
                },
                pt: this.garanzia.public_token
            };
        this.window.plussimple_signal.emit({name: "table-selected", detail: selected});
    };

    show = (selector:any) => this.$(selector).fadeIn(300);
    hide = (selector:any) => this.$(selector).fadeOut(300);
}