import { Component } from '@angular/core';

@Component({
  selector: 'app-rich-text-modal',
  templateUrl: './rich-text-modal.component.html',
  styleUrls: ['./rich-text-modal.component.css']
})
export class RichTextModalComponent {
    window:any=<any>window;
    $:any=<any>this.window.$;
    
    title:any = "";
    rich_text:any = "";
    text_class:any = "";
    constructor() {
        this.window.plussimple_signal.signaller.addEventListener('open-rich-text', (ev) => {
            this.rich_text = ev?.detail?.rich_text;
            this.title = ev?.detail?.title;
            this.text_class = ev?.detail?.text_class;
            this.openModal();
        });
    }

    openModal = () => {
        let el = this.$("#rich_text_element");
        if(!!this.text_class) el.addClass(this.text_class);
        this.$("app-rich-text-modal").fadeIn(300);
    }

    closeModal = () => {
        this.$("app-rich-text-modal").fadeOut(300);
    }
}