<div class="login-page">
	<div class="container">
		<div class="inner-login-content">
			<div class="login-logo mb-2" data-aos="fade-up" data-aos-delay="100">
				<a class="back-page cursor-pointer" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></a>
				<a (click)="closeModal()">
					<app-logo></app-logo>
				</a>
			</div>
			<div class="login-title" data-aos="fade-up" data-aos-delay="100">
				<h3>{{window.dictionary.get('benvenuto')}}, <br> {{window.dictionary.get('compila_informazioni')}}</h3>
			</div>
			<form class="login-form" action="" data-aos="fade-up" data-aos-delay="200">
				<div class="form-group">
					<label>{{window.dictionary.get('username')}}</label>
					<input type="text" class="form-control" id="register-username" name="register-username" placeholder="{{window.dictionary.get('username')}}" [(ngModel)]="model.username">
				</div>
				<div class="form-group">
					<label>{{window.dictionary.get('persona_fisica') + " / " + window.dictionary.get('persona_giuridica')}}</label>
					<select class="form-control" [(ngModel)]="model.configuration.type" id="tipologia" name="tipologia">
						<option value="fisica">{{window.dictionary.get('persona_fisica')}}</option>
						<option value="giuridica">{{window.dictionary.get('persona_giuridica')}}</option>
					</select>
				</div>

				<!-- SE AZIENDA -->
				<div class="form-group" [hidden]="model.configuration.type != 'giuridica'">
					<label>{{window.dictionary.get('ragione_sociale')}}</label>
					<input type="text" class="form-control" id="ragione_sociale" name="ragione_sociale" placeholder="{{window.dictionary.get('ragione_sociale')}}" [(ngModel)]="model.configuration.ragione_sociale">
				</div>
				<div class="form-group" [hidden]="model.configuration.type != 'giuridica'">
					<label>{{window.dictionary.get('p_iva')}}</label>
					<input type="text" class="form-control" id="p_iva" name="p_iva" placeholder="{{window.dictionary.get('p_iva')}}" [(ngModel)]="model.configuration.p_iva">
				</div>

				<!-- SE UTENTE PRIVATO -->
				<div class="form-group" [hidden]="model.configuration.type != 'fisica'">
					<label>{{window.dictionary.get('nome')}}</label>
					<input type="text" class="form-control" id="nome" name="nome" placeholder="{{window.dictionary.get('nome')}}" [(ngModel)]="model.configuration.nome">
				</div>
				<div class="form-group" [hidden]="model.configuration.type != 'fisica'">
					<label>{{window.dictionary.get('cognome')}}</label>
					<input type="text" class="form-control" id="cognome" name="cognome" placeholder="{{window.dictionary.get('cognome')}}" [(ngModel)]="model.configuration.cognome">
				</div>
				<div class="form-group" [hidden]="model.configuration.type != 'fisica'">
					<label>{{window.dictionary.get('codice_fiscale')}}</label>
					<input type="text" class="form-control" id="codice_fiscale" name="codice_fiscale" placeholder="{{window.dictionary.get('codice_fiscale')}}" [(ngModel)]="model.configuration.cf">
				</div>

				<div class="form-group">
					<label>{{window.dictionary.get('email')}}</label>
					<input type="email" class="form-control" id="email" name="email" placeholder="{{window.dictionary.get('email')}}" [(ngModel)]="model.email">
				</div>
				<div class="form-group">
					<label>{{window.dictionary.get('telefono')}}</label>
					<input type="number" class="form-control" id="telefono" name="telefono" placeholder="{{window.dictionary.get('telefono')}}" [(ngModel)]="model.configuration.telefono">
				</div>
				<div class="form-group">
					<label>{{window.dictionary.get('indirizzo')}}</label>
					<input type="text" class="form-control" id="indirizzo" name="indirizzo" placeholder="{{window.dictionary.get('indirizzo')}}" [(ngModel)]="model.configuration.indirizzo">
				</div>
				<div class="form-group">
					<label>{{window.dictionary.get('cap')}}</label>
					<input type="number" class="form-control" id="cap" name="cap" placeholder="{{window.dictionary.get('cap')}}" [(ngModel)]="model.configuration.cap">
				</div>
				<div class="form-group">
					<label>{{window.dictionary.get('citta')}}</label>
					<input type="text" class="form-control" id="citta" name="citta" placeholder="{{window.dictionary.get('citta')}}" [(ngModel)]="model.configuration.citta">
				</div>
				<div class="form-group">
					<label>{{window.dictionary.get('provincia')}}</label>
					<input type="text" class="form-control" id="provincia" name="provincia" placeholder="{{window.dictionary.get('provincia')}}" [(ngModel)]="model.configuration.provincia">
				</div>

				<!-- SELECT DELLE PROFESSIONI -->
				<div class="form-group">
					<label>{{window.dictionary.get('professione')}}</label>
					<select class="form-control" [(ngModel)]="model.configuration.professione" id="professione" name="professione">
						<option [value]="p.public_token" *ngFor="let p of getProfessioni(); index as i">{{p.name}}</option>
					</select>
				</div>
                
                <div class="form-check col-6 d-inline-block" *ngFor="let polizza of getPolizze(); index as i">
                    <input class="form-check-input cursor-pointer my-checkbox" [(ngModel)]="temp.polizze[polizza.public_token]" type="checkbox" [value]="polizza.public_token" polizza="true" id="{{polizza.public_token}}" name="{{polizza.public_token}}">
                    <label class="form-check-label cursor-pointer" for="{{polizza.public_token}}">
                        {{ polizza.name }}
                    </label>
				</div>
				
				<div class="form-group">
					<label>{{window.dictionary.get('note')}}</label>
					<textarea name="note" id="note" cols="30" rows="5" class="form-control" [(ngModel)]="temp.note"></textarea>
				</div>

				<div class="form-group">
					<button type="button" class="btn btn-primary" (click)="confirm()">{{window.dictionary.get('invia_richiesta')}}</button>
				</div>
			</form>
		</div>
	</div>
</div>