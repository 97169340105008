<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="70" height="70" x="0" y="0" viewBox="0 0 415.869 415.869" style="enable-background:new 0 0 512 512" xml:space="preserve"><g>
<g xmlns="http://www.w3.org/2000/svg">
	<path style="" d="M125.91,170.841c-5.747-6.269-15.673-6.792-21.943-1.045c-6.269,5.747-6.792,15.673-1.045,21.943   l78.89,85.682c3.135,3.135,6.792,5.224,10.971,5.224c0,0,0,0,0.522,0c4.18,0,8.359-1.567,10.971-4.702L403.853,78.89   c6.269-6.269,6.269-16.196,0-21.943c-6.269-6.269-16.196-6.269-21.943,0L193.829,244.506L125.91,170.841z" data-original="#4dcfe0"/>
	<path style="" d="M400.196,192.261c-8.882,0-15.673,6.792-15.673,15.673c0,97.175-79.412,176.588-176.588,176.588   S31.347,305.11,31.347,207.935S110.759,31.347,207.935,31.347c8.882,0,15.673-6.792,15.673-15.673S216.816,0,207.935,0   C93.518,0,0,93.518,0,207.935s93.518,207.935,207.935,207.935s207.935-93.518,207.935-207.935   C415.869,199.053,409.078,192.261,400.196,192.261z" data-original="#4dcfe0"/>
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
<g xmlns="http://www.w3.org/2000/svg">
</g>
</g></svg>