import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesModalComponent implements OnInit {
  window:any=<any>window;
  $:any=<any>this.window.$;
  cookies:any = {
    marketing: false,
    analytics: false,
    necessari: false
  };

    eventHandler:any = (ev:any) => {
      console.log("cookies modal event");
        this.openModal();
    };
    constructor() {
        this.cookies = this.getCookies();
        this.window.plussimple_signal.signaller.addEventListener('open-modal-cookies', this.eventHandler.bind(this));
        this.window.plussimple_signal.signaller.addEventListener('update-cookies', (ev) => {
            this.cookies = this.getCookies();
        });
    }

  ngOnInit(): void {
    /*this.$('#cookiesModal').on('shown.bs.modal', function () {
      this.$('.collapse').collapse();
    })
    */
  }

    acceptAll = () => {
        this.cookies = {
            marketing: true,
            analytics: true,
            necessari: true
        };
        this.save();
    };

    denyAll = () => {
        this.cookies = {
            marketing: false,
            analytics: false,
            necessari: false
        };
        this.window.localStorage.removeItem(`${this.window.config.appname}_cookies`);
        this.emitChangeCookie();
        this.closeModal();
    };

  getCookies = () => JSON.parse(this.window.localStorage.getItem(`${this.window.config.appname}_cookies`) || '{"marketing": false, "analytics": false, "necessari": false}');

  emitChangeCookie = () => this.window.plussimple_signal.emit({name: 'update-cookies', detail: {}});

  save = () => {
    this.window.localStorage.setItem(`${this.window.config.appname}_cookies`, JSON.stringify(this.cookies));
    this.emitChangeCookie();
    this.closeModal();
  };

  openModal = () => {
    this.$("#cookiesModal").modal('show');
    
  }
  closeModal = () => {
    this.$("#cookiesModal").modal('hide');
  }

}