<div class="sticky-cookies" id="cookies" [hidden]="!display">
    <div class="cookies-wrap">
        <div class="left-colum">
            <h4>{{ window.dictionary.get('cookie_title') }}</h4>
            <p>{{ window.dictionary.get('cookie_description') }}</p>
        </div>
        <div class="right-colum">
            <button type="button" class="btn-full" (click)="acceptAll()">{{ window.dictionary.get('cookie_button1') }}</button>
            <button type="button" class="btn-full" (click)="denyAll()">{{ window.dictionary.get('cookie_button2') }}</button>
            <button type="button" class="btn-full btn-transparent" (click)="openCookiesModal()">{{ window.dictionary.get('cookie_button3') }}</button>
        </div>
    </div>
</div>