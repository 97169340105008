import { Component } from '@angular/core';

@Component({
  selector: 'coming-soon-modal',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.css']
})
export class ComingSoonComponent {
  window:any=<any>window;
  $:any=<any>this.window.$;
  notification:any = new this.window.HPNotification({parent: 'coming-soon-modal'});
  temp:any = {
    polizze: {},
    note: ""
  };
  model:any = {
    username: "",
    email: "",
    configuration: {
      type: 'fisica',
      nome: '',
      cognome: '',
      ragione_sociale: '',
      cf: '',
      p_iva: '',
      email: '',
      telefono: '',
      indirizzo: '',
      cap: '',
      provincia: '',
      citta: '',
      professione: ''
    }
  };
  professioni:any = [{
    name: "Commercialista",
    public_token: "pr0"
  }, {
    name: "Avvocato",
    public_token: "pr1"
  }, {
    name: "Architetto",
    public_token: "pr2"
  }, {
    name: "Geologo",
    public_token: "pr3"
  }, {
    name: "Avvocato di stato",
    public_token: "pr4"
  }, {
    name: "Ingegnere",
    public_token: "pr5"
  }, {
    name: "Geometra",
    public_token: "pr6"
  }, {
    name: "Agente immobiliare",
    public_token: "pr7"
  }, {
    name: "Amministratore di condominio",
    public_token: "pr8"
  }, {
    name: "Agronomo agrotecnico",
    public_token: "pr9"
  }, {
    name: "Perito industriale",
    public_token: "pr10"
  }, {
    name: "Medico",
    public_token: "pr11"
  }, {
    name: "Altro",
    public_token: "pr12"
  }];
  polizze:any = [{
    name: "RC Professionale",
    public_token: "po0"
  }, {
    name: "Tutela Legale",
    public_token: "po1"
  }, {
    name: "D&O",
    public_token: "po2"
  }, {
    name: "Property",
    public_token: "po3"
  }, {
    name: "Liability",
    public_token: "po4"
  }, {
    name: "Tempering",
    public_token: "po5"
  }, {
    name: "Welfare",
    public_token: "po6"
  }, {
    name: "Cyber",
    public_token: "po7"
  }, {
    name: "RC auto",
    public_token: "po8"
  }, {
    name: "Malattia e infortuni",
    public_token: "po9"
  }, {
    name: "Householder",
    public_token: "po10"
  }, {
    name: "Altro",
    public_token: "po11"
  }];

  constructor() {
    this.window.usher.config({
      onLogin: (myself) => {
        this.model.username = myself.username;
        this.model.email = myself.email;
        this.model.configuration = Object.assign({}, myself.configuration);
      }
    })
  }

  openModal = () => this.$("coming-soon-modal").fadeIn(300);
  closeModal = () => this.$("coming-soon-modal").fadeOut(300);

  getProfessioni = (pt:any = undefined) => !pt ? this.professioni : this.professioni.find(e => e.public_token == pt) || {};

  getPolizze = (pt:any = undefined) => !pt ? this.polizze : this.polizze.find(e => e.public_token == pt) || {};

  getCheckedPolizze = () => Array.from(this.window.document.querySelectorAll('input[polizza="true"]') || []).filter((e:any) => e.checked).map((e:any) => e.id) || [];

  check = () => {
    if(this.model.configuration.type == 'fisica') {
      if(!this.model.configuration.nome) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_nome')});
        return false;
      }
      if(!this.model.configuration.cognome) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_cognome')});
        return false;
      }
      if(!this.model.configuration.cf) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_codice_fiscale')});
        return false;
      }
    }
    if(this.model.configuration.type == 'giuridica') {
      if(!this.model.configuration.ragione_sociale) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_ragione_sociale')});
        return false;
      }
      if(!this.model.configuration.p_iva) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_partita_iva')});
        return false;
      }
    }
    if(!this.model.email) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_email')});
      return false;
    }
    if(!this.model.configuration.telefono) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_telefono')});
      return false;
    }
    if(!this.model.configuration.indirizzo) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_indirizzo')});
      return false;
    }
    if(!this.model.configuration.cap) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_cap')});
      return false;
    }
    if(!this.model.configuration.provincia) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_provincia')});
      return false;
    }
    if(!this.model.configuration.citta) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_citta')});
      return false;
    }
    if(!this.getCheckedPolizze().length) {
      this.notification.danger({text: this.window.dictionary.get('selezionare_polizza')});
      return false;
    }
    return true;
  };

  confirm = async () => {
    if(!this.check()) return;
    let obj = {
      username: this.model.username,
      email: this.model.email,
      note: this.temp.note,
      ...this.model.configuration,
      polizze: Object.keys(this.temp.polizze).map(e => !!this.temp.polizze[e] ? this.getPolizze(e) : "")
    }
    obj.professione = this.getProfessioni(obj.professione).name || "";
    try {
      await this.window.sf.utils.$http({
        url : `${this.window.config.urls.url_php}/endpoint.php/mail`,
        method : "PUT",
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        data : {
          data: obj
        }
      });
      this.notification.success({text: this.window.dictionary.get('richiesta_inviata_correttamente')});
      return this.closeModal();
    } catch(e) {
      console.log(e);
      return this.notification.danger({text: this.window.dictionary.get('errore_generico')});
    }
  }

}
