import { Component } from '@angular/core';

@Component({
  selector: 'app-convenzione',
  templateUrl: './convenzione.component.html',
  styleUrls: ['./convenzione.component.css']
})
export class ConvenzioneComponent {
  
    window:any=<any>window;
    AOS:any=<any>this.window.AOS;
    $:any=<any>this.window.$;
    constructor() { }

    ngAfterViewInit() {
        this.arrangeView();
        this.window.scrollTo(0,0);
    }
    
    show = (selector:any) => this.$(selector).fadeIn(300);
    hide = (selector:any) => this.$(selector).fadeOut(300);

    arrangeView = () => {

        this.$('.partner-slider').slick({
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 1000,
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: true,
            cssEase: 'linear',
            responsive: [
            {
              breakpoint: 1151,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
              }
            }
            ]
        });

        this.AOS.init({
            disable: 'mobile',
            duration: 800,
            anchorPlacement: 'center-bottom',
            once: true,
        });
    };

    getPacchetti = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'pacchetto' && (!pt || b.public_token == pt)) || [];

    getConvenzione = () => this.window.collection_data?.filter(b => b.configuration.type == 'convenzione')[0] || {configuration: {}};

    getInfoGenerali = (param:any) => this.window.info_data[param] || [];
}