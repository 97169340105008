import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-table-inputs',
  templateUrl: './table-inputs.component.html',
  styleUrls: ['./table-inputs.component.css']
})
export class TableInputsComponent {
    @Input() garanzia:any = {};
    @Input() model_param:any = {};

    window:any=<any>window;
    $:any=<any>this.window.$;
    changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });
    queryparams:any = () => this.route.snapshot.params;

    constructor(
        private route:ActivatedRoute,
        private router:Router,
        private cdr: ChangeDetectorRef
    ) {
        this.window.plussimple_signal.signaller.addEventListener('clear-configuratore', this.initModel.bind(this));
    };

    ngAfterContentInit() {
        console.log('table-inputs component');
        this.initModel();
    };

    ngOnDestroy() {
        this.window.plussimple_signal.signaller.removeEventListener('clear-configuratore', this.initModel.bind(this));
    };

    initModel = () => {
        if(!this.model_param) return;
        const params = Object.keys(this.window.middleware.tables.getParams(this.garanzia, this.model_param));
        for(const param of params) {
            this.model_param[param] = null;
        }
    };

    getAgenzia = (pt:any) => this.window.collection_agenzie?.find(el => el.public_token == pt) || {};

    getLink = (name:any) => !!name ? `${this.window.config.urls.url_media}/public/${name}` : './assets/icon/robot.svg';

    clearTable = () => this.window.plussimple_signal.emit({name: "table-selected", detail: {
        table: {
            price: 0,
            id_value: "",
            params: {
                ...this.model_param
            }
        },
        pt: this.garanzia.public_token
    }});

    show = (selector:any) => this.$(selector).fadeIn(300);
    hide = (selector:any) => this.$(selector).fadeOut(300);
}