<div class="sm-modal-custom first position-absolute" id="infoPreventivoModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></button>
            </div>
            <div class="modal-body custom-product-modal">

                <section class="title-bar insurance-bar space-remove">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 left-text">
                                <p style="font-size: 20px;">{{ window.dictionary.get('descrizione_info_preventivo') }} <a [href]="window.info_data.telefono1" style="white-space: nowrap;"><b>{{ window.info_data.telefono1 }}</b></a> {{ window.dictionary.get('descrizione_info_preventivo2') }}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <button class="btn btn-primary float-right mt-3" (click)="closeModal()">{{ window.dictionary.get('chiudi') }}</button>
            </div>
        </div>
    </div>
</div>