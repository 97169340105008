import { Component } from '@angular/core';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.css']
})
export class RestorePasswordComponent {
  window:any=<any>window;
  notification:any = new this.window.HPNotification({parent: 'app-restore-password'});
  $:any=<any>this.window.$;

  temp:any = {
    email: ""
  };
  constructor() { }

  openModal = () => this.$("app-restore-password").fadeIn(300);
  closeModal = () => this.$("app-restore-password").fadeOut(300);

  show = (selector:any) => this.$(selector).fadeIn(300);
  hide = (selector:any) => this.$(selector).fadeOut(300);

  confirm = async () => {
    try {
        await this.window.usher.getRestoreToken(this.temp.email);
        this.closeModal();
        return this.window.plussimple_signal.emit({name: 'notification', detail: {type: 'success', text: this.window.dictionary.get('email_recupero_password')}});
    } catch(e) {
        console.log(e);
        return this.window.plussimple_signal.emit({name: 'notification', detail: {type: 'danger', text: this.window.dictionary.get('errore_generico')}});
    }
  };
}
