import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';

import * as dictionary from '../assets/dictionary.json';
import { ComingSoonComponent } from './modals/coming-soon/coming-soon.component';
import { ProdottoComponent } from './prodotto/prodotto.component';
import { LoginComponent } from './modals/login/login.component';
import { SignupComponent } from './modals/signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { LogoComponent } from './logo/logo.component';
import { ProfessioniComponent } from './professioni/professioni.component';
import { PacchettiComponent } from './pacchetti/pacchetti.component';
import { ConvenzioniComponent } from './convenzioni/convenzioni.component';
import { NewConvenzioneComponent } from './modals/new-convenzione/new-convenzione.component';
import { ConvenzioneDocumentsComponent } from './modals/convenzione-documents/convenzione-documents.component';
import { PacchettoCardComponent } from './components/pacchetto-card/pacchetto-card.component';
import { QuestionarioComponent } from './modals/questionario/questionario.component';
import { GaranziaCardComponent } from './components/garanzia-card/garanzia-card.component';
import { ProfessioneCardComponent } from './components/professione-card/professione-card.component';
import { ConvenzioneCardComponent } from './components/convenzione-card/convenzione-card.component';
import { CheckMarkComponent } from './components/check-mark/check-mark.component';
import { AddClientComponent } from './modals/add-client/add-client.component';
import { ModalConfirmComponent } from './modals/modal-confirm/modal-confirm.component';
import { ConfiguratoreGaranziaComponent } from './components/configuratore-garanzia/configuratore-garanzia.component';
import { RestorePasswordComponent } from './modals/restore-password/restore-password.component';
import { ClientDetailComponent } from './modals/client-detail/client-detail.component';
import { PreventivoModalComponent } from './modals/preventivo-modal/preventivo-modal.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { ConvenzioneModalComponent } from './modals/convenzione-modal/convenzione-modal.component';
import { CookiesModalComponent } from './modals/cookies/cookies.component';
import { AssicurazioneComponent } from './modals/assicurazione/assicurazione.component';
import { RichTextModalComponent } from './modals/rich-text-modal/rich-text-modal.component';
import { TableInputsComponent } from './components/table-inputs/table-inputs.component';

import { IntegrazioniModule } from './integrazioni/integrazioni.module';
import { InfoPreventivoComponent } from './modals/info-preventivo/info-preventivo.component';

import { NewsDetailComponent } from './news-detail/news-detail.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { NewsComponent } from './news/news.component';
import { StickyComponent } from './components/sticky/sticky.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TeamComponent,
    ComingSoonComponent,
    ProdottoComponent,
    LoginComponent,
    SignupComponent,
    ProfileComponent,
    LogoComponent,
    ProfessioniComponent,
    PacchettiComponent,
    ConvenzioniComponent,
    NewConvenzioneComponent,
    ConvenzioneDocumentsComponent,
    PacchettoCardComponent,
    QuestionarioComponent,
    GaranziaCardComponent,
    ProfessioneCardComponent,
    ConvenzioneCardComponent,
    CheckMarkComponent,
    AddClientComponent,
    ModalConfirmComponent,
    ConfiguratoreGaranziaComponent,
    RestorePasswordComponent,
    ClientDetailComponent,
    PreventivoModalComponent,
    CookiesComponent,
    ConvenzioneModalComponent,
    CookiesModalComponent,
    AssicurazioneComponent,
    RichTextModalComponent,
    InfoPreventivoComponent,
    TableInputsComponent,
    NewsDetailComponent,
    NewsCardComponent,
    NewsComponent,
    StickyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    IntegrazioniModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  window:any=<any>window;
  notification:any = new this.window.HPNotification({parent: 'body'});

  queryparams:any = () => decodeURI(this.window.location.search)
  .replace('?', '')
  .split('&')
  .map(param => param.split('='))
  .reduce((values, [ key, value ]) => {
    values[ key ] = value
    return values
  }, {});
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.window.dictionary = {
      get: (text:any) => dictionary['it'][text]
    };

    this.window.usher.config({
      autoLoginOnLoad: true,
      onLogin: (myself) => {
        //this.router.navigate([`/profile`], { queryParams: this.queryparams() });
        if(myself.public_token_parent == -1) this.window.usher.logout();
      },
      onLogout: () => {
        this.router.navigate([`/home`], { queryParams: this.queryparams() });
      },
      public_token_parent: this.window.config.public_token_parent,
      public_token_manager: this.window.config.public_token_manager
    });

    this.window.plussimple_signal = {
        signaller: new EventTarget(),
        emit: (e) => this.window.plussimple_signal.signaller.dispatchEvent(new CustomEvent(e.name, {detail: e.detail || {}}))
    };

    this.window.plussimple_signal.signaller.addEventListener('goto', (ev:any) => this.router.navigate([ev.detail.route], { queryParams: this.queryparams() }));
    this.window.plussimple_signal.signaller.addEventListener('notification', (ev:any) => this.notification[ev.detail.type]({text: ev.detail.text}));
  };

}
