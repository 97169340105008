import { Component } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  window:any=<any>window;
  notification:any = new this.window.HPNotification({parent: 'app-login'});
  type:any = "login";
  $:any=<any>this.window.$;

  login_model:any = {
    username: "",
    password: "",
    remember: false
  }

  constructor() {}

  openModal = () => this.$("app-login").fadeIn(300);
  closeModal = () => this.$("app-login").fadeOut(300);

  show = (selector:any) => this.$(selector).fadeIn(300);
  hide = (selector:any) => this.$(selector).fadeOut(300);

  switchModal = () => {
    this.closeModal();
    this.$("app-signup").show();
  };

  getTitle = () => "Effettua il login";

  check = () => {
    if(!this.login_model.username) {
      this.notification.danger({text:"Inserire il campo username"});
      return false;
    }
    if(!this.login_model.password) {
      this.notification.danger({text:"Inserire il campo password"});
      return false;
    }
    return true;
  };

  login = async () => {
    if(!this.check()) return;
    try {
      await this.window.usher.login(this.login_model.username, this.login_model.password, this.login_model.remember);
      this.initModel();
      this.notification.success({text: "Login effettuato con successo"});
      return this.closeModal();
    } catch(e) {
      console.log(e);
      return this.notification.danger({text: "Errore. Riprova più tardi"});
    }
  };

  initModel = () => {
    this.login_model = {
      username: "",
      password: ""
    };
  };
}
