<section class="main-banner">
    <div class="banr-overlay">
        <div class="container">
            <div class="banr-text">
                <div class="banr-icon" data-aos="fade-up" data-aos-delay="200">
                    <img src="./assets/icon/robot.svg" alt="">
                </div>
                <div class="banr-caption text-center" data-aos="fade-up" data-aos-delay="300">
                    <h1><span>{{window.config.title}}, </span> <span>{{window.dictionary.get("assicurazione")}}</span><br><span> {{window.dictionary.get("dedicata_a")}} </span>
                        <!--<span>a </span>--> <strong class="typing">{{window.dictionary.get("professionisti")}}</strong>
                    </h1>
                </div>
            </div>
            <!--
            <div class="banr-form" data-aos="fade-up" data-aos-delay="400">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="{{window.dictionary.get('tua_azienda')}}">
                    <button class="btn btn-secondary" type="button">{{window.dictionary.get('trova_assicurazione')}}</button>
                </div>
            </div>
            <div class="col-12 banr-form">
                <button class="btn btn-block btn-secondary w-75 m-auto mt-5" (click)="show('coming-soon-modal')">{{window.dictionary.get('richiedi_informazioni')}}</button>
            </div>-->
        </div>
    </div>

</section>
<section class="sec-posts">
    <div class="container">
        <div class="post-slider">
            <div class="slick-slide" *ngFor="let professione of getProfessioniPacchetti(); index as i">
                <app-professione-card [professione]="professione"></app-professione-card>
            </div>
        </div>
        <div class="footr-link text-right"  data-aos="fade-up">
            <a [routerLink]="'/professioni'" class="line-link">
                <span>{{ window.dictionary.get('vedi_mestieri') }}</span>
                <i class="fal fa-arrow-right"></i>
            </a>
        </div>
    </div>
</section>
<section class="sec-assurance">
    <div class="container pb-5">
        <h2 class="text-center" data-aos="fade-up" data-aos-delay="100">{{ window.dictionary.get('assicurazione_misura') }}</h2>
        <div class="assur-slider" data-aos="fade-up" data-aos-delay="200">
            <div class="assur-slide" *ngFor="let pacchetto of getPacchetti(); index as i">
                <app-pacchetto-card [pacchetto]="pacchetto" [visualizza_button]="true"></app-pacchetto-card>
            </div>
        </div>
        <div class="footr-link text-right" data-aos="fade-up">
            <a [routerLink]="'/pacchetti'" class="line-link">
                <span>{{window.dictionary.get("vedi_prodotti")}}</span>
                <i class="fal fa-arrow-right"></i>
            </a>
        </div>
    </div>
    <div class="container pt-5">
        <h2 class="text-center" data-aos="fade-up" data-aos-delay="100" [hidden]="!getConvenzioni().length || !window.info_data.visualizza_convenzioni">{{ window.dictionary.get('nostre_convenzioni') }}</h2>
        <div class="convenzione-slider" [hidden]="!getConvenzioni().length || !window.info_data.visualizza_convenzioni">
            <div class="convenzione-slide" *ngFor="let convenzione of getConvenzioni().slice().reverse(); index as i">
                <app-convenzione-card [convenzione]="convenzione" [box]="true"></app-convenzione-card>
            </div>
        </div>
        <div class="footr-link text-right" data-aos="fade-up" [hidden]="!getConvenzioni().length || !window.info_data.visualizza_convenzioni">
            <a [routerLink]="'/convenzioni'" class="line-link">
                <span>{{window.dictionary.get("vedi_convenzioni")}}</span>
                <i class="fal fa-arrow-right"></i>
            </a>
        </div>

        <div class="full-card" data-aos="fade-up">
            <div class="card">
                <div class="card-body text-center">
                    <h2>{{window.dictionary.get("chi_siamo")}}</h2>
                    <p [innerHtml]="window.info_data.team.chi_siamo"></p>
                    <div class="row">
                        <div class="col-12 col-md-6 img-left">
                            <img src="https://s3.plussimple.fr/content/img/2019/12/08/5ded7cf207e56qui-sommes-nous-1.jpg"
                                alt="">
                        </div>
                        <div class="col-12 col-md-6 img-right">
                            <img src="https://s3.plussimple.fr/content/img/2019/12/08/5ded7d4102c3fqui-sommes-nous-2.jpg"
                                alt="">
                        </div>
                    </div>
                    <a class="line-link" data-aos="fade-up" [routerLink]="'/team'">
                        <span>{{window.dictionary.get("vedi_team")}}</span>
                        <i class="fal fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="partners-slider" data-aos="fade-up">
            <div class="container">
                <h2>{{window.dictionary.get("nostri_partner")}}</h2>
                <div class="partner-slider">
                    <div class="img-slide">
                        <img src="https://s3.plussimple.fr/content/img/2019/12/12/5df24abaadb49grassavoye-alt.svg"
                            alt="Gras Savoye">
                    </div>
                    <div class="img-slide">
                        <img src="https://s3.plussimple.fr/content/img/2019/12/12/5df269b117d8cbnp-alt.svg"
                            alt="BNP">
                    </div>
                    <div class="img-slide">
                        <img src="https://s3.plussimple.fr/content/img/2020/07/27/5f1ef58a25ea2ticker-beazley.svg"
                            alt="Beazley">
                    </div>
                    <div class="img-slide">
                        <img src="https://s3.plussimple.fr/content/img/2019/08/19/5d5b17f5a8d00generali.svg"
                            alt="Generali">
                    </div>
                    <div class="img-slide">
                        <img src="https://s3.plussimple.fr/content/img/2019/08/19/5d5b1980c8babtokio-marine.svg"
                            alt="Tokio Marine">
                    </div>
                    <div class="img-slide">
                        <img src="https://s3.plussimple.fr/content/img/2019/08/19/5d5b17c7a7035datarobot.svg">
                    </div>
                    <div class="img-slide">
                        <img src="https://s3.plussimple.fr/content/img/2019/08/19/5d5b161435258allianz.svg"
                            alt="Allianz">
                    </div>
                    <div class="img-slide">
                        <img src="https://s3.plussimple.fr/content/img/2019/12/26/5e0537bcd22c2besseagro-alt.svg"
                            alt="Besse Agro">
                    </div>
                    <div class="img-slide">
                        <img src="https://s3.plussimple.fr/content/img/2020/11/30/5fc52974b49eewakam-gris.svg"
                            alt="Wakam (ex La Parisienne)">
                    </div>
                    <div class="img-slide">
                        <img src="./assets/img/qbe.jpg"
                            alt="QBE">
                    </div>
                    <div class="img-slide">
                        <img src="./assets/img/arag.jpg"
                            alt="Arag">
                    </div>

                    <!-- LOGHI PARTNER SMAFBROKER -->
                    <div class="img-slide" [hidden]="window.config.appname != 'smafbroker'">
                        <img src="./assets/img/cna.jpg"
                            alt="CNA">
                    </div>
                    <div class="img-slide" [hidden]="window.config.appname != 'smafbroker'">
                        <img src="./assets/img/dual.jpg"
                            alt="Dual">
                    </div>
                    <div class="img-slide" [hidden]="window.config.appname != 'smafbroker'">
                        <img src="./assets/img/roland.jpg"
                            alt="Roland">
                    </div>

                    <!-- LOGHI PARTNER AGENCY -->
                    <div class="img-slide" [hidden]="window.config.appname != 'plussimple'">
                        <img src="./assets/img/amtrust.jpg"
                            alt="Amtrust">
                    </div>
                    <div class="img-slide" [hidden]="window.config.appname != 'plussimple'">
                        <img src="./assets/img/conte.jpg"
                            alt="Conte">
                    </div>
                    <div class="img-slide" [hidden]="window.config.appname != 'plussimple'">
                        <img src="./assets/img/eurete.jpg"
                            alt="Eurete">
                    </div>
                    <div class="img-slide" [hidden]="window.config.appname != 'plussimple'">
                        <img src="./assets/img/europ.jpg"
                            alt="Europ">
                    </div>
                    <div class="img-slide" [hidden]="window.config.appname != 'plussimple'">
                        <img src="./assets/img/sigma.jpg"
                            alt="Sigma">
                    </div>
                </div>
                <!--<div class="footr-link text-right">
                    <a href="#" class="line-link">
                        <span>{{window.dictionary.get("vedi_partner")}}</span>
                        <i class="fal fa-arrow-right"></i>
                    </a>
                </div>-->
            </div>
        </div>
    </div>
    <div class="hero-bg absolute w-100">
        <div class="absolute inset-0 overflow-hidden">
            <div class="circal-1 absolute rounded-full overflow-hidden transition-all ease-out duration-500">
                <div class="absolute inset-0 bg-brand-300" style="opacity:.4"></div>
            </div>
            <div class="circal-2 absolute rounded-full overflow-hidden transition-all ease-out duration-500">
                <div class="absolute inset-0 bg-brand-200" style="opacity:.52"></div>
            </div>
        </div>
    </div>
</section>
<section class="sec-testmonial">
    <div class="container">
        <h2 class="text-center" data-aos="fade-up">{{window.dictionary.get("piu_di")}} <span>30 000</span> {{window.dictionary.get("aziende")}}<br> {{window.dictionary.get("hanno_scelto")}} <span>{{window.config.title}}</span></h2>
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-4 aos-init aos-animate sm-box" *ngFor="let punto_forza of getInfoGenerali('punti_forza'); index as i" data-aos="fade-up" data-aos-delay="{{(i+1)*100}}">
                <h3><i class="fas fa-badge-check text-3xl text-brand mr-3"></i> {{punto_forza.title}}</h3>
                <p>{{punto_forza.description}}</p>
            </div>
        </div>
    </div>
</section>
<section class="sec-nos" [hidden]="true">
    <div class="container">
        <h2 data-aos="fade-up">{{window.dictionary.get("nostre_notizie")}}</h2>
        <div class="post-slider" data-aos="fade-up" data-aos-delay="200">
            <div class="slick-slide">
                <div class="card post-card">
                    <img src="https://s3.plussimple.fr/content/img/2020/12/01/5fc65a3b22a93difference-rc-decennale-320.jpg"
                        class="card-img-top" alt="...">
                    <div class="card-body text-left">
                        <h5><a href="#">Quelle est la différence entre RC et décennale ?</a></h5>
                        <div class="post-date">
                            <p>11 nov. 2020</p>
                            <p>Protection</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slick-slide">
                <div class="card post-card">
                    <img src="https://s3.plussimple.fr/content/img/2020/12/01/5fc654b979d27cout-decennale-320.jpg"
                        class="card-img-top" alt="...">
                    <div class="card-body text-left">
                        <h5><a href="#">Quelle est la différence entre RC et décennale ?</a></h5>
                        <div class="post-date">
                            <p>11 nov. 2020</p>
                            <p>Protection</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slick-slide">
                <div class="card post-card">
                    <img src="https://s3.plussimple.fr/content/img/2020/12/01/5fc6569ee284dlancer-btp-320.jpg"
                        class="card-img-top" alt="...">
                    <div class="card-body text-left">
                        <h5><a href="#">Quelle est la différence entre RC et décennale ?</a></h5>
                        <div class="post-date">
                            <p>11 nov. 2020</p>
                            <p>Protection</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slick-slide">
                <div class="card post-card">
                    <img src="https://s3.plussimple.fr/content/img/2020/12/01/5fc658cfa448bsite-engineer-construction-site-320.jpg"
                        class="card-img-top" alt="...">
                    <div class="card-body text-left">
                        <h5><a href="#">Quelle est la différence entre RC et décennale ?</a></h5>
                        <div class="post-date">
                            <p>11 nov. 2020</p>
                            <p>Protection</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slick-slide">
                <div class="card post-card">
                    <img src="https://s3.plussimple.fr/content/img/2020/08/07/5f2d0d294bca5courtiers-portefeuille-card.jpg"
                        class="card-img-top" alt="...">
                    <div class="card-body text-left">
                        <h5><a href="#">Quelle est la différence entre RC et décennale ?</a></h5>
                        <div class="post-date">
                            <p>11 nov. 2020</p>
                            <p>Protection</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footr-link text-right" data-aos="fade-up">
            <a href="#" class="line-link">
                <span>{{window.dictionary.get("vedi_articoli")}}</span>
                <i class="fal fa-arrow-right"></i>
            </a>
        </div>
    </div>
</section>
<section class="pre-footer">
    <div class="container">
        <div class="banr-icon">
            <img src="./assets/icon/robot.svg" alt="">
        </div>
        <h5 data-aos="fade-up">{{window.dictionary.get("assicurazione_digitale")}}</h5>
        <div class="inline-btn">
            <a href="tel:{{window.info_data.telefono1}}" class="btn btn-outline-dark" data-aos="fade-up" data-aos-delay="150"><i class="mr-1 fa-fas fa-phone fal"></i>
                {{window.info_data.telefono1}}
            </a>
            <a [routerLink]="'/pacchetti'" class="btn btn-outline-primary" data-aos="fade-up" data-aos-delay="300">{{window.dictionary.get("scopri_assicurazioni")}}</a>
        </div>
    </div>
</section>

<div class="hero-bg absolute bg-brand-500 w-100">
    <div class="absolute inset-0 overflow-hidden">
        <div class="circal-1 item-to bounce-1 absolute rounded-full overflow-hidden transition-all ease-out duration-500">
            <div class="absolute inset-0 bg-brand-800" style="opacity:.4"></div>
        </div>
        <div class="circal-2 item-to bounce-2 absolute rounded-full overflow-hidden transition-all ease-out duration-500">
            <div class="absolute inset-0 bg-brand-700" style="opacity:.52"></div>
        </div>
    </div>
</div>
