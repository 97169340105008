<h5 [hidden]="!getTables().length">{{ garanzia.name }}</h5>
<form class="mb-5" [hidden]="!garanzia.configuration.tables?.length">
    <div class="form-row cursor-pointer">
        <div class="row w-100">
            <div class="col-12 col-md-6 col-lg-4" *ngFor="let table of getTables(); index as i">
                <div class="supplementary-box mt-4" [ngClass]="{'selected': table_selected.id == table.id}"
                    (click)="selectTable(table)">
                    <h3 class="w-100 text-center custom-h3 mb-2">
                        {{ getAgenzia(table.agenzia)?.public_configuration?.nome || window.config.title || "" }}</h3>
                    <!--<h5 class="w-100 text-center custom-h3 mb-2"><a target="_blank" [href]="getAgenzia(table.agenzia)?.public_configuration?.sito_web">{{ window.dictionary.get('visita_sito') }}</a></h5>-->
                    <h5 class="w-100 text-center custom-h3 mb-4" *ngIf="!!showPrice(table)">{{ printPrice(table) }}</h5>
                    <!--<h5 class="w-100 text-center custom-h3 mb-4" *ngIf="!showPrice(table) && !!invalidFatturato(table)">{{ window.dictionary.get('configurazione_non_valida') }}</h5>-->
                    <app-check-mark [hidden]="table_selected.id != table.id"></app-check-mark>
                    <img class="menu-icon d-table mb-4" [src]="getLink(getAgenzia(table.agenzia)?.picture)" />
                    <p class="text-center">{{ getAgenzia(table.agenzia)?.public_configuration?.descrizione }}</p>
                </div>
            </div>
        </div>
    </div>
</form>