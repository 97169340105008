import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckMarkComponent } from '../check-mark/check-mark.component';

@Component({
  selector: 'app-garanzia-card',
  templateUrl: './garanzia-card.component.html',
  styleUrls: ['./garanzia-card.component.css']
})
export class GaranziaCardComponent {
  @Input() pt_garanzia:any;
  @Input() selected:any = false;
  @Input() preventivo_info:any = {};

  garanzia:any = {
    description: "",
    name: "",
    public_token: "",
    configuration: {
      description: ""
    }
  };

  window:any=<any>window;
  AOS:any=<any>this.window.AOS;
  $:any=<any>this.window.$;
  Typed:any=<any>this.window.Typed;
  changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });
  queryparams:any = () => this.route.snapshot.params;

  constructor(
    private route:ActivatedRoute,
    private router:Router
  ) {};

  ngAfterContentInit() {
    this.garanzia = this.window.collection_data?.find(b => b.public_token == this.pt_garanzia) || this.garanzia;
  }

  getAgenzia = () => !!this.preventivo_info?.agenzia ? this.window.collection_agenzie.find(e => e.public_token == this.preventivo_info?.agenzia) : {};

  getPriceTable = () => (!!this.preventivo_info && this.preventivo_info?.total_price != false) ? this.window.middleware.utils.formatPrice(this.preventivo_info?.total_price) : "Nessuna offerta";

  getDescription = () => this.garanzia.configuration.description.length > 300 ? `${this.garanzia.configuration.description.substr(0, 300)}...` : this.garanzia.configuration.description;
}