import { Component } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent {
    window:any=<any>window;
    $:any=<any>this.window.$;
    cookies:any = {
        marketing: false,
        analytics: false,
        necessari: false
    };
    display:any = true;

    constructor() {
        this.cookies = this.getCookies();
        this.checkCookie();
        this.window.plussimple_signal.signaller.addEventListener('update-cookies', (ev) => {
            this.cookies = this.getCookies();
            this.checkCookie();
        });
    }

    checkCookie = () => this.display = !this.cookies.necessari;

    acceptAll = () => {
        this.cookies = {
            marketing: true,
            analytics: true,
            necessari: true
        };
        this.window.localStorage.setItem(`${this.window.config.appname}_cookies`, JSON.stringify(this.cookies));
        this.display = false;
        this.emitChangeCookie();
    };
    
    denyAll = () => {
        this.cookies = {
            marketing: false,
            analytics: false,
            necessari: false
        };
        this.window.localStorage.removeItem(`${this.window.config.appname}_cookies`);
        this.display = false;
        this.emitChangeCookie();
    };

    getCookies = () => JSON.parse(this.window.localStorage.getItem(`${this.window.config.appname}_cookies`) || '{"marketing": false, "analytics": false, "necessari": false}');

    openCookiesModal = () => this.window.plussimple_signal.emit({name: 'open-modal-cookies', detail: {}});

    emitChangeCookie = () => this.window.plussimple_signal.emit({name: 'update-cookies', detail: {}});

    openModal = (selector:any) => this.$(selector).modal('show');
    hideModal = (selector:any) => this.$(selector).modal('hide');
}