<div class="grassavoye-product mb-4">
    <div class="g-product-img">
        <img [src]="'assets/img/icons-colored/' + pacchetto.configuration.icon" alt="">
    </div>
    <div class="g-product-body">
        <div class="title">
            <h4>{{ pacchetto.name }}</h4>
            <p class="price" *ngIf="getMinPrice() > 0"> {{ window.dictionary.get('a_partire_da') }} <span>{{ window.middleware.utils.formatPrice(getMinPrice()) }} </span> {{ window.dictionary.get('annuali') }}</p>
            <p class="price" *ngIf="getMinPrice() <= 0">{{ window.dictionary.get('quotazione_richiesta') }}</p>
        </div>
        <ul class="features">
            <!--<li *ngFor="let garanzia of pacchetto.configuration.garanzie; index as i">{{ getGaranzie(garanzia)[0]?.name }}</li>
            <li *ngFor="let garanzia of pacchetto.configuration.garanzie_opzionali; index as i">{{ getGaranzie(garanzia)[0]?.name }}</li>-->
            <li *ngFor="let description of pacchetto.configuration.array_convenzione_descriptions; index as i">{{ description }}</li>
        </ul>
        <!--<div [innerHtml]="getDescription()"></div>-->
        <div class="w-100 mt-3">
            <button (click)="openQuestionario()" class="btn btn-primary d-table m-auto">{{ window.dictionary.get("fai_preventivo") }}</button>
        </div>
    </div>
    <!--<div class="g-product-footer">
        <button (click)="openQuestionario()" class="btn btn-primary">{{ window.dictionary.get("fai_preventivo") }}</button>
    </div>-->
</div>