<h4>{{ garanzia.name }}<b *ngIf="!!garanzia.configuration.base_price"> {{ garanzia.configuration.base_price || 0 }}&euro;</b></h4> <!-- [hidden]="!window.middleware.tables.hasFatturato(garanzia) && !window.middleware.tables.getParams(garanzia, model_param)?.length" -->
<form class="mb-5" [hidden]="!garanzia.configuration.tables?.length">
    <div class="form-row">
        <div class="col-md-6 col-sm-12" *ngIf="!!window.middleware.tables.hasFatturato(garanzia) && !!model_param">
            <div class="form-group">
                <label [for]="'valore_assicurato_' + garanzia.public_token">{{ window.dictionary.get('fatturato') + " - " + garanzia.name }}</label>
                <input type="number" class="form-control" placeholder="0" [(ngModel)]="model_param.fatturato" (change)="clearTable()" [id]="'valore_assicurato_' + garanzia.public_token" [name]="'valore_assicurato_' + garanzia.public_token">
            </div>
        </div>
        <div class="col-md-6 col-sm-12" *ngFor="let item of window.middleware.tables.getParams(garanzia, model_param) | keyvalue;" [hidden]="!item.value?.length">
            <div class="form-group">
                <label [for]="item.key + '_' + garanzia.public_token" style="text-transform: capitalize;">{{ item.key + " - " + garanzia.name }}</label>
                <select class="form-control" [(ngModel)]="model_param[item.key]" (change)="clearTable()" [id]="item.key + '_' + garanzia.public_token" [name]="item.key + '_' + garanzia.public_token">
                    <option value="">-</option>
                    <option [ngValue]="''+v" *ngFor="let v of item.value; index as i">{{ v }}</option>
                </select>
            </div>
        </div>
    </div>
</form>