import { Component } from '@angular/core';

@Component({
  selector: 'app-assicurazione',
  templateUrl: './assicurazione.component.html',
  styleUrls: ['./assicurazione.component.css']
})
export class AssicurazioneComponent {
    window:any=<any>window;
    $:any=<any>this.window.$;
    notification:any = new this.window.HPNotification({parent: 'app-assicurazione'});
    
    queryparams:any = () => decodeURI(this.window.location.href)
    .split('?')[1]?.split('&')
    .map(param => param.split('='))
    .reduce((values, [ key, value ]) => {
        values[ key ] = value
        return values
    }, {}) || {};
    changeQueryParam:any = (values:any = {}) => new Object({ ...this.queryparams(), ...values });

    constructor() {}

}