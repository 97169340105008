import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvenzioneComponent } from '../convenzione/convenzione.component';

import { PacchettoConvenzioneCardComponent } from '../components/pacchetto-convenzione-card/pacchetto-convenzione-card.component';

@NgModule({
  declarations: [ConvenzioneComponent, PacchettoConvenzioneCardComponent],
  imports: [
    CommonModule
  ]
})
export class IntegrazioniModule { }
