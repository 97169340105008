<header id="header">
    <nav class="navbar navbar-expand-lg">
        <div class="container">
            <a class="navbar-brand" routerLink="/home">
                <app-logo></app-logo>
            </a>
            <button class="navbar-toggler" type="button">
                <i class="far fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse expanded" id="navbarNav">
                <ul class="navbar-nav">
					<li class="nav-item navbar-toggler-li">
						<div class="mega-dropdown">
							<a class="nav-link text-capitalize" (click)="scrollTo('#sezione_assicurazioni')" [routerLink]="">{{ window.config.appname == 'smafbroker' ? window.dictionary.get("assicurazioni") : window.dictionary.get("prodotti") }}<i class="far fa-angle-down"></i></a>
							<div class="mega-menu">
								<div class="menu-title">
									<h4>{{ window.dictionary.get("nostre_assicurazioni") }}</h4>
								</div>
								<div class="menu-list">
									<ul>
										<li *ngFor="let pacchetto of getPacchettiHeader(); index as i">
											<a [routerLink]="" (click)="scrollTo('div[public_token=&quot;'+ pacchetto.public_token + '&quot;]')">
												<div class="round-icon">
													<img class="menu-icon" [src]="'assets/img/icons-colored/' + pacchetto.configuration.icon" alt="">
												</div>
												<span>{{ pacchetto.name }}</span>
											</a>
                                        </li>
									</ul>
								</div>
								<div class="footr-link text-right">
									<a [routerLink]="" (click)="scrollTo('#sezione_assicurazioni')" class="line-link">
										<span>{{ window.dictionary.get("scoprile_tutte") }}</span>
										<i class="fal fa-arrow-right"></i>
									</a>
								</div>
							</div>
						</div>
					</li>
                    <!--<li class="nav-item navbar-toggler-li">
						<div class="mega-dropdown">
							<a class="nav-link text-capitalize" routerLink="/professioni">{{window.dictionary.get("professioni")}}<i class="far fa-angle-down"></i></a>
							<div class="mega-menu">
								<div class="menu-title">
									<h4>{{window.dictionary.get("nostre_professioni")}}</h4>
								</div>
								<div class="menu-list">
									<ul>
										<li *ngFor="let professione of getProfessioniHeader(); index as i">
											<a [routerLink]="'/pacchetti/'+professione.public_token">
												<div class="round-icon">
													<img class="menu-icon" [src]="'assets/img/icons-colored/' + professione.configuration.icon" alt="">
												</div>
												<span>{{ professione.name }}</span>
											</a>
                                        </li>
									</ul>
								</div>
								<div class="footr-link text-right">
									<a [routerLink]="'/professioni'" class="line-link">
										<span>{{ window.dictionary.get("scoprile_tutte") }}</span>
										<i class="fal fa-arrow-right"></i>
									</a>
								</div>
							</div>
						</div>
					</li>-->
                    <li class="nav-item navbar-toggler-li" (click)="show('coming-soon-modal')">
                        <a [routerLink]="" class="nav-link text-capitalize cursor-pointer">{{window.dictionary.get("richiedi_informazioni")}}</a>
                    </li>
                    <!--<li class="nav-item">
                        <a class="nav-link text-capitalize" [routerLink]="">{{window.dictionary.get("notizie")}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-capitalize" [routerLink]="">{{window.dictionary.get("brokers")}}</a>
                    </li>-->
                    <li class="nav-item navbar-toggler-li">
                        <a class="nav-link text-capitalize cursor-pointer" (click)="scrollTo('#sezione_chisiamo')" [routerLink]="">{{window.dictionary.get("chi_siamo")}}</a>
                    </li>
                    <!--<li class="nav-item navbar-toggler-li" *ngIf="window.info_data.visualizza_convenzioni">
                        <a class="nav-link text-capitalize cursor-pointer" [routerLink]="'/convenzioni'">{{window.dictionary.get("convenzioni")}}</a>
                    </li>-->
                </ul>
                <ul class="navbar-nav ml-auto">
                    <!--<li class="nav-item">
                        <a class="nav-link tel-link" href="tel:{{window.info_data.telefono1}}">
                            <i class="fas fa-phone mr-2"></i>
                            <span>{{window.info_data.telefono1}}</span>
                        </a>
                    </li>-->
                    <li class="nav-item">
                        <a [hidden]="!window.usher.isLogged()" class="nav-link btn btn-primary cursor-pointer text-white" [routerLink]="'/profile'">{{ getNome() }}</a>
                        <a [hidden]="!!window.usher.isLogged()" class="nav-link btn btn-primary cursor-pointer text-white" (click)="show('app-login')">{{ window.config.appname == 'smafbroker' ? window.dictionary.get("area_clienti") : window.dictionary.get("area_simplifier") }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>

<router-outlet></router-outlet>

<footer class="footer">
    <div class="container">
        <div class="d-flex">
            <div class="fotr-logo">
                <a [routerLink]="">
                    <app-logo></app-logo>
                </a>
            </div>
            <div class="fotr-menu">
                <div>
                    <a href="tel:{{window.info_data.telefono1}}">
                        {{window.info_data.telefono1}}
                    </a>
                    <a [hidden]="!window.info_data.telefono2" href="mailto:{{window.info_data.telefono2}}">
                        {{window.info_data.telefono2}}
                    </a>
                    <a href="mailto:{{window.info_data.email1}}">
                        {{window.info_data.email1}}
                    </a>
                    <a [hidden]="!window.info_data.email2" href="mailto:{{window.info_data.email2}}">
                        {{window.info_data.email2}}
                    </a>
                    <a [routerLink]="">
                        {{ window.dictionary.get("numero_rui") + ": " + window.info_data.numero_rui }}
                    </a>
                    <a [routerLink]="">
                        {{ window.dictionary.get("p_iva") + ": " + window.info_data.p_iva }}
                    </a>
                    <a [routerLink]="">
                        {{ window.dictionary.get("sede_legale") + ": " + window.info_data.sede_legale }}
                    </a>
                    
                </div>
                <div class="pl-10">
                    <a (click)="scrollTo('#sezione_assicurazioni')" [routerLink]="">{{ window.config.appname == 'smafbroker' ? window.dictionary.get("assicurazioni") : window.dictionary.get("prodotti") }}</a>
                    <!--<a routerLink="/professioni">{{window.dictionary.get("professioni")}}</a>
                    <a routerLink="/team">{{window.dictionary.get("team")}}</a>-->
                    
                    <a [routerLink]="" (click)="openDocument('termini_condizioni')">{{window.dictionary.get("termini_condizioni")}}</a>
                    <a [routerLink]="" (click)="openDocument('privacy_policy')">{{window.dictionary.get("privacy_policy")}}</a>
                    <a [routerLink]="" (click)="openDocument('cookie_policy')">{{window.dictionary.get("cookie_policy")}}</a>
                    <a [href]="getDocuments(window.info_data.modulo_reclami)" target="_blank">{{window.dictionary.get("modulo_reclami")}}</a>
                    <a [routerLink]="" (click)="openCookiesModal()">{{window.dictionary.get("cookie_settings")}}</a>
                    <a [routerLink]="" (click)="openDocument('note_legali')">{{window.dictionary.get("note_legali")}}</a>
                </div>
            </div>
<div class="fotr-social">
<a target="_blank" rel="noopener" href="{{window.info_data.social.facebook}}">
<span >Facebook</span>
<i class="fa-fw fab fa-fab fa-facebook-square"></i>
</a>
<a target="_blank" rel="noopener" href="{{window.info_data.social.twitter}}">
<span >Twitter</span>
<i class="fab fa-fab fa-twitter"></i>
</a>
<a target="_blank" rel="noopener" href="{{window.info_data.social.linkedin}}">
<span >LinkedIn</span>
<i class="fab fa-linkedin"></i>
</a>
<a target="_blank" rel="noopener" href="{{window.info_data.social.youtube}}">
<span >Youtube</span>
<i class="fab fa-youtube"></i>
</a>

</div>
</div>
<div class="fotr-copyright text-center">
{{window.config.title}} © 2021
</div>
</div>
</footer>

<!------MODALS-------->
<app-new-convenzione class="full-width-modal"></app-new-convenzione>
<app-convenzione-documents class="full-width-modal"></app-convenzione-documents>
<app-questionario class="full-width-modal"></app-questionario>
<app-add-client class="full-width-modal"></app-add-client>
<app-client-detail class="full-width-modal"></app-client-detail>
<app-modal-confirm class="full-width-modal"></app-modal-confirm>
<app-login class="full-width-modal"></app-login>
<app-signup class="full-width-modal"></app-signup>
<app-restore-password class="full-width-modal"></app-restore-password>
<coming-soon-modal class="full-width-modal"></coming-soon-modal>
<app-preventivo-modal class="full-width-modal"></app-preventivo-modal>
<app-assicurazione class="full-width-modal"></app-assicurazione>
<app-cookies-modal class="w-0 h-0 z-index-100"></app-cookies-modal>
<app-rich-text-modal class="full-width-modal"></app-rich-text-modal>

<app-convenzione-modal></app-convenzione-modal>

<!-- Cookies popover -->
<app-cookies></app-cookies>

<!-- Sticky Button's -->
<app-sticky></app-sticky>