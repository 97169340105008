import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-convenzioni',
  templateUrl: './convenzioni.component.html',
  styleUrls: ['./convenzioni.component.css']
})
export class ConvenzioniComponent {
  window:any=<any>window;
  AOS:any=<any>this.window.AOS;
  $:any=<any>this.window.$;
  Typed:any=<any>this.window.Typed;
  changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });
  random_index:any=0;
  collapseFaq:any = {};
  queryparams:any = () => this.route.snapshot.params;
  constructor(
    private route:ActivatedRoute,
    private router:Router
  ) {
    this.random_index = Math.floor((Math.random()*1000)%this.getProfessioni().length);
  };

  ngAfterViewInit(): void {
    this.arrangeView();
    this.window.scrollTo(0,0);
  };

  arrangeView() {
    this.AOS.init({
      disable: 'mobile',
      duration: 800,
      anchorPlacement: 'center-bottom',
      once: true,
    });
  };

  getInfoGenerali = (param:any) => this.window.info_data[param] || [];
  getProfessioni = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'professione' && (!pt || b.public_token == pt)) || [];
  getConvenzioni = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'convenzione' && (!pt || b.public_token == pt)) || [];
  getPicture = (pic:any) => `${this.window.config.urls.url_media}/public/${pic}`;
  getCover = () => `${this.window.config.urls.url_media}/public/${this.getProfessioni()[this.random_index].configuration.cover}`;
  getDescription = (convenzione:any) => convenzione.configuration.description.length > 300 ? `${convenzione.configuration.description.substr(0, 300)}...` : convenzione.configuration.description;
}