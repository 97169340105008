import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-professioni',
  templateUrl: './professioni.component.html',
  styleUrls: ['./professioni.component.css']
})
export class ProfessioniComponent {
  window:any=<any>window;
  AOS:any=<any>this.window.AOS;
  $:any=<any>this.window.$;
  Typed:any=<any>this.window.Typed;
  changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });
  random_index:any=0;
  collapseFaq:any = {};

  constructor(
    private route:ActivatedRoute,
    private router:Router
  ) {
    this.random_index = Math.floor((Math.random()*1000)%this.getProfessioni().length);
  }

  ngAfterViewInit(): void {
    this.arrangeView();
    this.window.scrollTo(0,0);
  }

  queryparams:any = () => this.route.snapshot.params;

  arrangeView() {
    this.AOS.init({
      disable: 'mobile',
      duration: 800,
      anchorPlacement: 'center-bottom',
      once: true,
    });
  }

  getInfoGenerali = (param:any) => this.window.info_data[param] || [];

  getGaranzie = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'garanzia' && (!pt || b.public_token == pt)) || [];
  getPacchetti = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'pacchetto' && (!pt || b.public_token == pt)) || [];
  getProfessioni = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'professione' && (!pt || b.public_token == pt)) || [];
  getProfessioniPacchetti = (pt:any = undefined) => this.getProfessioni(pt).reduce((a:any, b:any) => {
    a = a || [];
    if(!a.find(e => e.public_token == b.public_token) && this.getPacchetti().some(el => el.configuration.professioni.includes(b.public_token))) a.push(b);
    return a;
  }, []) || [];

  getPicture = (pic:any) => `${this.window.config.urls.url_media}/public/${pic}`;
  getCover = () => `${this.window.config.urls.url_media}/public/${this.getProfessioni()[this.random_index].configuration.cover}`;

}