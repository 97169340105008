<div class="login-page">
	<div class="container box-md-modal">
		<div class="inner-login-content">
			<div class="login-logo mb-2" data-aos="fade-up" data-aos-delay="100">
				<a class="back-page cursor-pointer" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></a>
				<a (click)="closeModal()">
					<app-logo></app-logo>
				</a>
			</div>
			<div class="login-title" data-aos="fade-up" data-aos-delay="100">
				<h3>Inserimento nuova convenzione</h3>
			</div>
			<form class="login-form" action="" data-aos="fade-up" data-aos-delay="100">
				<div class="form-group">
					<label for="key-convenzione">Inserisci il codice segreto che ti &egrave; stato comunicato dall'azienda</label>
					<input type="text" class="form-control" id="key-convenzione" name="key-convenzione" placeholder="Chiave segreta" [(ngModel)]="model.key">
				</div>

				<div class="form-group" [hidden]="!model.key.length">
					<button type="button" class="btn btn-primary" (click)="confirm()">Aggiungi</button>
				</div>
			</form>
		</div>
	</div>
</div>