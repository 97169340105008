import { Component } from '@angular/core';

@Component({
  selector: 'app-new-convenzione',
  templateUrl: './new-convenzione.component.html',
  styleUrls: ['./new-convenzione.component.css']
})
export class NewConvenzioneComponent {
  window:any=<any>window;
  notification:any = new this.window.HPNotification({parent: 'app-new-convenzione'});
  type:any = "login";
  $:any=<any>this.window.$;

  model:any = {
    key: ""
  };
  constructor() { }

  confirm = async () => {
    if(!this.model.key) return this.notification.danger({text: "Inserisci la chiave segreta."});
    try {
      await this.window.sf.utils.$http({
        url : `${this.window.config.urls.url_php}/endpoint.php/convenzione`,
        method : "POST",
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        data : {
          key: this.model.key,
          private_token: this.window.usher.get()?.private_token || ""
        }
      });
      this.notification.success({text: "Convenzione aggiunta con successo."});
      this.closeModal();
      this.window.plussimple_signal.emit({name: 'convenzione', detail: {public_token: this.window.collection_data.find(e => e.configuration.type == 'convenzione' && e.configuration.secret == this.model.key)?.public_token}});
      this.$('#Convenzione_modal').modal('show');
      this.model.key = "";
    } catch(e) {
      console.log(e);
      return this.notification.danger({text: "Attenzione. La chiave fornita non è disponibile."});
    }
  };
  
  openModal = () => this.$("app-new-convenzione").fadeIn(300);
  closeModal = () => this.$("app-new-convenzione").fadeOut(300);

}