import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pacchetto-card',
  templateUrl: './pacchetto-card.component.html',
  styleUrls: ['./pacchetto-card.component.css']
})
export class PacchettoCardComponent {
    @Input() pacchetto:any = {
        description: "",
        name: "",
        public_token: ""
    };
    @Input() visualizza_button:any = false;

    window:any=<any>window;
    AOS:any=<any>this.window.AOS;
    $:any=<any>this.window.$;
    Typed:any=<any>this.window.Typed;
    changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });
    queryparams:any = () => this.route.snapshot.params;

    constructor(
        private route:ActivatedRoute,
        private router:Router
    ) {};

    ngAfterViewInit(): void {
        this.arrangeView();
    };

    arrangeView() {
    };
    
    getGaranzie = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'garanzia' && (!pt || b.public_token == pt)) || [];

    calculateTax = (condizione:any = {}) => {
        if(isNaN(condizione.tasso)) return Infinity;
        if(condizione.tasso == 0 || !condizione.tasso) return condizione.minimo;
        return Math.max(condizione.minimo || 0, parseFloat(condizione.fatturato || 0)*parseFloat(condizione.tasso || 1)/100);
    };

    getMinPrice = (pacchetto:any) => pacchetto.configuration?.garanzie?.reduce((a:any, b:any) => {
        a = a || 0;
        let garanzia = this.getGaranzie(b)[0] || {},
            temp_tables = this.getGaranzie(b)[0].configuration.tables || [],
            tables = [],
            minimo = 0;

        if(temp_tables.length) {
        tables = temp_tables.map(e => e.values.sort((c:any, d:any) => this.calculateTax(c) >= this.calculateTax(d) ? 1 : -1));
        minimo = this.calculateTax(tables.sort((c:any, d:any) => this.calculateTax(c[0]) >= this.calculateTax(d[0]) ? 1 : -1)[0][0]);
        }

        if(!!garanzia.configuration.tax && garanzia.configuration.tax != 0) minimo += minimo*(garanzia.configuration.tax/100);

        let costo = garanzia.configuration.base_price + (minimo == Infinity ? 0 : minimo);

        if(!pacchetto.configuration.single_garanzia) return a += costo;
        return a = (!a || a > costo) ? costo : a;
    }, 0).toFixed(2) || 0;

    openQuestionario = () => {
        if(this.window.config.appname == 'plussimple' && !this.window.usher.isLogged()) {
            this.window.plussimple_signal.emit({name: 'open-info-preventivo', detail: {}});
            return;
        }
        this.window.plussimple_signal.emit({name: 'questionario', detail: {pacchetto: this.pacchetto}});
    }

    show = (selector:any) => this.$(selector).fadeIn(300);
    hide = (selector:any) => this.$(selector).fadeOut(300);
}