<div class="card round-card">
    <div class="card-body">
        <div class="card-head d-flex">
            <div class="card-icon mr-auto">
                <img class="menu-icon" [src]="'assets/img/icons-colored/' + pacchetto.configuration.icon"/>
            </div>
        </div>
        <h5>{{pacchetto.name}}</h5>
        <p class="card-text">{{pacchetto.description}}</p>
    </div>
    <div class="card-footer text-center">
        <div class="col-12 d-inline-block mb-2"><a [routerLink]="'/prodotto'" [queryParams]="changeQueryParam({'public_token': pacchetto.public_token})" [hidden]="!visualizza_button">{{ window.dictionary.get('visualizza_pacchetto') }}</a></div>
        <div class="col-12 d-inline-block"><a class="btn btn-primary btn-block" [routerLink]="" (click)="openQuestionario()">{{ window.dictionary.get('fai_preventivo') }}</a></div>
    </div>
</div>