<div class="lg-modal-custom first position-absolute" id="addclientModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></button>
            </div>
            <div class="modal-body custom-product-modal">
                <section class="title-bar insurance-bar space-remove">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-7 left-text">
                                <h2>{{ window.dictionary.get('info_cliente') }}</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="sec-popup-boxes mt-4">
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-6 col-sm-12">
                                <label for="client_name">{{ window.dictionary.get('nome') }}</label>
                                <input class="form-control" type="text" id="client_name" name="client_name" [(ngModel)]="getCliente().name">
                            </div>
                            <div class="form-group col-md-6 col-sm-12">
                                <label for="client_surname">{{ window.dictionary.get('cognome') }}</label>
                                <input class="form-control" type="text" id="client_surname" name="client_surname" [(ngModel)]="getCliente().surname">
                            </div>
                            <div class="form-group col-md-6 col-sm-12">
                                <label for="client_email">{{ window.dictionary.get('email') }}</label>
                                <input class="form-control" type="text" id="client_email" name="client_email" [(ngModel)]="getCliente().email">
                            </div>
                            <div class="form-group col-md-6 col-sm-12">
                                <label for="client_phone">{{ window.dictionary.get('telefono') }}</label>
                                <input class="form-control" type="text" id="client_phone" name="client_phone" [(ngModel)]="getCliente().phone">
                            </div>
                        </div>

                    </form>
                </section>

                
                <section class="sec-popup-text sec-popup-boxes mt-5">
                    <h2>{{ window.dictionary.get('assicurazioni_attive') }}</h2>
                    <div class="col-6 d-inline-block mb-4" *ngFor="let assicurazione of getAssicurazioni(); index as i">
                        <div class="card round-card">
                            <div class="card-body">
                                <div class="card-head d-flex">
                                </div>
                                <h5>{{ assicurazione.pacchetto.name }}</h5>
                                <p class="card-text">{{ window.dictionary.get('effettuata_il') + " " + window.moment(assicurazione.creation_timestamp).format('DD-MM-YYYY') }}</p>
                                <p class="card-text">{{ window.dictionary.get('pagamento_di') + " " + window.middleware.utils.formatPrice(assicurazione.price) }}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="sec-popup-text sec-popup-boxes mt-5">
                    <h2>{{ window.dictionary.get('preventivi_effettuati') }}</h2>
                    <div class="col-6 d-inline-block mb-4" *ngFor="let preventivo of getPreventivi(); index as i">
                        <div class="card round-card">
                            <div class="card-body">
                                <div class="card-head d-flex">
                                </div>
                                <h5>{{ preventivo.pacchetto.name }}</h5>
                                <p class="card-text">{{ window.dictionary.get('effettuata_il') + " " + window.moment(preventivo.creation_timestamp).format('DD-MM-YYYY') }}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <button class="btn btn-primary float-right mt-3" (click)="confirm()">{{ window.dictionary.get('aggiorna') }}</button>
            </div>
        </div>
    </div>
</div>