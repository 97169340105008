import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TeamComponent } from './team/team.component';
import { ProdottoComponent } from './prodotto/prodotto.component';
//import { ProfileComponent } from './profile/profile.component';
import { ProfessioniComponent } from './professioni/professioni.component';
import { PacchettiComponent } from './pacchetti/pacchetti.component';
import { ConvenzioniComponent } from './convenzioni/convenzioni.component';
import { ConvenzioneComponent } from './convenzione/convenzione.component';

const routes: Routes = [
  { path: '', component: ConvenzioneComponent },
  { path: 'home', component: ConvenzioneComponent },
  { path: 'team', component: TeamComponent },
  //{ path: 'profile', component: ProfileComponent },
  { path: 'prodotto', component: ProdottoComponent },
  { path: 'professioni', component: ProfessioniComponent },
  { path: 'pacchetti', component: PacchettiComponent },
  { path: 'pacchetti/:professione', component: PacchettiComponent },
  { path: 'convenzioni', component: ConvenzioniComponent },
  { path: '**', redirectTo: 'home', pathMatch: 'prefix' },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
