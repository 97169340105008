import { Component, ChangeDetectorRef, ElementRef } from '@angular/core';

@Component({
  selector: 'app-questionario',
  templateUrl: './questionario.component.html',
  styleUrls: ['./questionario.component.css']
})
export class QuestionarioComponent {
    window:any=<any>window;
    $:any=<any>this.window.$;
    notification:any = new this.window.HPNotification({parent: 'app-questionario'});

    rand = (num:any = null) => {
        num = num || 12;
        var text = "";
        var characters = "_ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < num; i++) {
            text += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return text;
    };
    note:any = {
        text: ""
    };
    temp_cliente:any = {};
    temp_model_cliente:any = {
        name: "",
        surname: "",
        email: "",
        phone: "",
        id: this.rand()
    };
    pacchetto:any = {
        configuration: {
            questionario: [],
            documents: [],
            garanzie_opzionali: [],
            garanzie: [],
            professioni: []
        }
    };
    current_focus:any = "";
    modelProxyHandler = (t,p:string = "",v) => {
        t[p] = v;
        /*if(p.includes("|")) p = p.split("|")[0];
        const question = this.getQuestion(p);
        if(question.type == 'risposta_multipla' || question.type == 'risposta_singola' || question.type == 'booleano') this.onChange(question);
        else this.onChange();*/
        return true;
    };
    temp_model:any = new Proxy({}, {
        set: this.modelProxyHandler.bind(this)
    });
    temp_model_opzionali:any = {};
    temp_model_indispensabili:any = {
        value: ""
    };
    modelVariantiProxyHandler = (t,p:string = "",v) => {
        t[p] = v;
        if(p.includes("|")) p = p.split("|")[0];
        const question = this.getQuestion(p);
        if(question.type == 'risposta_multipla' || question.type == 'risposta_singola' || question.type == 'booleano' || question.type == 'testo_numerico') this.onChange(question, true);
        else this.onChange(false, true);
        return true;
    };
    temp_model_varianti:any = new Proxy({}, {
        set: this.modelVariantiProxyHandler.bind(this)
    });
    eventHandler:any = (ev:any) => {
        this.pacchetto = ev.detail.pacchetto;
        this.openModal();
        this.populateInputParams();
        this.populateQuestionario();
        this.updateView();
    };
    questionari:any = {};
    questionari_varianti:any = {};
    table_selected:any = {};
    model_params:any = {};

    strict_keys_table = ["fatturato", "tasso", "minimo", "id"];
    update_view_timeoutdescriptor:any;
    constructor(
        private cdr: ChangeDetectorRef,
        private element: ElementRef
    ) {
        this.cdr.detach();
    }

    ngAfterContentInit() {
        this.window.plussimple_signal.signaller.addEventListener('questionario', this.eventHandler.bind(this));
        this.window.plussimple_signal.signaller.addEventListener('table-selected', this.setTable.bind(this));
        this.window.document.addEventListener('usher', this.updateView.bind(this));
    };

    ngOnDestroy() {
        this.window.plussimple_signal.signaller.removeEventListener('questionario', this.eventHandler.bind(this));
        this.window.plussimple_signal.signaller.removeEventListener('table-selected', this.setTable.bind(this));
        this.window.document.removeEventListener('usher', this.updateView.bind(this));
    };

    show = (selector:any) => this.$(selector).fadeIn(300);
    hide = (selector:any) => this.$(selector).fadeOut(300);

    updateView() {
        this.window.clearTimeout(this.update_view_timeoutdescriptor);
        this.update_view_timeoutdescriptor = setTimeout(() => {
            this.cdr.detectChanges();
        }, 50);
    };

    onBlur = (question) => {
        if(question.type == 'risposta_multipla' || question.type == 'risposta_singola' || question.type == 'booleano') this.onChange(question);
        else this.onChange();
    };

    sortQuestionari = (a:any, b:any) => a.value.name > b.value.name ? 1 : (a.value.name < b.value.name ? -1 : 0);

    resetModel = () => {
        this.questionari = {};
        this.questionari_varianti = {};
        this.table_selected = {};
        for(const key in this.model_params) {
            if(this.model_params[key] instanceof Object) {
                for(const key2 in this.model_params[key]) {
                    this.model_params[key][key2] = null;
                }
                continue;
            }
            this.model_params[key] = null;
        }
        this.temp_model_opzionali = {};
        this.temp_model_indispensabili = {
            value: ""
        };
        this.temp_model = new Proxy({}, {
            set: this.modelProxyHandler.bind(this)
        });
        this.temp_model_varianti = new Proxy({}, {
            set: this.modelVariantiProxyHandler.bind(this)
        });
    };

    // MODIFICA USER INTERFACE

    setQuestionari = (questionari:any = []) => {
        const new_questionari = this.window.middleware.questionari.getModel(questionari, this.temp_model, this.temp_model_varianti);
        Object.assign(this.questionari, new_questionari);
        for(const key in this.questionari) {
            if(!new_questionari[key]) delete this.questionari[key];
        }
    };

    setQuestionariVarianti = (questionari:any = []) => {
        const new_questionari = this.window.middleware.questionari.getModelVarianti(questionari, this.temp_model, this.temp_model_varianti);
        Object.assign(this.questionari_varianti, new_questionari);
        for(const key in this.questionari_varianti) {
            if(!new_questionari[key]) delete this.questionari_varianti[key];
        }
    };

    resetQuestionariVarianti = () => {
        let questions_varianti = this.getQuestionarioVarianti();
        for(const key in this.temp_model_varianti) {
            if(!questions_varianti.find(e => key.includes(e.id))) delete this.temp_model_varianti[key];
        }
    };

    getObjectArrayGaranzie = () => [...this.getGaranzieIndispensabili(), ...this.getGaranzieOpzionali()].map(e => this.window.middleware.garanzie.get(e));

    populateQuestionario = () => {
        let questionari = this.window.middleware.questionari.getUnfilteredQuestionari(this.getObjectArrayGaranzie(), this.table_selected);
        this.setQuestionari(questionari);
        this.setQuestionariVarianti(questionari);
        this.resetQuestionariVarianti();
    };

    populateInputParams = () => {
        let garanzie = [...(this.pacchetto.configuration?.garanzie || []), ...(this.pacchetto.configuration?.garanzie_opzionali || [])];
        for(const garanzia of garanzie) {
            if(!(this.model_params[garanzia] instanceof Object)) this.model_params[garanzia] = {};
            for(const key in this.model_params[garanzia]) {
                this.model_params[garanzia][key] = null;
            }
        }
    };

    getQuestionarioVarianti = () => {
        return Object.values(this.questionari_varianti)?.map((e:any) => e.configuration?.questions)?.flat() || [];
    };

    getQuestionariLength = (varianti:any = false) => Object.keys(!varianti ? this.questionari : this.questionari_varianti).length;

    showDomandePreliminari = () => {
        let garanzie = [];
        if(!!this.pacchetto.configuration?.single_garanzia) garanzie.push(this.temp_model_indispensabili.value);
        else garanzie.push(...this.getGaranzieIndispensabili());
        garanzie.push(...this.getGaranzieOpzionali());
        return this.window.middleware.questionari.showPreliminari(garanzie, this.model_params);
    };

    getIncrementoQuestionari = (garanzia_pt:any) => {
        var incremento = {
            fisso: 0,
            percentuale: 0,
            fatturato_selected: 0
        };
        for(const key in this.questionari_varianti) {
            let questionario:any = this.questionari_varianti[key] || {},
                garanzia = this.getGaranzia(garanzia_pt) || {},
                i=0;
            if(!garanzia.configuration.questionari.includes(key)) continue;
            for(; i<questionario.configuration?.questions?.length; i++) {
                let question = questionario.configuration.questions[i] || {},
                    answers:any = [];

                
                let ans = question.answers?.find(e => e.id == this.temp_model_varianti[question.id]);
                if(!!ans) answers.push(ans);
                if(question.type == 'testo_numerico') {
                    answers.length = 0;
                    answers.push(question.answers[0]);
                }
                if(question.type == 'risposta_multipla') {
                    answers.length = 0;
                    let answers_array = Object.keys(this.temp_model_varianti).filter(e => e.split("|")[0] == question.id && !!this.temp_model_varianti[e]).map(e => question.answers[e.split("|")[1]]) || [];
                    answers.push(...answers_array);
                }

                if(!answers.length) continue;
                for(const answer of answers) {
                    if(!answer.rules?.length) continue;
                    for(const rule of answer.rules) {
                        if(!rule.id) continue;
                        let incremento_target = incremento,
                            breaking = false;
                        if(!!rule.agenzia) {
                            incremento[rule.agenzia] = incremento[rule.agenzia] || {fisso: 0, percentuale: 0, fatturato_selected: 0};
                            incremento_target = incremento[rule.agenzia];
                        }
    
                        if(!!rule.condizione_risposta && !!rule.condizione_valore) {
                            if(rule.condizione_risposta == '>') breaking = this.temp_model_varianti[question.id] > rule.condizione_valore;
                            if(rule.condizione_risposta == '<') breaking = this.temp_model_varianti[question.id] < rule.condizione_valore;
                            if(rule.condizione_risposta == '=') breaking = this.temp_model_varianti[question.id] == rule.condizione_valore;
                            if(rule.condizione_risposta == '!=') breaking = this.temp_model_varianti[question.id] != rule.condizione_valore;
                            if(rule.condizione_risposta == '<>') breaking = this.temp_model_varianti[question.id] > rule.condizione_valore && this.temp_model_varianti[question.id] < rule.condizione_valore2;
                        } else breaking = true;
                        
                        if(!breaking) continue;
                        if(rule.operatore == 'block') {
                            incremento_target.fisso = Infinity;
                            incremento_target.percentuale = Infinity;
                            continue;
                        }
                        if(!rule.table?.name) {
                            if(rule.tipo_valore == 'fisso') incremento_target.fisso += (rule.operatore == 'aumenta' ? rule.valore : -rule.valore);
                            if(rule.tipo_valore == 'percentuale') incremento_target.percentuale += (rule.operatore == 'aumenta' ? rule.valore : -rule.valore);
                        } else {
                            incremento_target.fatturato_selected = this.temp_model_varianti[question.id];
                            if(!incremento_target.fatturato_selected) continue;
                            incremento_target.fisso += +(this.window.middleware.tables.getIncrementedPrice(garanzia, rule.table, +this.temp_model_varianti[question.id], {}, false, this.model_params).price || 0);
                        }
                    }
                }
            }
        }
        return incremento;
    };
    // FINE MODIFICA USER INTERFACE

    getMaxTableAnswer = (question:any) => {
        let questionario_variante = false;
        Object.keys(this.questionari_varianti)?.forEach((e:any) => {
            if(this.questionari_varianti[e]?.configuration?.questions?.find(q => q.id == question?.id)) questionario_variante = e;
        });
        if(!questionario_variante) return 0;
        let garanzie = [...this.getGaranzieOpzionali(), ...this.getGaranzieIndispensabili()];
        for(const temp_garanzia of garanzie) {
            let temp_garanzia_obj = this.getGaranzia(temp_garanzia);
            if(!temp_garanzia_obj) continue;
            if(temp_garanzia_obj.configuration?.questionari?.includes(questionario_variante) && !!this.model_params[temp_garanzia]) return this.model_params[temp_garanzia]?.fatturato-1;
        }
    };

    onChange(question:any = false, cleartable:any = false) {
        if(!!cleartable) {
            let garanzie = [...this.getGaranzieOpzionali(), ...this.getGaranzieIndispensabili()];
            for(const garanzia of garanzie) {
                if(!this.table_selected[garanzia]) continue;
                this.window.plussimple_signal.emit({name: 'table-selected', detail: {
                    table: {
                        params: {
                            ...this.table_selected[garanzia].params
                        }
                    },
                    pt: garanzia
                }});
            }
            this.table_selected = {};
            /*if(!!question && !!question.answers?.length && question.answers[0]?.rules?.find(e => !!e.table?.name)) {
                if(this.temp_model_varianti[question.id] > this.getMaxTableAnswer(question)) {
                    this.temp_model_varianti[question.id] = this.getMaxTableAnswer(question);
                    this.window.plussimple_signal.emit({name: 'notification', detail: {type: 'danger', text: this.window.dictionary.get('domanda_maggiore_fatturato')}});
                }
            }*/
        }
        if(!question || !question.answers.find(e => !!e.activate_questions?.length)) {
            this.setQuestionari(this.window.middleware.questionari.getUnfilteredQuestionari(this.getObjectArrayGaranzie(), this.table_selected));
            return this.updateView();
        }
        this.populateQuestionario();
        return this.updateView();
    };

    setTable = (ev:any) => {
        this.table_selected[ev?.detail?.pt] = ev?.detail?.table;
        this.temp_model = new Proxy({}, {
            set: this.modelProxyHandler.bind(this)
        });
        this.setQuestionari(this.window.middleware.questionari.getUnfilteredQuestionari(this.getObjectArrayGaranzie(), this.table_selected));
        //this.temp_model_opzionali = {};
        this.updateView();
    };

    clearModels = () => {
        this.table_selected = {};
        this.temp_model = new Proxy({}, {
            set: this.modelProxyHandler.bind(this)
        });
        this.temp_model_opzionali = {};
        this.window.plussimple_signal.emit({name: 'clear-configuratore', detail: {}});

        this.setQuestionariVarianti(this.window.middleware.questionari.getUnfilteredQuestionari(this.getObjectArrayGaranzie(), this.table_selected));
        this.resetQuestionariVarianti();

        this.populateInputParams();
        this.updateView();
    };

    checkCliente = async () => {
        if(!!this.temp_cliente.id) return true;
        if(!this.temp_model_cliente.name) {
            this.notification.danger({text: this.window.dictionary.get('seleziona_aggiungi_cliente')});
            return false;
        }
        if(!this.temp_model_cliente.surname) {
            this.notification.danger({text: this.window.dictionary.get('seleziona_aggiungi_cliente')});
            return false;
        }
        if(!this.temp_model_cliente.email) {
            this.notification.danger({text: this.window.dictionary.get('seleziona_aggiungi_cliente')});
            return false;
        }
        try {
            this.window.usher.get().public_configuration.clients = this.window.usher.get().public_configuration.clients || [];
            this.window.usher.get().public_configuration.clients.push(this.temp_model_cliente);
            await this.window.usher.get().update();
            this.temp_cliente = this.window.usher.get().public_configuration.clients.find(e => e.id == this.temp_model_cliente.id) || {};
            return true;
        } catch(e) {
            console.log(e);
            this.notification.danger({text: this.window.dictionary.get('errore_generico')});
            return false;
        }
    };

    isBreaking = () => {
        let breaking = false;
        this.getQuestionari().forEach((questionario:any, index:any) => {
            questionario.configuration?.questions?.forEach((question:any, i:any) => {
                if(question.type == 'risposta_multipla') {
                    if(Object.keys(this.temp_model).filter(el => el.split("|")[0] == question.id).some(el => !!this.temp_model[el] && !!question.answers[el.split("|")[1]]?.is_breaking)) breaking = true;
                } else {
                    if(Object.keys(this.temp_model).filter(el => el.split("|")[0] == question.id).some(el => (!!this.temp_model[el] || !isNaN(this.temp_model[el])) && !!question.answers.find(e => e.text == this.temp_model[el])?.is_breaking)) breaking = true;
                }
            });
        });
        return breaking;
    };

    check = () => {
        if(!this.window.usher.isLogged()) {
        this.notification.danger({text: this.window.dictionary.get('effettua_login')});
        this.show('app-login');
        return false;
        }
        let checked = true;
        if(!!this.pacchetto.configuration?.single_garanzia && !this.table_selected[this.temp_model_indispensabili?.value]?.id && !this.temp_model_indispensabili.value) {
        this.notification.danger({text: this.window.dictionary.get('compila_garanzia_indispensabile')});
        return false;
        }
        for(let i = 0, questionario, questions; i < this.getQuestionari()?.length; i++) {
        questionario = this.getQuestionari()[i];
        questions = questionario.configuration?.questions || [];
        for(let j = 0, question; j < questions?.length; j++) {
            question = questions[j];
            if(!!question.optional) continue;
            if(question.type == 'risposta_multipla') {
            if(!Object.keys(this.temp_model).find(e => e.split("|")[0] == question.id) || 
                Object.keys(this.temp_model).filter(e => e.split("|")[0] == question.id).every(el => !this.temp_model[el])) {
                    checked = false;
                    break;
                }
            } else {
                if(!Object.keys(this.temp_model).includes(question.id) || (!this.temp_model[question.id] && isNaN(this.temp_model[question.id]))) { // Object.keys(this.temp_model).every(el => !this.temp_model[el])
                checked = false;
                break;
                }
            }
        }
        if(!checked) break;
        }
        if(!checked) {
        this.notification.danger({text: this.window.dictionary.get('rispondi_domande_questionari')});
        return checked;
        }
        return checked;
    };

    isQuestionCompleted = (question:any) => {
        if(!!question?.optional || question?.type != 'risposta_multipla') return true;
        if(Object.keys({...this.temp_model, ...this.temp_model_varianti}).filter(e => e.split("|")[0] == question?.id).every(el => !this.temp_model[el] && !this.temp_model_varianti[el])) return false;
        return true;
    };

    // Funzione usata nell'html per evitare di segnare rosso le domande numeriche con risposta 0
    isNotCompleted = (question) => !question?.optional && isNaN((this.temp_model[question?.id] || this.temp_model_varianti[question?.id]) ?? NaN);

    confirm = async () => {
        if(!this.check()) return;
        if(this.window.config.appname == 'plussimple' && !(await this.checkCliente())) return;
        //if(!!this.isBreaking()) return this.notification.danger({text: `Una o più risposte impediscono la generazione automatica del preventivo. Verrà inviata una mail ad un nostro operatore e verrai contattato al più presto.`});
        //console.log(this.temp_model);
        let parsed_keys = Object.keys(this.temp_model).reduce((a:any, b:any) => {
            a = a || [];
            if(!a.includes(b.split("|")[0])) a.push(b.split("|")[0]);
            return a; 
            }, []),
            questionari_obj = parsed_keys.reduce((a:any, b:any) => {
            a = a || {};
            let questionari = this.getQuestionari(),
                question = this.getQuestion(b),
                questionario = questionari.find(e => e.configuration.questions.find(el => el.id == b)),
                temp_obj = {
                    value: [],
                    id_question: b
                };
            a[questionario.public_token] = a[questionario.public_token] || [];
            if(question.type == 'risposta_multipla') {
                temp_obj.value.push(...Object.keys(this.temp_model).filter(el => el.includes(b) && !!this.temp_model[el]).map(j => question.answers[+(j.split("|")[1])].id));
            } else temp_obj.value.push(this.temp_model[b]);
            a[questionario.public_token].push(temp_obj);
            return a;
            }, {}),
            table_selected_indispensabili = !!this.pacchetto.configuration.single_garanzia ? [{
                public_token: this.temp_model_indispensabili.value,
                id_table: this.table_selected[this.temp_model_indispensabili.value]?.id || "",
                id_value: this.table_selected[this.temp_model_indispensabili.value]?.id_value || "",
                agenzia: this.table_selected[this.temp_model_indispensabili.value]?.agenzia || "",
                questionari: this.table_selected[this.temp_model_indispensabili.value]?.questionari || [],
                templates: this.table_selected[this.temp_model_indispensabili.value]?.templates || [],
                documents: this.table_selected[this.temp_model_indispensabili.value]?.documents || [],
                params: this.table_selected[this.temp_model_indispensabili.value]?.params || {}
            }] : this.pacchetto.configuration.garanzie?.reduce((a:any, b:any) => {
                a = a || [];
                let obj:any = {
                    public_token: b
                };
                if(this.getGaranzia(b)?.configuration?.tables?.length) {
                    obj.id_table = this.table_selected[b]?.id || "";
                    obj.id_value = this.table_selected[b]?.id_value || "";
                    obj.agenzia = this.table_selected[b]?.agenzia || "";
                    obj.questionari = this.table_selected[b]?.questionari || [];
                    obj.templates = this.table_selected[b]?.templates || [];
                    obj.documents = this.table_selected[b]?.documents || [];
                    obj.params = this.table_selected[b]?.params || {};
                }
                a.push(obj);
                return a;
            }, []),
            table_selected_opzionali = this.pacchetto.configuration?.garanzie_opzionali.filter(e => !!this.temp_model_opzionali[e])?.reduce((a:any, b:any) => { // Object.keys(this.table_selected)
                a = a || [];
                let obj:any = {
                    public_token: b
                };
                if(this.getGaranzia(b)?.configuration?.tables?.length) {
                    obj.id_table = this.table_selected[b]?.id || "";
                    obj.id_value = this.table_selected[b]?.id_value || "";
                    obj.agenzia = this.table_selected[b]?.agenzia || "";
                    obj.questionari = this.table_selected[b]?.questionari || [];
                    obj.templates = this.table_selected[b]?.templates || [];
                    obj.documents = this.table_selected[b]?.documents || [];
                    obj.params = this.table_selected[b]?.params || {};
                }
                a.push(obj);
                return a;
            }, []),
            obj = {
                pacchetto: this.pacchetto.public_token,
                table_selected_indispensabili: table_selected_indispensabili,
                table_selected_opzionali: table_selected_opzionali,
                questionario: questionari_obj,
                note: this.note.text,
                cliente: this.temp_cliente
            };
        try {
        //if(!this.checkPrice()) {
            await this. window.sf.utils.$http({
                url : `${this.window.config.urls.url_php}/endpoint.php/payment`,
                method : "POST",
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                data : {
                    data: obj,
                    type: 'preventivo',
                    private_token: this.window.usher.get().private_token,
                    public_token_parent: this.window.config.public_token_parent
                }
            });
            alert(this.window.dictionary.get('preventivo_in_valutazione'));
            this.temp_model = new Proxy({}, {
                set: this.modelProxyHandler.bind(this)
            });
            this.closeModal();
            return this.window.plussimple_signal.emit({name: "goto", detail: {route: "/profile"}});
        //}
        /*if(!!this.checkPrice()) {
            let result = await this. window.sf.utils.$http({
            url : `${this.window.config.urls.url_php}/endpoint.php/payment`,
            method : "POST",
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            data : {
                data: obj,
                private_token: this.window.usher.get().private_token,
                public_token_parent: this.window.config.public_token_parent
            }
            });
            this.closeModal();
            this.temp_model = {};
            return this.window.middleware.utils.openLink({
            url : result.link,
            target : '_self'
            });
        }*/
        } catch(e) {
            console.log(e);
            return this.notification.danger({text: this.window.dictionary.get('errore_generico')});
        }
    };

    showNextStep = (i:any = 0) => {
        if(i == 0) return true;
        let questionari_arr = <any>Object.values(this.questionari).sort((a:any, b:any) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0).splice(0, i);
        for(const questionario of questionari_arr) {
            for(let j = 0, question; j < questionario?.configuration?.questions?.length; j++) {
                question = questionario?.configuration?.questions[j];
                if(!!question.optional) continue;
                if(question.type == 'risposta_multipla') {
                    if(!Object.keys(this.temp_model).find(e => e.split("|")[0] == question.id) || 
                        Object.keys(this.temp_model).filter(e => e.split("|")[0] == question.id).every(el => !this.temp_model[el])) return false;
                } else {
                    if(!Object.keys(this.temp_model).includes(question.id) || Object.keys(this.temp_model).every(el => !this.temp_model[el])) return false;
                }
            }
        }
        return true;
    };

    getGaranzia = (pt:any = undefined) => this.window.collection_data?.find(b => b.configuration.type == 'garanzia' && (!pt || b.public_token == pt)) || { configuration: {}};
    getQuestionario = (pt:any = undefined) => this.window.collection_data?.find(b => b.configuration.type == 'questionario' && (!pt || b.public_token == pt)) || { configuration: {}};
    getQuestion = (id:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'questionario').reduce((a:any, b:any) => {
        a = a || {};
        if(b.configuration.questions.find(e => e.id == id)) a = b.configuration.questions.find(e => e.id == id);
        return a;
    }, {});
    getPacchetti = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'pacchetto' && (!pt || b.public_token == pt)) || [];
    getProfessioni = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'professione' && (!pt || b.public_token == pt)) || [];
    getProfessioniPacchetti = (pt:any = undefined) => this.getProfessioni(pt).reduce((a:any, b:any) => {
        a = a || [];
        if(!a.find(e => e.public_token == b.public_token) && this.getPacchetti().some(el => el.configuration.professioni.includes(b.public_token))) a.push(b);
        return a;
    }, []) || [];
    
    getGaranzieOpzionali = () => Object.keys(this.temp_model_opzionali).filter(e => !!this.temp_model_opzionali[e]) || [];
    getGaranzieIndispensabili = () => this.pacchetto?.configuration?.single_garanzia ? (!!this.temp_model_indispensabili.value ? [this.temp_model_indispensabili.value] : []) : this.pacchetto.configuration?.garanzie || [];

    getQuestionari = () => {
        let garanzie = [...this.getGaranzieIndispensabili(), ...this.getGaranzieOpzionali()].map(e => this.getGaranzia(e));
        let questionari = garanzie.reduce((a:any, b:any) => {
            a = a || [];
            let array_pt = [],
                table_selected_values:any = Object.values(this.table_selected).filter((e:any) => !!e.id);
            for(let i=0; i<table_selected_values.length; i++) {
                for(let j=0; j<table_selected_values[i].questionari.length; j++) {
                    if(!array_pt.includes(table_selected_values[i].questionari[j])) array_pt.push(table_selected_values[i].questionari[j]);
                }
            }
            for(let i=0; i<b.configuration.questionari.length; i++) {
                if(!array_pt.includes(b.configuration.questionari[i])) array_pt.push(b.configuration.questionari[i]);
            }
            a.push(...array_pt?.filter(el => !a.find(e => e.public_token == el)).map(k => this.getQuestionario(k)) || []);
            return a;
        }, []).sort((a:any, b:any) => (a.name > b.name) ? 1 : ((a.name == b.name) ? 0 : -1));
        return questionari;
    };

    getMassimali = (pt_garanzia:any = undefined) => this.getGaranzia(pt_garanzia).configuration.tables?.reduce((a:any, b:any) => {
        a = a || [];
        b.values.forEach(el => !a.includes(el.massimale) ? a.push(el.massimale) : "");
        return a;
    }, []);
    
    getPriceIndispensabili:any = () => this.getGaranzieIndispensabili()?.reduce((a:any, b:any) => { // Object.keys(this.table_selected)
        a = a || 0;
        let garanzia = this.getGaranzia(b);
        if(!!garanzia.configuration?.tables?.length) a += this.table_selected[b]?.price || 0;
        else a += garanzia.configuration.base_price || 0;
        return a;
    }, 0);

    getPriceOpzionali:any = () => this.pacchetto.configuration?.garanzie_opzionali.filter(e => !!this.temp_model_opzionali[e])?.reduce((a:any, b:any) => { // Object.keys(this.table_selected)
        a = a || 0;
        let garanzia = this.getGaranzia(b);
        if(!!garanzia.configuration?.tables?.length) a += this.table_selected[b]?.price || 0;
        else a += garanzia.configuration.base_price || 0;
        return a;
    }, 0);

    showQuestionPrice = (question) => {
        let price = this.window.middleware.questions.getPrice(question, undefined, [...this.getGaranzieIndispensabili(), ...this.getGaranzieOpzionali()], this.table_selected, this.temp_model, this.temp_model_varianti, this.model_params);
        return (!isNaN(price) && price != Infinity && price != 0) ? 
            this.window.middleware.utils.formatPrice(price) : (price == Infinity ? "bloccante" : "");
    };

    questionarioInGaranzia = (questionario:any, garanzia:any) => {
        if(garanzia.configuration.questionari.includes(questionario?.public_token)) return true;
        if(garanzia.configuration.tables.find(e => e.questionari?.includes(questionario?.public_token))) return true;
        return false;
    };

    getTableKeys = (garanzia:any) => Object.keys(garanzia.configuration.tables[0]?.values[0] || {}).filter(k => !this.strict_keys_table.includes(k));

    handleOpzionale = (garanzia:any) => {
        if(!this.temp_model_opzionali[garanzia]) this.table_selected[garanzia] = {};
        this.updateView();
        return;
    };

    showQuestionario = () => {
        let opzionali = false,
            indispensabili = false;
        if(!!this.pacchetto.configuration?.single_garanzia) {
            let garanzia = this.getGaranzia(this.temp_model_indispensabili.value);
            indispensabili = !!this.temp_model_indispensabili.value && (!garanzia.configuration.tables.length || !!this.table_selected[this.temp_model_indispensabili.value]?.id);
        }
        else indispensabili = this.getGaranzieIndispensabili().every(el => {
            let garanzia = this.getGaranzia(el);
            if(!garanzia.configuration?.tables?.length) return true;
            if(!!this.table_selected[el]?.id) return true;
            return false;
        });
        opzionali = this.getGaranzieOpzionali().every(el => {
            let garanzia = this.getGaranzia(el);
            if(!garanzia.configuration?.tables?.length) return true;
            if(!!this.table_selected[el]?.id) return true;
            return false;
        });
        return indispensabili && opzionali;
    };

    getAllQuestionPrice = () => this.getQuestionari()?.reduce((a:any, questionario:any) => {
        a = a || 0;
        let garanzie = [...this.getGaranzieIndispensabili(), ...this.getGaranzieOpzionali()].map(k => this.getGaranzia(k)).filter(e => !!this.questionarioInGaranzia(questionario, e));
        a += questionario?.configuration?.questions?.reduce((c:any, d:any) => {
            c = c || 0;
            for(let i = 0; i<garanzie.length; i++) {
                c += this.window.middleware.questions.getPrice(d, garanzie[i], [...this.getGaranzieIndispensabili(), ...this.getGaranzieOpzionali()], this.table_selected, this.temp_model, this.temp_model_varianti);
            }
            return c;
        }, 0);
        return a;
    }, 0);

    getFinalPrice = () => {
        let price_indispensabili = (+this.getPriceIndispensabili()),
            price_opzionali = (+this.getPriceOpzionali()),
            price_allquestion = (+this.getAllQuestionPrice());
        
        return  (//(!!this.pacchetto?.configuration?.single_garanzia && !this.table_selected[this.temp_model_indispensabili?.value]?.id && !this.temp_model_indispensabili.value) || 
                        (!this.showQuestionario())
                        || isNaN((price_indispensabili + price_opzionali + price_allquestion)) 
                        || (price_indispensabili + price_opzionali + price_allquestion) == Infinity 
                        || (price_indispensabili + price_opzionali + price_allquestion) == 0 
                        || this.isBreaking()) ? this.window.dictionary.get('necessita_valutazione') : (price_indispensabili + price_opzionali + price_allquestion).toFixed(2);
    };

    checkPrice = () => this.getFinalPrice() != Infinity && !isNaN(this.getFinalPrice());

    openModal = () => {
        this.$("app-questionario").fadeIn(300);
        this.updateView();
    };
    closeModal = () => {
        this.$("app-questionario").fadeOut(300);
        this.updateView();
        this.resetModel();
    };

    getLink = (doc:any) => `${this.window.config.urls.url_media}/get.php?private_token=${this.window.usher.getPrivateToken()}&name=${doc.link}`;

    getPicture = (pic:any, fallback:any = 'assets/img/inner-banner.jpg') => !!pic? `${this.window.config.urls.url_media}/get.php?private_token=${this.window.usher.getPrivateToken()}&name=${pic}` : fallback;

    getQuestions = (questionario:any, varianti:any = false) => questionario.configuration?.questions?.filter(e => {
        let questions_activate = questionario.configuration?.questions?.filter(q => q.answers?.find(a => !!a.activate_questions?.includes(e.id))) || [];
        if(!questions_activate.length) return true;
        for(const question of questions_activate) {
            let correct_answers = question.answers?.filter(a => a.activate_questions?.includes(e.id)) || [],
                model = !!varianti ? this.temp_model_varianti : this.temp_model;
            if(!!varianti && question.type == 'risposta_multipla') {
                let answers_index = Object.keys(this.temp_model_varianti).filter(t => !!t.includes(question.id) && !!this.temp_model_varianti[t]).map(t => t.split("|")[1]),
                    myanswers = [...answers_index.map(k => question.answers[+k])];
                if(!correct_answers.find(a => myanswers.find(m => m.id == a.id))) return false;
                return true;
            }
            if(!correct_answers.find(c => !!model[question.id] && model[question.id] == c.id)) return false;
        }
        return true;
    });
}