<div class="full-card pb-0 pt-1" [ngClass]="{'slick-active': box}">
    <div class="card">
        <div class="card-body text-center">
            <h4 class="mb-3 double-height-h4">{{ convenzione.name }}</h4>
            <p class="convenzione-description" [innerHtml]="getDescription()"></p>
            <div class="row">
                <div class="col-12">
                    <img class="convenzione-image" [src]="getPicture(convenzione.configuration.image)" alt="">
                </div>
            </div>
            <!--<a [hidden]="!!window.usher.isLogged()" class="line-link cursor-pointer" (click)="show('app-login')">
                <span>{{ window.dictionary.get('effettua_login') }}</span>
            </a>-->
            <a class="line-link cursor-pointer" (click)="showConvenzione()"><!-- [hidden]="!showButton()"  [hidden]="!window.usher.isLogged()" -->
                <span>{{ window.dictionary.get('visualizza_convenzione') }}</span>
            </a>
        </div>
    </div>
</div>