<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1200 320.6" style="enable-background:new 0 0 1200 320.6;" xml:space="preserve"><style type="text/css">
	.st0{fill:#27348B;}
	.st1{fill:#0E488E;}
	.st2{fill:#025A99;}
	.st3{fill:#6BCFF1;}
	.st4{fill:#FFFFFF;}
	.st5{fill:#04427C;}
</style><g><g><path class="st0" d="M1063,149.3h-0.2c-4.1-0.7-7.3,2.8-9.8,9.8c-1.8-10.4-3.7-13.2-5.5-14.7c-1.5-1.1-2.8-1.1-3.7-1
			c-3.9,0.7-7,4.4-9.3,9.1v-7.2c0.2-1.6-1.1-2.8-2.7-2.8c-1.6,0-2.9,1.1-3.8,2.8v31.8c0.9,1.6,2,2.8,3.6,3h0.2
			c1.5-0.3,2.8-1.2,2.9-2.9c0,0,0.7-7.2,2.4-14.5c2.8-10.7,5.5-13.4,7-13.8c2,2.6,4.2,15.1,4.9,28.2c0,1.5,1.3,2.6,2.8,2.6
			s2.8-1.3,2.9-2.8c0,0,0.7-6.8,2.3-13c2.3-8.5,4.6-11.4,5.5-11.4c1.5,0,2.8,3.1,3.9,7.3c1.1,4.6,1.5,11.2,1.6,15
			c0,0.8,0,1.6,0.2,2.1c0.2,1.6,1.5,2.8,3.1,2.8c1.6-0.2,2.8-1.5,2.8-3.1c0-0.7,0-1.3-0.2-2.1C1073.6,165.5,1072.6,148.7,1063,149.3
			z"/><path class="st0" d="M984.8,140.1c-5.9-2.1-19.7-6.8-19.5-11.7c0-1.3,0.3-3.1,1.6-4.4c1.1-1,2.8-1.5,5.2-1.5
			c5,0.2,5.5,3.6,5.5,4.4c0.2,1.3,1.5,2.8,3.1,2.8c0.7,0,1.6-0.2,2.3-0.8c0.3-0.3,0.7-0.8,0.5-1.6c-0.2-3.1-2.8-9.1-11.2-9.4
			c-3.4-0.2-6.8,0.2-9.4,2.6c-2.3,2.1-3.4,5-3.4,8.5c0,5.7,3.9,10.3,23.6,17.3c15.6,5.5,15,18.1,15,18.6c0,0.7-1.5,15.3-18.2,14.8
			c-8.5-0.3-12.9-3.7-15-6.5c-2.6-3.4-2.9-7.2-2.3-9.3c1.5-5.5,10.3-6.2,10.4-6.2c0.8,0,1.5-0.3,2.1-1c0.5-0.7,0.8-1.3,0.8-2.1
			c-0.2-1.6-1.5-2.9-3.1-2.8c-0.5,0-13.4,0.8-15.8,10.6c-1.1,4.6,0.2,9.9,3.3,14.2c2.9,3.9,8.6,8.6,19.5,9
			c11.7,0.3,17.7-5.2,20.7-9.9c3.3-5,3.6-10.1,3.6-10.4c0-0.5,0.3-4.7-2-9.9C999.8,150.2,995.2,143.7,984.8,140.1z"/><path class="st0" d="M951.6,152L951.6,152l-13,0.3l-0.5-14.7c0-1.7-1.5-3-3.2-3l0,0c-0.8,0-1.7,0.3-2.2,1
			c-0.5,0.7-0.8,1.5-0.8,2.3l0.5,14.7l-12.8,0.3c-0.8,0-1.7,0.5-2.3,1.5c-0.5,0.8-0.8,1.8-0.8,2.7c0,1.1,0.8,2.8,2.3,3.4
			c0.2,0,0.5,0.1,0.7,0.1l13-1.2l0.5,13c0,1.7,1.3,2.8,3,2.8c0,0,0.3,0,0.5,0c0.2,0,0.3,0,0.3,0c1.5-0.3,2.5-1.8,2.3-3.3l-0.5-13
			l12.8-0.3c1.7,0,3.2-1.5,3-3.2c0-0.8-0.3-1.7-1-2.2C953.1,152.4,952.5,152,951.6,152z"/><path class="st0" d="M1014.6,143.7c-1.6,0-2.9,1.5-2.9,3.1l1.1,30.5c0,1.6,1.5,2.9,3.1,2.9c0,0,0,0,0.2,0c1.6,0,2.9-1.5,2.9-3.1
			l-1.1-30.5C1017.7,145,1016.4,143.7,1014.6,143.7z"/><path class="st0" d="M1184.1,165.6c-1.3-1.1-3.3-1-4.2,0.3c0,0-6.4,7.2-15.8,8.5c-3.7,0.5-6.8-0.3-9.1-2.4
			c-0.5-0.5-1.1-1.1-1.5-1.8c5.4-3.7,19.4-13.7,23.3-18.1c2.6-2.9,2-5.9,0.7-7.7c-3.4-4.4-14.5-5.4-21.2-2
			c-7.8,4.1-10.9,8.6-11.4,16.9c-0.3,5.2,1.3,12.7,6,17.1c2.9,2.8,6.8,4.2,11.2,4.2c1,0,2,0,2.9-0.2c11.7-1.5,19.2-10.1,19.5-10.6
			C1185.6,168.7,1185.4,166.8,1184.1,165.6z M1150.8,159.8c0.5-6,2.3-8.8,8.1-11.9c2-1,4.4-1.5,6.5-1.5c2.9,0,5.7,0.7,6.7,1.5l0,0
			c-3.1,3.4-14.3,11.6-21,16.4C1150.8,162.9,1150.8,161.2,1150.8,159.8z"/><path class="st0" d="M1110.4,145.9c-7.5-0.3-15.8,5.9-21,10.7l-0.2-7.2c0-1.6-1.5-3.1-3.1-2.9c-1.6,0-3.1,1.5-2.9,3.1l2,81.9
			c0,1.6,1.5,2.9,3.1,2.9l0,0c1.6,0,3.1-1.5,2.9-3.1l-1-46.4c9.1-1.8,25.7-9.9,30.6-20.5c2.1-4.6,1.8-9.1-1-13.2
			C1117.4,148,1113.8,146.3,1110.4,145.9z M1115.3,162.1c-3.4,7.5-16.8,14.8-25.2,16.8l-0.3-13.7c3.7-4.1,13.4-12.2,20-13
			c2.4-0.3,3.9,1,5.2,2.8C1116.2,157,1116.4,159.4,1115.3,162.1z"/><path class="st0" d="M1143.6,91.5c-1.5-1.6-3.4-2.4-6-2.4c-0.3,0-0.5,0-0.7,0c-1.3,0-2.4,0.7-3.4,2.1c-2.8,3.8-6,16-7,32.3
			c-0.8,13.9-0.5,35.1,6.7,58.1c0.3,1.1,1.5,2,2.8,2l0,0l0,0h0.2c0.2,0,0.3,0,0.7-0.2c0.7-0.2,1.3-0.7,1.6-1.5
			c0.3-0.7,0.5-1.5,0.2-2.1c-2.9-9.5-5.1-19.4-6-29.7c2.4-3.9,8.2-14.4,11.4-30.7C1146.9,103.5,1146.7,95.2,1143.6,91.5z
			 M1138.4,118.2c-1.8,9.1-4.4,16.1-6.4,20.7c-0.2-5.5,0-11.1,0.3-16.4c1.1-15.6,4.2-25.2,5.7-27.7c0.7,0,1,0.2,1.3,0.5
			C1140.2,96.6,1141.5,101.3,1138.4,118.2z"/><path class="st0" d="M1018.2,132.6c0-2.3-1.8-4.1-4.1-4.1c-2.3,0-4.1,1.8-4.1,4.1c0,2.3,1.8,4.1,4.1,4.1
			C1016.4,136.6,1018.2,134.9,1018.2,132.6z"/><path class="st0" d="M1050.6,118.1c0.3,0.8,1.1,1.3,2.1,1.3l0,0c1,0,1.8-0.5,2.1-1.5l8.8-21.2c0.5-1.1,0-2.4-1.3-2.9
			c-1.1-0.5-2.4,0-2.9,1.3l-6.7,16.1l-7.2-16.6c-0.5-1.1-1.8-1.6-2.9-1.1c-1.1,0.5-1.6,1.8-1.1,2.9L1050.6,118.1z"/><path class="st0" d="M1070.2,118.9L1070.2,118.9c1.3,0,2.3-1,3.4-2.3V97.4c-0.8-1.3-1.8-2.3-3.1-2.3c-1.1,0-2.3,1-1.8,2.3v19.2
			C1067.9,117.9,1068.8,118.9,1070.2,118.9z"/><path class="st0" d="M1072.1,89.8c0.8-0.8,0.8-2.3,0-3.1c-0.8-0.8-2.3-0.8-3.1,0c-0.8,0.8-0.8,2.3,0,3.1
			C1069.8,90.6,1071.1,90.6,1072.1,89.8z"/><path class="st0" d="M1093.2,118.5c0.2,1.2,1,2.1,2.3,2.1c1.4,0,2.4-1,2.4-2.4v-15.7c0-2.6-0.9-4.9-2.4-6.6
			c-2.8-2.8-6.5-2.8-7-2.8c-5.4,0-8.7,2.4-9.1,2.8c-1,0.9-1.2,2.3-0.3,3.5c0.9,1,2.4,1.2,3.5,0.3c0,0,2.3-1.7,5.9-1.7
			c0,0,2.3,0,3.5,1.4c0.7,0.7,1,1.7,1,3.1v0.7c-2.8,0-7.5,0.5-11.5,2.3c-1.9,0.9-4.2,3.5-4.7,6.5c-0.3,2.6,0.7,5.2,3,7.2
			c1.2,1,3,1.6,4.7,1.6C1087.7,120.6,1091,119.4,1093.2,118.5z M1082.5,112.2c0.2-1.3,1-2.1,1.8-2.6c2.1-1,6.2-1.8,8.8-1.8v4.7
			c-2.4,1.5-8.1,2.8-9.3,2.1C1082.8,114.5,1082.3,113.2,1082.5,112.2z"/></g><g><g><rect x="14.7" y="242.5" class="st1" width="402.9" height="49.2"/></g><g><polygon class="st2" points="284.8,50.3 447.6,50.3 417.6,242.5 279.2,244.1 			"/></g><g><polygon class="st3" points="60.9,64.2 14.7,242.5 287.9,242.5 287.9,28.8 			"/></g><g><g><path class="st4" d="M341.1,60.9c-21.5,0.6-42.7,1.7-44.2,2.5c-9.2,1.5-64.6,44.6-66.1,46.1c-84.6-72.3-127.8-26.2-129.2-23.1
					c-1.3,3.2,47.7-38.4,123,32.3c1.5-1.5,19.3-21.5,44.6-30.8c25.6-9.4,72.3-9.2,81.5-10.8c0,0,23.6-2.6,36.7-15.1
					c0,0,0.2-0.2,0.2-0.2C373.2,61,357.7,60.5,341.1,60.9z"/></g><g><path class="st4" d="M232.3,124.9c-26.1,9.2-61.8,88.7-56.9,92.3C184.7,214.1,209.3,143.4,232.3,124.9z"/></g><g><path class="st4" d="M393.8,117.2c-18.5,27.7-49.2,83-70.7,106.1c-25.5-10.6-54.1-15.6-75.5-16.5c-0.8,0-0.8-1.2,0-1.3
					c34.5-2.7,67.5,3.4,72.5,5.5c0,0,3.9,1,4.6,0c7.7-4.6,55.4-80,56.9-96.9c-6.1,10.7-41.1,67-58.1,88.8
					c-34.6-10.6-53-18-89.5-13.5c-3.8,0.4-11.9,1.6-21.1,4.7c22.4-9.4,54.3-12.7,109.4-13.8c4.5-0.1,8.2-3.6,8.6-8.1
					c3.5-45.5,71-82.5,57-110.5c-6.2,18.5-16.9,27.7-32.3,46.1c-41.5,43.1-26.1,50.7-36.9,61.5c-9.2,9.2-39.7-1.4-81,7.6
					c-0.4,0.1-0.7,0.2-1.1,0.3c-41.1,12.4-58.1,41.9-61,39.8c-27.7-26.1-46.1-56.9-113.8-41.5c-3.1-9.2,43.1-78.4,43.1-78.4
					s-43.1,43.1-55.4,86.1c8.8,5.9,45.8-26.2,116.3,31.5c-60.8-48.3-116-18.8-117.4-15.9c-1.5,3.1,49.6-35.9,121.2,38.7
					c0.1-0.1,0.6-0.6,1.3-1.3c3.3-3.3,17.7-17.1,43.6-24.8c1.2-0.4,2.4-0.7,3.6-1c20-2.8,95.3,26.5,112.9,20.6
					C355.3,203.3,396.9,120.3,393.8,117.2z"/></g></g><g><path class="st5" d="M471.9,91.6h10.3l12,38.1H485l-5-19.3c-1.1-3.8-2.1-8.3-3.1-12.3h-0.2c-0.9,4-1.9,8.5-3,12.3l-5,19.3h-8.8
				L471.9,91.6z M467.8,113.9h18.3v6.7h-18.3V113.9z"/><path class="st5" d="M496.2,126.3l3.9-5.3c2.6,2,5.1,3,7.5,3c2.6,0,3.7-1,3.7-2.5c0-1.9-2.9-2.8-5.9-3.9
				c-3.6-1.4-7.7-3.7-7.7-8.5c0-5.4,4.4-9.1,11.1-9.1c4.4,0,7.7,1.8,10.2,3.7l-3.8,5.2c-2.1-1.5-4.1-2.5-6.1-2.5
				c-2.2,0-3.3,0.9-3.3,2.3c0,1.8,2.7,2.6,5.7,3.6c3.7,1.4,7.8,3.4,7.8,8.7c0,5.2-4.1,9.3-11.9,9.3
				C503.5,130.4,499.1,128.7,496.2,126.3z"/><path class="st5" d="M522.5,126.3l3.9-5.3c2.6,2,5.1,3,7.5,3c2.6,0,3.7-1,3.7-2.5c0-1.9-2.9-2.8-5.9-3.9
				c-3.6-1.4-7.7-3.7-7.7-8.5c0-5.4,4.4-9.1,11.1-9.1c4.4,0,7.7,1.8,10.2,3.7l-3.8,5.2c-2.1-1.5-4.1-2.5-6.1-2.5
				c-2.2,0-3.3,0.9-3.3,2.3c0,1.8,2.7,2.6,5.7,3.6c3.7,1.4,7.8,3.4,7.8,8.7c0,5.2-4.1,9.3-11.9,9.3
				C529.8,130.4,525.4,128.7,522.5,126.3z"/><path class="st5" d="M549.7,115.2c0-9.6,6.8-15.2,14.1-15.2s14.1,5.6,14.1,15.2c0,9.6-6.8,15.2-14.1,15.2
				S549.7,124.8,549.7,115.2z M569.1,115.2c0-5-1.8-8.3-5.3-8.3c-3.6,0-5.3,3.3-5.3,8.3c0,5,1.8,8.3,5.3,8.3
				C567.3,123.4,569.1,120.2,569.1,115.2z"/><path class="st5" d="M582.5,115.2c0-9.6,7.1-15.2,15.1-15.2c3.6,0,6.3,1.3,8.5,3.1l-4.1,5.5c-1.4-1.2-2.5-1.7-3.9-1.7
				c-4.1,0-6.8,3.3-6.8,8.3c0,5,2.8,8.3,6.5,8.3c1.9,0,3.7-0.9,5.2-2.1l3.4,5.6c-2.8,2.5-6.4,3.5-9.6,3.5
				C588.8,130.4,582.5,124.8,582.5,115.2z"/><path class="st5" d="M611.2,92c0-2.6,2.1-4.5,5-4.5c2.9,0,5,1.9,5,4.5c0,2.6-2.1,4.5-5,4.5C613.3,96.4,611.2,94.6,611.2,92z
				 M611.9,100.7h8.6v29h-8.6V100.7z"/><path class="st5" d="M627.1,121.6c0-6.1,4.9-9.4,16.3-10.6c-0.2-2.5-1.4-4.2-4.6-4.2c-2.4,0-4.9,1-7.7,2.6l-3.1-5.6
				c3.7-2.2,7.8-3.8,12.4-3.8c7.4,0,11.5,4.2,11.5,13.1v16.6h-7l-0.6-3h-0.2c-2.4,2.1-5.2,3.7-8.4,3.7
				C630.5,130.4,627.1,126.5,627.1,121.6z M643.4,121.3v-5.1c-6.1,0.8-8.1,2.6-8.1,4.8c0,1.9,1.3,2.8,3.3,2.8
				C640.6,123.7,641.9,122.8,643.4,121.3z"/><path class="st5" d="M658,125.1l12.2-17.7h-10.8v-6.7h21.5v4.6l-12.2,17.7h12.6v6.7H658V125.1z"/><path class="st5" d="M686.3,92c0-2.6,2.1-4.5,5-4.5c2.9,0,5,1.9,5,4.5c0,2.6-2.1,4.5-5,4.5C688.3,96.4,686.3,94.6,686.3,92z
				 M686.9,100.7h8.6v29h-8.6V100.7z"/><path class="st5" d="M701.8,115.2c0-9.6,6.8-15.2,14.1-15.2c7.3,0,14.1,5.6,14.1,15.2c0,9.6-6.8,15.2-14.1,15.2
				C708.6,130.4,701.8,124.8,701.8,115.2z M721.2,115.2c0-5-1.8-8.3-5.3-8.3c-3.6,0-5.3,3.3-5.3,8.3c0,5,1.8,8.3,5.3,8.3
				C719.4,123.4,721.2,120.2,721.2,115.2z"/><path class="st5" d="M736.3,100.7h7l0.6,3.7h0.2c2.4-2.3,5.4-4.4,9.3-4.4c6.3,0,9,4.4,9,11.7v18h-8.6v-16.9
				c0-4.2-1.1-5.5-3.6-5.5c-2.2,0-3.5,1-5.4,2.8v19.6h-8.6V100.7z"/><path class="st5" d="M768.4,115.2c0-9.4,6.7-15.2,13.7-15.2c8.3,0,12.3,6,12.3,13.9c0,1.5-0.2,3.1-0.4,3.8h-17.3
				c0.8,4.2,3.7,6.1,7.5,6.1c2.2,0,4.2-0.7,6.3-1.9l2.8,5.2c-3,2.1-6.8,3.3-10.3,3.3C774.8,130.4,768.4,124.8,768.4,115.2z
				 M787.1,112c0-3.2-1.4-5.5-4.8-5.5c-2.7,0-5.1,1.8-5.6,5.5H787.1z"/><path class="st5" d="M464.8,151.6h8.8l9.9,18.9l3.8,8.4h0.2c-0.4-4-1.1-9.4-1.1-13.9v-13.5h8.2v38.1h-8.8l-9.9-19l-3.8-8.3h-0.2
				c0.4,4.2,1.1,9.3,1.1,13.8v13.5h-8.2V151.6z"/><path class="st5" d="M502,181.6c0-6.1,4.9-9.4,16.3-10.6c-0.2-2.5-1.4-4.2-4.6-4.2c-2.4,0-4.9,1-7.7,2.6l-3.1-5.6
				c3.7-2.2,7.8-3.8,12.4-3.8c7.4,0,11.5,4.2,11.5,13.1v16.6h-7l-0.6-3h-0.2c-2.4,2.1-5.2,3.7-8.4,3.7
				C505.4,190.3,502,186.5,502,181.6z M518.3,181.2v-5.1c-6.1,0.8-8.1,2.6-8.1,4.8c0,1.9,1.3,2.8,3.3,2.8
				C515.5,183.7,516.8,182.7,518.3,181.2z"/><path class="st5" d="M532.9,185.1l12.2-17.7h-10.8v-6.7h21.5v4.6l-12.2,17.7h12.6v6.7h-23.3V185.1z"/><path class="st5" d="M561.2,151.9c0-2.6,2.1-4.5,5-4.5c2.9,0,5,1.9,5,4.5c0,2.6-2.1,4.5-5,4.5
				C563.2,156.4,561.2,154.5,561.2,151.9z M561.8,160.7h8.6v29h-8.6V160.7z"/><path class="st5" d="M576.7,175.2c0-9.6,6.8-15.2,14.1-15.2c7.3,0,14.1,5.6,14.1,15.2c0,9.6-6.8,15.2-14.1,15.2
				C583.5,190.3,576.7,184.8,576.7,175.2z M596.1,175.2c0-5-1.8-8.3-5.3-8.3c-3.6,0-5.3,3.3-5.3,8.3c0,5,1.8,8.3,5.3,8.3
				C594.3,183.4,596.1,180.2,596.1,175.2z"/><path class="st5" d="M611.2,160.7h7l0.6,3.7h0.2c2.4-2.3,5.4-4.4,9.3-4.4c6.3,0,9,4.4,9,11.7v18h-8.6v-16.9
				c0-4.2-1.1-5.5-3.6-5.5c-2.2,0-3.5,1-5.4,2.8v19.6h-8.6V160.7z"/><path class="st5" d="M643.7,181.6c0-6.1,4.9-9.4,16.3-10.6c-0.2-2.5-1.4-4.2-4.6-4.2c-2.4,0-4.9,1-7.7,2.6l-3.1-5.6
				c3.7-2.2,7.8-3.8,12.4-3.8c7.4,0,11.5,4.2,11.5,13.1v16.6h-7l-0.6-3h-0.2c-2.4,2.1-5.2,3.7-8.4,3.7
				C647,190.3,643.7,186.5,643.7,181.6z M660,181.2v-5.1c-6.1,0.8-8.1,2.6-8.1,4.8c0,1.9,1.3,2.8,3.3,2.8
				C657.2,183.7,658.5,182.7,660,181.2z"/><path class="st5" d="M676.2,180.6v-31.9h8.6V181c0,1.8,0.9,2.4,1.6,2.4c0.3,0,0.6,0,1.1-0.1l1,6.3c-0.9,0.4-2.4,0.7-4.3,0.7
				C678.2,190.3,676.2,186.5,676.2,180.6z"/><path class="st5" d="M691.7,175.2c0-9.4,6.7-15.2,13.7-15.2c8.3,0,12.3,6,12.3,13.9c0,1.5-0.2,3.1-0.4,3.8H700
				c0.8,4.2,3.7,6.1,7.5,6.1c2.2,0,4.2-0.7,6.3-1.9l2.8,5.2c-3,2.1-6.8,3.3-10.3,3.3C698.1,190.3,691.7,184.8,691.7,175.2z
				 M710.3,172c0-3.2-1.4-5.5-4.8-5.5c-2.7,0-5.1,1.8-5.6,5.5H710.3z"/><path class="st5" d="M463,230.8c0-12.6,8.4-20,18-20c4.9,0,8.9,2.3,11.5,4.9l-4.5,5.5c-1.9-1.8-4-3-6.7-3
				c-5.2,0-9.3,4.6-9.3,12.2c0,7.8,3.6,12.4,9.2,12.4c3.1,0,5.6-1.5,7.4-3.6l4.5,5.4c-3.2,3.7-7.4,5.6-12.3,5.6
				C471,250.3,463,243.6,463,230.8z"/><path class="st5" d="M496.8,235.1c0-9.6,6.8-15.2,14.1-15.2c7.3,0,14.1,5.6,14.1,15.2c0,9.6-6.8,15.2-14.1,15.2
				C503.6,250.3,496.8,244.7,496.8,235.1z M516.2,235.1c0-5-1.8-8.3-5.3-8.3c-3.6,0-5.3,3.3-5.3,8.3c0,5,1.8,8.3,5.3,8.3
				C514.5,243.4,516.2,240.1,516.2,235.1z"/><path class="st5" d="M531.3,220.6h7l0.6,3.7h0.2c2.4-2.4,5-4.4,8.8-4.4c4.1,0,6.5,1.8,8,4.9c2.5-2.7,5.3-4.9,9.1-4.9
				c6.3,0,9.1,4.4,9.1,11.7v18h-8.6v-16.9c0-4.2-1.1-5.5-3.6-5.5c-1.5,0-3.1,0.9-4.9,2.8v19.6h-8.6v-16.9c0-4.2-1.1-5.5-3.6-5.5
				c-1.4,0-3.1,0.9-4.9,2.8v19.6h-8.6V220.6z"/><path class="st5" d="M581.8,220.6h7l0.6,3.7h0.2c2.4-2.4,5-4.4,8.8-4.4c4.1,0,6.5,1.8,8,4.9c2.5-2.7,5.3-4.9,9.1-4.9
				c6.3,0,9.1,4.4,9.1,11.7v18H616v-16.9c0-4.2-1.1-5.5-3.6-5.5c-1.5,0-3.1,0.9-4.9,2.8v19.6h-8.6v-16.9c0-4.2-1.1-5.5-3.6-5.5
				c-1.4,0-3.1,0.9-4.9,2.8v19.6h-8.6V220.6z"/><path class="st5" d="M630.6,235.1c0-9.4,6.7-15.2,13.7-15.2c8.3,0,12.3,6,12.3,13.9c0,1.5-0.2,3.1-0.4,3.8h-17.3
				c0.8,4.2,3.7,6.1,7.5,6.1c2.2,0,4.2-0.7,6.3-1.9l2.8,5.2c-3,2.1-6.8,3.3-10.3,3.3C637,250.3,630.6,244.7,630.6,235.1z M649.3,232
				c0-3.2-1.4-5.5-4.8-5.5c-2.7,0-5.1,1.8-5.6,5.5H649.3z"/><path class="st5" d="M663,220.6h7l0.6,5.1h0.2c2.1-3.9,5.3-5.8,8.2-5.8c1.7,0,2.6,0.2,3.5,0.6l-1.4,7.4c-1-0.3-1.9-0.5-3.1-0.5
				c-2.2,0-4.8,1.4-6.4,5.4v16.8H663V220.6z"/><path class="st5" d="M684.4,235.1c0-9.6,7.1-15.2,15.1-15.2c3.6,0,6.3,1.3,8.5,3.1l-4.1,5.5c-1.4-1.2-2.5-1.7-3.9-1.7
				c-4.1,0-6.8,3.3-6.8,8.3c0,5,2.8,8.3,6.5,8.3c1.9,0,3.7-0.9,5.2-2.1l3.4,5.6c-2.8,2.5-6.4,3.5-9.6,3.5
				C690.6,250.3,684.4,244.7,684.4,235.1z"/><path class="st5" d="M713.1,211.9c0-2.6,2.1-4.5,5-4.5c2.9,0,5,1.9,5,4.5c0,2.6-2.1,4.5-5,4.5
				C715.2,216.4,713.1,214.5,713.1,211.9z M713.7,220.6h8.6v29h-8.6V220.6z"/><path class="st5" d="M728.9,241.6c0-6.1,4.9-9.4,16.3-10.6c-0.2-2.5-1.4-4.2-4.6-4.2c-2.4,0-4.9,1-7.7,2.6l-3.1-5.6
				c3.7-2.2,7.8-3.8,12.4-3.8c7.4,0,11.5,4.2,11.5,13.1v16.6h-7l-0.6-3H746c-2.4,2.1-5.2,3.7-8.4,3.7
				C732.3,250.3,728.9,246.5,728.9,241.6z M745.3,241.2v-5.1c-6.1,0.8-8.1,2.6-8.1,4.8c0,1.9,1.3,2.8,3.3,2.8
				C742.4,243.7,743.7,242.7,745.3,241.2z"/><path class="st5" d="M761.5,240.6v-31.9h8.6V241c0,1.8,0.9,2.4,1.6,2.4c0.3,0,0.6,0,1.1-0.1l1,6.3c-0.9,0.4-2.4,0.7-4.3,0.7
				C763.5,250.3,761.5,246.5,761.5,240.6z"/><path class="st5" d="M777.9,211.9c0-2.6,2.1-4.5,5-4.5c2.9,0,5,1.9,5,4.5c0,2.6-2.1,4.5-5,4.5C780,216.4,777.9,214.5,777.9,211.9
				z M778.6,220.6h8.6v29h-8.6V220.6z"/><path class="st5" d="M792.6,246.3l3.9-5.3c2.6,2,5.1,3,7.5,3c2.6,0,3.7-1,3.7-2.5c0-1.9-2.9-2.8-5.9-3.9
				c-3.6-1.4-7.7-3.7-7.7-8.5c0-5.4,4.4-9.1,11.1-9.1c4.4,0,7.7,1.8,10.2,3.7l-3.8,5.2c-2.1-1.5-4.1-2.5-6.1-2.5
				c-2.2,0-3.3,0.9-3.3,2.3c0,1.8,2.7,2.6,5.7,3.6c3.7,1.4,7.8,3.4,7.8,8.7c0,5.2-4.1,9.3-11.9,9.3
				C799.9,250.3,795.5,248.7,792.6,246.3z"/><path class="st5" d="M821.4,239.1v-11.7h-4V221l4.4-0.3l1-7.7h7.1v7.7h6.9v6.7H830V239c0,3.3,1.5,4.6,3.8,4.6c1,0,2-0.3,2.8-0.5
				l1.4,6.2c-1.5,0.5-3.6,1.1-6.4,1.1C824.3,250.3,821.4,245.8,821.4,239.1z"/><path class="st5" d="M842.4,211.9c0-2.6,2.1-4.5,5-4.5c2.9,0,5,1.9,5,4.5c0,2.6-2.1,4.5-5,4.5
				C844.5,216.4,842.4,214.5,842.4,211.9z M843.1,220.6h8.6v29h-8.6V220.6z"/></g><g><g><path class="st4" d="M24.4,248.1l-5.2,37.3h2l1.5-11.4h5.6l1.5,11.4h2.1l-5.2-37.3H24.4z M27.8,270.2h-4.7l0.7-5.7
					c0.6-4.2,1.1-8.3,1.5-12.6h0.1c0.5,4.3,1,8.4,1.6,12.6L27.8,270.2z"/></g><g><path class="st4" d="M37,263.2c-1.2-1.2-2.6-2.6-2.6-6.2c0-3.3,1.2-5.5,2.9-5.5c1.4,0,2.6,1.3,3.5,3.5l1-3.1
					c-1.1-2.7-2.7-4.6-4.6-4.6c-2.8,0-4.9,4.2-4.9,9.9c0,5.5,1.7,8.1,3.1,9.6l2.2,2.4c1.5,1.5,2.6,2.8,2.6,6.6
					c0,3.6-1.2,6.1-3.2,6.1c-1.6,0-3.2-1.8-4.3-4.6l-1.2,3.3c1.4,3.4,3.2,5.5,5.4,5.5c3.3,0,5.3-4.7,5.3-10.6c0-5.6-1.4-8.1-3.2-10
					L37,263.2z"/></g><g><path class="st4" d="M48,263.2c-1.2-1.2-2.6-2.6-2.6-6.2c0-3.3,1.2-5.5,2.9-5.5c1.4,0,2.6,1.3,3.5,3.5l1-3.1
					c-1.1-2.7-2.7-4.6-4.6-4.6c-2.8,0-4.9,4.2-4.9,9.9c0,5.5,1.7,8.1,3.1,9.6l2.2,2.4c1.5,1.5,2.6,2.8,2.6,6.6
					c0,3.6-1.2,6.1-3.2,6.1c-1.6,0-3.2-1.8-4.3-4.6l-1.2,3.3c1.4,3.4,3.2,5.5,5.4,5.5c3.3,0,5.3-4.7,5.3-10.6c0-5.6-1.4-8.1-3.2-10
					L48,263.2z"/></g><g><path class="st4" d="M60.9,247.4c-3.9,0-6.6,7.3-6.6,19.2c0,12,2.7,19.5,6.6,19.5s6.6-7.5,6.6-19.5
					C67.5,254.7,64.8,247.4,60.9,247.4z M60.9,281.9c-2.8,0-4.6-6-4.6-15.3c0-9.3,1.8-15,4.6-15s4.6,5.7,4.6,15
					C65.5,275.9,63.6,281.9,60.9,281.9z"/></g><g><path class="st4" d="M75.5,281.9c-2.9,0-4.8-5.9-4.8-15.3c0-9.3,1.9-15.1,4.9-15.1c1.3,0,2.3,1.4,3.1,3.5l1.1-3.1
					c-0.9-2.4-2.4-4.6-4.3-4.6c-3.9,0-6.9,7.3-6.9,19.4c0,12.1,2.9,19.3,6.7,19.3c2,0,3.5-2,4.8-5.4l-1.1-3
					C78.1,280.4,77,281.9,75.5,281.9z"/></g><g><rect x="81.8" y="248.1" class="st4" width="2" height="37.3"/></g><g><path class="st4" d="M107.6,250.9v-2.8h-9.9v4h7.4L97,282.6v0.4l-4.9-35h-2.2l-5.2,37.3h2l1.5-11.4h5.6l1.5,11.4H97h0.3h10.3v-4
					h-8.2L107.6,250.9z M88.6,270.2l0.7-5.7c0.6-4.2,1.1-8.3,1.5-12.6H91c0.5,4.3,1,8.4,1.5,12.6l0.7,5.7H88.6z"/></g><g><rect x="109.4" y="248.1" class="st4" width="2" height="37.3"/></g><g><path class="st4" d="M120.1,247.4c-3.9,0-6.6,7.3-6.6,19.2c0,12,2.7,19.5,6.6,19.5c3.9,0,6.6-7.5,6.6-19.5
					C126.7,254.7,124,247.4,120.1,247.4z M120.1,281.9c-2.8,0-4.6-6-4.6-15.3c0-9.3,1.8-15,4.6-15c2.8,0,4.6,5.7,4.6,15
					C124.7,275.9,122.8,281.9,120.1,281.9z"/></g><g><path class="st4" d="M137.9,267.4c0,3.9,0.2,8.1,0.3,11.9h-0.1l-1.7-7.7l-5.6-23.5h-2v37.3h1.9v-19.5c0-3.9-0.2-7.9-0.3-11.7
					h0.1l1.7,7.7l5.6,23.5h2v-37.3h-1.9V267.4z"/></g><g><polygon class="st4" points="144.7,267.8 150.6,267.8 150.6,263.8 144.7,263.8 144.7,252.1 151.7,252.1 151.7,248.1 
					142.8,248.1 142.8,285.4 151.9,285.4 151.9,281.4 144.7,281.4 				"/></g><g><path class="st4" d="M166.5,267.4c0,3.9,0.2,8.1,0.3,11.9h-0.1l-1.7-7.7l-5.6-23.5h-2v37.3h1.9v-19.5c0-3.9-0.2-7.9-0.3-11.7
					h0.1l1.7,7.7l5.6,23.5h2v-37.3h-1.9V267.4z"/></g><g><path class="st4" d="M192.3,250.9v-2.8h-9.9v4h7.4l-8.2,30.5v0.4l-4.9-35h-2.2l-5.2,37.3h2l1.5-11.4h5.6l1.5,11.4h1.8h0.3h10.3
					v-4h-8.2L192.3,250.9z M173.3,270.2l0.7-5.7c0.6-4.2,1.1-8.3,1.5-12.6h0.1c0.5,4.3,1,8.4,1.6,12.6l0.7,5.7H173.3z"/></g><g><rect x="194.1" y="248.1" class="st4" width="2" height="37.3"/></g><g><path class="st4" d="M204.8,247.4c-3.9,0-6.6,7.3-6.6,19.2c0,12,2.7,19.5,6.6,19.5s6.6-7.5,6.6-19.5
					C211.4,254.7,208.7,247.4,204.8,247.4z M204.8,281.9c-2.8,0-4.6-6-4.6-15.3c0-9.3,1.8-15,4.6-15s4.6,5.7,4.6,15
					C209.4,275.9,207.5,281.9,204.8,281.9z"/></g><g><path class="st4" d="M222.6,267.4c0,3.9,0.2,8.1,0.3,11.9h-0.1l-1.7-7.7l-5.6-23.5h-2v37.3h1.9v-19.5c0-3.9-0.2-7.9-0.3-11.7
					h0.1l1.7,7.7l5.6,23.5h2v-37.3h-1.9V267.4z"/></g><g><path class="st4" d="M230.6,248.1l-5.2,37.3h2l1.5-11.4h5.6l1.5,11.4h2.1l-5.2-37.3H230.6z M234,270.2h-4.7l0.7-5.7
					c0.6-4.2,1.1-8.3,1.5-12.6h0.1c0.5,4.3,1,8.4,1.6,12.6L234,270.2z"/></g><g><polygon class="st4" points="240.9,248.1 238.9,248.1 238.9,285.4 247.7,285.4 247.7,281.4 240.9,281.4 				"/></g><g><polygon class="st4" points="251.1,267.8 257,267.8 257,263.8 251.1,263.8 251.1,252.1 258,252.1 258,248.1 249.1,248.1 
					249.1,285.4 258.3,285.4 258.3,281.4 251.1,281.4 				"/></g><g><path class="st4" d="M269.7,281.9c-2.9,0-4.8-5.9-4.8-15.3c0-9.3,1.9-15.1,4.9-15.1c1.3,0,2.3,1.4,3.1,3.5l1.1-3.1
					c-0.9-2.4-2.4-4.6-4.3-4.6c-3.9,0-6.9,7.3-6.9,19.4c0,12.1,2.9,19.3,6.7,19.3c2,0,3.5-2,4.8-5.4l-1.1-3
					C272.3,280.4,271.1,281.9,269.7,281.9z"/></g><g><path class="st4" d="M281,247.4c-3.9,0-6.6,7.3-6.6,19.2c0,12,2.7,19.5,6.6,19.5s6.6-7.5,6.6-19.5
					C287.6,254.7,284.9,247.4,281,247.4z M281,281.9c-2.8,0-4.6-6-4.6-15.3c0-9.3,1.8-15,4.6-15c2.8,0,4.6,5.7,4.6,15
					C285.6,275.9,283.7,281.9,281,281.9z"/></g><g><path class="st4" d="M297.3,268c-0.4,2.6-0.7,5.2-1.1,7.8h-0.1c-0.4-2.6-0.7-5.2-1.1-7.8l-3-20h-2.4v37.3h1.8v-20.6
					c0-3.3-0.2-7.7-0.3-11h0.1l1.2,8.5l2.9,19.3h1.3l2.9-19.3l1.2-8.5h0.1c-0.1,3.3-0.3,7.8-0.3,11v20.6h1.8v-37.3h-2.4L297.3,268z"
					/></g><g><path class="st4" d="M313.2,268c-0.4,2.6-0.7,5.2-1.1,7.8H312c-0.4-2.6-0.7-5.2-1.1-7.8l-3-20h-2.4v37.3h1.8v-20.6
					c0-3.3-0.2-7.7-0.3-11h0.1l1.2,8.5l2.9,19.3h1.3l2.9-19.3l1.2-8.5h0.1c-0.1,3.3-0.3,7.8-0.3,11v20.6h1.8v-37.3h-2.4L313.2,268z"
					/></g><g><polygon class="st4" points="323.4,267.8 329.3,267.8 329.3,263.8 323.4,263.8 323.4,252.1 330.4,252.1 330.4,248.1 
					321.4,248.1 321.4,285.4 330.6,285.4 330.6,281.4 323.4,281.4 				"/></g><g><path class="st4" d="M342.9,258.6c0-7.8-2.3-10.5-5.4-10.5h-4.9v37.3h2v-15.8h2.8l3.7,15.8h2.2l-3.9-16.3
					C341.5,267.8,342.9,264.4,342.9,258.6z M334.6,251.9h2.6c2.4,0,3.7,1.7,3.7,6.7c0,4.8-1.3,7.2-3.7,7.2h-2.6V251.9z"/></g><g><path class="st4" d="M350.7,281.9c-2.9,0-4.8-5.9-4.8-15.3c0-9.3,1.9-15.1,4.9-15.1c1.3,0,2.3,1.4,3.1,3.5l1.1-3.1
					c-0.9-2.4-2.4-4.6-4.3-4.6c-3.9,0-6.9,7.3-6.9,19.4c0,12.1,2.9,19.3,6.7,19.3c2,0,3.5-2,4.8-5.4l-1.1-3
					C353.3,280.4,352.2,281.9,350.7,281.9z"/></g><g><rect x="357" y="248.1" class="st4" width="2" height="37.3"/></g><g><path class="st4" d="M365.1,248.1l-5.2,37.3h2l1.5-11.4h5.6l1.5,11.4h2.1l-5.2-37.3H365.1z M368.5,270.2h-4.7l0.7-5.7
					c0.6-4.2,1.1-8.3,1.5-12.6h0.1c0.5,4.3,1,8.4,1.6,12.6L368.5,270.2z"/></g><g><polygon class="st4" points="375.3,248.1 373.4,248.1 373.4,285.4 382.1,285.4 382.1,281.4 375.3,281.4 				"/></g><g><rect x="383.6" y="248.1" class="st4" width="2" height="37.3"/></g><g><path class="st4" d="M392.7,263.2c-1.2-1.2-2.6-2.6-2.6-6.2c0-3.3,1.2-5.5,2.9-5.5c1.4,0,2.6,1.3,3.5,3.5l1-3.1
					c-1.1-2.7-2.7-4.6-4.6-4.6c-2.8,0-4.9,4.2-4.9,9.9c0,5.5,1.7,8.1,3.1,9.6l2.2,2.4c1.5,1.5,2.6,2.8,2.6,6.6
					c0,3.6-1.2,6.1-3.2,6.1c-1.6,0-3.2-1.8-4.3-4.6l-1.2,3.3c1.4,3.4,3.2,5.5,5.4,5.5c3.3,0,5.3-4.7,5.3-10.6c0-5.6-1.4-8.1-3.2-10
					L392.7,263.2z"/></g><g><polygon class="st4" points="397.9,252.1 402.6,252.1 402.6,285.4 404.6,285.4 404.6,252.1 409.2,252.1 409.2,248.1 
					397.9,248.1 				"/></g><g><rect x="410.7" y="248.1" class="st4" width="2" height="37.3"/></g></g><g><path class="st4" d="M360,234.8c0-2.5,1.5-4,3.2-4c0.9,0,1.4,0.3,2,0.8l-0.1-1.2v-2.7h1.8v10.9h-1.5l-0.1-0.8h-0.1
				c-0.6,0.6-1.3,1-2.1,1C361.3,238.7,360,237.3,360,234.8z M365.2,236.4v-3.5c-0.5-0.5-1-0.6-1.5-0.6c-1,0-1.8,0.9-1.8,2.5
				c0,1.6,0.6,2.5,1.7,2.5C364.2,237.3,364.7,237,365.2,236.4z"/><path class="st4" d="M368.7,236.5c0-1.6,1.3-2.5,4.4-2.8c0-0.8-0.4-1.5-1.4-1.5c-0.7,0-1.5,0.3-2.1,0.7l-0.7-1.2
				c0.8-0.5,1.9-1,3.1-1c1.9,0,2.8,1.2,2.8,3.3v4.5h-1.5l-0.1-0.8h0c-0.7,0.6-1.5,1-2.3,1C369.6,238.7,368.7,237.8,368.7,236.5z
				 M373.2,236.5v-1.7c-2,0.3-2.7,0.8-2.7,1.6c0,0.7,0.5,1,1.1,1C372.1,237.4,372.6,237.1,373.2,236.5z"/><path class="st4" d="M377,236.6v-8.9h1.8v9c0,0.4,0.2,0.6,0.4,0.6c0.1,0,0.1,0,0.3,0l0.2,1.3c-0.2,0.1-0.5,0.2-0.9,0.2
				C377.5,238.7,377,237.9,377,236.6z"/><path class="st4" d="M384.2,237.1h2.2v-6.4h-1.8v-1.1c1-0.2,1.6-0.4,2.3-0.8h1.3v8.3h1.9v1.4h-5.9V237.1z"/><path class="st4" d="M391.6,237.6l1-1.1c0.4,0.4,1,0.7,1.6,0.7c1.3,0,2.3-1,2.3-3.9c0-2.4-0.8-3.4-1.9-3.4
				c-0.8,0-1.5,0.7-1.5,1.9c0,1.2,0.6,1.7,1.5,1.7c0.6,0,1.3-0.3,1.8-1.2l0.1,1.2c-0.6,0.8-1.5,1.3-2.2,1.3c-1.7,0-2.9-1-2.9-3
				c0-2,1.4-3.2,3.1-3.2c1.9,0,3.5,1.5,3.5,4.8c0,3.8-1.8,5.4-3.8,5.4C393.1,238.7,392.2,238.2,391.6,237.6z"/><path class="st4" d="M399.1,237.4l0.8-1.1c0.6,0.6,1.3,1,2.3,1c1.1,0,1.9-0.7,1.9-1.9c0-1.2-0.7-1.8-1.8-1.8
				c-0.6,0-1,0.2-1.6,0.5l-0.8-0.5l0.3-4.8h5.2v1.5h-3.7l-0.2,2.4c0.4-0.2,0.8-0.3,1.3-0.3c1.7,0,3.1,1,3.1,3c0,2.1-1.6,3.4-3.4,3.4
				C400.8,238.7,399.8,238.1,399.1,237.4z"/><path class="st4" d="M407.2,233.6c0-3.3,1.3-5,3.3-5c2,0,3.3,1.7,3.3,5c0,3.3-1.3,5.1-3.3,5.1
				C408.5,238.7,407.2,236.9,407.2,233.6z M412.1,233.6c0-2.8-0.7-3.6-1.6-3.6c-0.9,0-1.6,0.9-1.6,3.6s0.7,3.7,1.6,3.7
				C411.5,237.4,412.1,236.4,412.1,233.6z"/></g></g></g></svg>