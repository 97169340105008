<div class="lg-modal-custom first position-absolute" id="addclientModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></button>
            </div>
            <div class="modal-body custom-product-modal">
                <section class="title-bar insurance-bar space-remove">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-7 left-text">
                                <h2>{{ window.dictionary.get('rimuovi_cliente') }}</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <button class="btn btn-primary float-right mt-3" (click)="confirm()">{{ window.dictionary.get('rimuovi') }}</button>
            </div>
        </div>
    </div>
</div>