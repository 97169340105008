import { Component } from '@angular/core';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailComponent {
  window:any=<any>window;
  notification:any = new this.window.HPNotification({parent: 'app-client-detail'});
  $:any=<any>this.window.$;

  queryparams:any = () => decodeURI(this.window.location.href)
    .split('?')[1]?.split('&')
    .map(param => param.split('='))
    .reduce((values, [ key, value ]) => {
      values[ key ] = value
      return values
    }, {}) || {};

  changeQueryParam:any = (values:any = {}) => new Object({ ...this.queryparams(), ...values });

  constructor() { }

  getCliente = () => this.window.usher.get()?.public_configuration?.clients?.find(e => e.id == this.queryparams()['id_cliente']) || {
    name: "",
    surname: "",
    email: "",
    phone: ""
  };

  getAssicurazioni = () => this.window.usher.get()?.manager_configuration?.assicurazioni?.filter(e => e.cliente?.id == this.queryparams()['id_cliente']);

  getPreventivi = () => this.window.usher.get()?.manager_configuration?.preventivi?.filter(e => e.cliente?.id == this.queryparams()['id_cliente']);

  confirm = async () => {
    try {
      await this.window.usher.get().update();
      this.closeModal();
      return this.notification.success({text: this.window.dictionary.get('aggiornamento_successo')});
    } catch(e) {
      console.log(e);
      return this.notification.danger({text: this.window.dictionary.get('errore_generico')});
    }
  };

  openModal = () => this.$("app-client-detail").fadeIn(300);
  closeModal = () => this.$("app-client-detail").fadeOut(300);
}