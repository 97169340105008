import { Component, NgZone } from '@angular/core';

import { GaranziaCardComponent } from '../../components/garanzia-card/garanzia-card.component';

@Component({
  selector: 'app-preventivo-modal',
  templateUrl: './preventivo-modal.component.html',
  styleUrls: ['./preventivo-modal.component.css']
})
export class PreventivoModalComponent {
    window:any=<any>window;
    $:any=<any>this.window.$;
    notification:any = new this.window.HPNotification({parent: 'app-preventivo-modal'});
    
    queryparams:any = () => decodeURI(this.window.location.href)
    .split('?')[1]?.split('&')
    .map(param => param.split('='))
    .reduce((values, [ key, value ]) => {
        values[ key ] = value
        return values
    }, {}) || {};
    changeQueryParam:any = (values:any = {}) => new Object({ ...this.queryparams(), ...values });
    preventivo:any = {
        table_selected_indispensabili: [],
        table_selected_opzionali: [],
        pacchetto: {},
        questionario: {}
    };
    formatted_questionario:any = [];
    constructor(
        private ngZone: NgZone
    ) {
        this.window.plussimple_signal.signaller.addEventListener('download-preventivo-modal', (ev:any) => this.ngZone.run(() => {
            let id = ev?.detail?.id;
            this.preventivo = this.window.usher.get()?.manager_configuration?.preventivi?.find(e => e.id == (id || this.queryparams()['preventivo'])) || {
                table_selected_indispensabili: [],
                table_selected_opzionali: [],
                pacchetto: {},
                questionario: {}
            };
            this.formatted_questionario = Object.keys(this.getPreventivo()?.questionario)?.reduce((a:any, b:any) => {
                a = a || [];
                let questionario_obj = this.getQuestionario(b)[0],
                    temp_questionario = {
                        name: questionario_obj?.name,
                        questions: this.getPreventivo()?.questionario[b].map(e => this.getFormattedAnswer(questionario_obj, e))
                    };
                if(!!questionario_obj && !!questionario_obj.public_token) a.push(temp_questionario);
                return a;
            }, []).sort((a:any, b:any) => a.name > b.name ? 1 : -1);
        }));
    }

    openModal = () => this.$("app-preventivo-modal").fadeIn(300);
    closeModal = () => this.$("app-preventivo-modal").fadeOut(300);

    getPreventivo = () => this.preventivo;

    getGaranzia = (pt:any = undefined) => !!pt ? this.window.collection_data?.find(b => b.configuration.type == 'garanzia' && b.public_token == pt) : { configuration: {}};

    getAgenzia = (table:any = undefined) => !!table.agenzia ? this.window.collection_agenzie.find(e => e.public_token == table?.agenzia) : {};

    getPriceTable = (table:any = undefined) => (!!table && table.total_price != false) ? this.window.middleware.utils.formatPrice(table.total_price) : "Nessuna offerta";

    getQuestionario = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'questionario' && (!pt || b.public_token == pt)) || [];

    getFormattedAnswer = (questionario:any, answer_obj:any) => {
        let question = questionario?.configuration?.questions?.find(e => e.id == answer_obj?.id_question) || {},
            result = {
                title: question.title,
                answer: ""
            };
        if(!questionario || !answer_obj) return result;
        if(question?.type.includes("testo_")) {
            result.answer = answer_obj.value[0];
            return result;
        }
        for(let i=0; i<answer_obj.value.length; i++) {
            result.answer += `${i > 0 ? ' - ' : ''}${question.answers.find(e => e.id == answer_obj.value[i]).text}`;
        }
        return result;
    };

    getFormattedQuestionario = () => this.formatted_questionario;

    getStatus = () => {
        let preventivo = this.getPreventivo();
        if(!preventivo) return "";
        switch(preventivo.status) {
            case "pending": 
                return "In attesa";
            case "sent": 
                return "Risposta inviata";
            case "denied": 
                return "Rifiutato";
            default: 
                return "";
        }
    };

    canBuy = () => this.preventivo.status == 'sent';

    acquista = async () => {
        if(!this.canBuy()) return this.notification.danger({text: this.window.dictionary.get('no_offerte_garanzie')});
        try {
            let result = await this. window.sf.utils.$http({
                url : `${this.window.config.urls.url_php}/endpoint.php/payment`,
                method : "POST",
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                data : {
                  preventivo: this.preventivo.id,
                  private_token: this.window.usher.get().private_token,
                  public_token_parent: this.window.config.public_token_parent
                }
            });
            this.openLink({url: result.link});
            this.closeModal();
            return;
        } catch(e) {
            console.log(e);
            return this.notification.danger({text: this.window.dictionary.get('errore_generico')});
        }
    };

    getLink = (doc:any) => `${this.window.config.urls.url_media}/get.php?private_token=${this.window.usher.getPrivateToken()}&name=${doc.link}`;
    
    downloadPdf = (doc:any) => `${this.window.config.urls.url_php}/endpoint.php/pdf?public_token=${this.window.usher.get().public_token}&id_obj=${this.getPreventivo().id}&filename=${doc}&type=fill`;

    openLink = (params:any) => {
        this.window.sf.utils.loader.value += 1;
        try {
            let popUp = window.open(params.url, params.target || '_blank');
            popUp.focus();
        } catch (e) {
            console.log(e);
            window.location.href=params.url;
        }
        if(params.target != '_self') this.window.sf.utils.loader.value -= 1;
    };
}