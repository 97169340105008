import { Component } from '@angular/core';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent {
  window:any=<any>window;
  $:any=<any>this.window.$;
  notification:any = new this.window.HPNotification({parent: 'app-add-client'});
  
  rand = (num:any = null) => {
    num = num || 12;
    var text = "";
    var characters = "_ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < num; i++) {
        text += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return text;
  };
  temp:any = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    id: this.rand()
  };
  constructor() {}

  confirm = async () => {
    if(!this.temp.name) return this.notification.danger({text: this.window.dictionary.get('aggiungi_nome')});
    if(!this.temp.surname) return this.notification.danger({text: this.window.dictionary.get('aggiungi_cognome')});
    if(!this.temp.email) return this.notification.danger({text: this.window.dictionary.get('aggiungi_email')});
    try {
      this.window.usher.get().public_configuration.clients = this.window.usher.get().public_configuration.clients || [];
      this.window.usher.get().public_configuration.clients.push(this.temp);
      await this.window.usher.get().update();
      this.notification.success({text: this.window.dictionary.get('cliente_aggiunto_successo')});
      return this.closeModal();
    } catch(e) {
      console.log(e);
      return this.notification.danger({text: this.window.dictionary.get('errore_generico')});
    }
  };

  openModal = () => this.$("app-add-client").fadeIn(300);
  closeModal = () => this.$("app-add-client").fadeOut(300);

}
