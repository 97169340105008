import { Component } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent {

    window:any=<any>window;
    AOS:any=<any>this.window.AOS;
    $:any=<any>this.window.$;

    constructor() {}

    ngAfterViewInit(): void {
        this.arrangeView();
        this.window.scrollTo(0,0);
    }

    arrangeView() {
        this.AOS.init({
            disable: 'mobile',
            duration: 800,
            anchorPlacement: 'center-bottom',
            once: true,
        });
    }
    
    show = (selector:any) => this.$(selector).fadeIn(300);
    hide = (selector:any) => this.$(selector).fadeOut(300);

    getInfoGenerali = (param:any) => this.window.info_data[param] || [];

    getMembers = () => this.window.info_data?.team?.team || [];

    getLink = (name:any) => !!name ? `${this.window.config.urls.url_media}/public/${name}` : '';
}
