import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
  window:any=<any>window;
  AOS:any=<any>this.window.AOS;
  $:any=<any>this.window.$;
  update_timeout:any = null;
  info_expanded:any = false;

  rand = (num:any = null) => {
    num = num || 12;
    var text = "";
    var characters = "_ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < num; i++) {
        text += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return text;
  };
  readFile = (input) => new Promise(async (res, rej) => { // , mode = 0
    let promiseArray = [],
        result = [],
        files = input.target.files || [];

    for(let index = 0, file; index < files.length; index++) {
        file = files[index];

        promiseArray.push(new Promise((resolve, reject) => {
            var reader = new FileReader();

            // reader.onloadend
            reader.onload = (e) => {
                // if (mode == 0) result.push(e.target.result);
                // if (mode == 1)
                result.push({
                  content : e.target.result,
                  name : this.rand(),
                  filename : file.name
                });
                resolve(result); // e.target.result
            };

            reader.readAsDataURL(file);

            // reader.readAsText(file);
        }));
    };

    await Promise.all(promiseArray);
    res(result);
  });
  upload = (data:any, silentLoader = false) => this.window.sf.utils.$http({
		method: 'POST',
		url: `${this.window.config.urls.url_php}/sf/v1/media/upload.php`,
		headers: {
			'Content-Type': 'application/json'
		},
		data: {
      private_token : this.window.usher.getPrivateToken(),
      name : data.name,
      content : data.content,
      private : true,
      onlymine : false
		}
  }, silentLoader);
  constructor(
    private route:ActivatedRoute,
    private router:Router
) {
    this.window.usher.checkLogin().catch(e => this.show('app-login'));
  };

  ngAfterViewInit(): void {
    this.arrangeView();
    this.window.scrollTo(0,0);
  };

  queryparams:any = () => decodeURI(this.window.location.href)
    .split('?')[1]?.split('&')
    .map(param => param.split('='))
    .reduce((values, [ key, value ]) => {
        values[ key ] = value
        return values
    }, {}) || {};

  changeQueryParam:any = (values:any = {}) => new Object({ ...this.queryparams(), ...values });

  arrangeView() {
    this.AOS.init({
      disable: 'mobile',
      duration: 800,
      anchorPlacement: 'center-bottom',
      once: true,
    });
  };

  updateOnChange = () => {
    this.window.clearTimeout(this.update_timeout);
    this.update_timeout = setTimeout(() => this.window.usher.get().update(true), 3000);
  };

  getUser = () => this.window.usher.isLogged() ? this.window.usher.get() : {
    username: "",
    email: "",
    configuration: {
      type: 'fisica',
      nome: '',
      cognome: '',
      ragione_sociale: '',
      cf: '',
      p_iva: '',
      telefono: '',
      indirizzo: '',
      cap: '',
      provincia: '',
      citta: '',
      professione: ''
    },
    manager_configuration: {
      convenzioni: [],
      preventivi: []
    }
  };
  
  getConvenzione = (pt:any) => this.window.collection_data?.find(b => b.configuration.type == 'convenzione' && (!pt || b.public_token == pt)) || {
    name: "",
    configuration: {
      documents: []
    }
  };

  getPicture = (pic:any) => `${this.window.config.urls.url_media}/public/${pic}`;

  getDescription = (pt_convenzione:any) => this.getConvenzione(pt_convenzione)?.configuration?.description?.length > 300 ? `${this.getConvenzione(pt_convenzione)?.configuration?.description?.substr(0, 300)}...` : this.getConvenzione(pt_convenzione)?.configuration?.description;
  
  showConvenzione = (pt:any) => {
    this.window.plussimple_signal.emit({name: 'convenzione', detail: {public_token: pt}});
    this.show('app-convenzione-documents');
    return;
  };

  getProfileImage = async (ev:any) => {
    try {
      let parsed_file = (await this.readFile(ev))[0];
      await this.upload({
        name: parsed_file?.name,
        content: parsed_file?.content
      });
      this.window.usher.get().picture = parsed_file.name;
      await this.window.usher.get().update();
      return this.window.plussimple_signal.emit({name: 'notification', detail: {type: 'success', text: this.window.dictionary.get('immagine_profilo_caricata')}});
    } catch(e) {
      console.log(e);
      return this.window.plussimple_signal.emit({name: 'notification', detail: {type: 'danger', text: this.window.dictionary.get('errore_generico')}});
    }
  };

  getPacchetto = (pt:any) => this.window.collection_data?.find(b => b.configuration.type == 'pacchetto' && (!pt || b.public_token == pt)) || {
    name: "",
    configuration: {}
  };

  getLink = () => this.getUser().picture ? `${this.window.config.urls.url_media}/get.php?private_token=${this.window.usher.getPrivateToken()}&name=${this.getUser().picture}` : './assets/icon/robot.svg';

    downloadDocument = (doc:any) => this.window.open(`${this.window.config.urls.url_media}/get.php?private_token=${this.window.usher.getPrivateToken()}&name=${doc.link}`, "_blank");

    openPreventivo = (id:any) => {
        this.window.plussimple_signal.emit({name: 'download-preventivo-modal', detail: {id: id}});
        this.show('app-preventivo-modal');
    };

    openAssicurazione = (id:any) => {
        this.window.plussimple_signal.emit({name: 'download-assicurazione-modal', detail: {id: id}});
        this.show('app-assicurazione-modal');
    };

    getPreventivi = (attesa:any = true) => this.window.usher.get().manager_configuration.preventivi?.filter(e => (!!attesa && e.status == 'pending') || (!attesa && e.status == 'sent')) || [];

    preventivoSaldare = (preventivo:any) => !![...preventivo.table_selected_indispensabili, ...preventivo.table_selected_opzionali].every(e => e != false && !isNaN(e.total_price) && e.total_price >= 0);

    show = (selector:any) => this.$(selector).fadeIn(300);
    hide = (selector:any) => this.$(selector).fadeOut(300);

    getNome = () => ((!!this.window.usher.get()?.configuration?.nome || !!this.window.usher.get()?.configuration?.cognome) ? `${this.window.usher.get()?.configuration?.nome} ${this.window.usher.get()?.configuration?.cognome}` : this.window.usher.get()?.public_configuration?.nome) || "Nome Cognome";
}