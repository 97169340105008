import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-prodotto',
  templateUrl: './prodotto.component.html',
  styleUrls: ['./prodotto.component.css']
})
export class ProdottoComponent {
  window:any=<any>window;
  AOS:any=<any>this.window.AOS;
  $:any=<any>this.window.$;
  collapseFaq:any = {};
  
  constructor(
    private route:ActivatedRoute,
    private router:Router
  ) {}

  ngAfterViewInit(): void {
    this.arrangeView();
    this.window.scrollTo(0,0);
  }

  queryparams:any = () => this.route.snapshot.queryParams;

  arrangeView() {
    this.AOS.init({
      disable: 'mobile',
      duration: 800,
      anchorPlacement: 'center-bottom',
      once: true,
    });
  }

  getInfoGenerali = (param:any) => this.window.info_data[param] || [];

  getGaranzie = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'garanzia' && (!pt || b.public_token == pt)) || [];
  getPacchetti = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'pacchetto' && (!pt || b.public_token == pt)) || [];
  getProfessioni = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'professione' && (!pt || b.public_token == pt)) || [];
  getProfessioniPacchetti = (pt:any = undefined) => this.getProfessioni(pt).reduce((a:any, b:any) => {
    a = a || [];
    if(!a.find(e => e.public_token == b.public_token) && this.getPacchetti().some(el => el.configuration.professioni.includes(b.public_token))) a.push(b);
    return a;
  }, []) || [];

  getPicture = (pic:any, fallback:any = 'assets/img/inner-banner.jpg') => !!pic? `${this.window.config.urls.url_media}/public/${pic}` : fallback;

  calculateTax = (condizione:any = {}) => {
    if(isNaN(condizione.tasso)) return Infinity;
    if(condizione.tasso == 0 || !condizione.tasso) return condizione.minimo;
    return Math.max(condizione.minimo || 0, parseFloat(condizione.fatturato || 0)*parseFloat(condizione.tasso || 1)/100);
  };

  getMinPrice = (pacchetto:any) => this.getPacchetto()?.configuration?.garanzie?.reduce((a:any, b:any) => {
    a = a || 0;
    let garanzia = this.getGaranzie(b)[0] || {},
        temp_tables = this.getGaranzie(b)[0].configuration.tables || [],
        tables = [],
        minimo = 0;

    if(temp_tables.length) {
      tables = temp_tables.map(e => e.values.sort((c:any, d:any) => this.calculateTax(c) >= this.calculateTax(d) ? 1 : -1));
      minimo = this.calculateTax(tables.sort((c:any, d:any) => this.calculateTax(c[0]) >= this.calculateTax(d[0]) ? 1 : -1)[0][0]);
    }

    if(!!garanzia.configuration.tax && garanzia.configuration.tax != 0) minimo += minimo*(garanzia.configuration.tax/100);

    let costo = garanzia.configuration.base_price + (minimo == Infinity ? 0 : minimo);

    if(!this.getPacchetto()?.configuration.single_garanzia) return a += costo;
    return a = (!a || a > costo) ? costo : a;
  }, 0).toFixed(2) || 0;


  getPacchetto = () => this.getPacchetti(this.queryparams()['public_token'])[0] || {
    name: "",
    configuration: {}
  };

    openQuestionario = () => {
        if(this.window.config.appname == 'plussimple' && !this.window.usher.isLogged()) {
            this.window.plussimple_signal.emit({name: 'open-info-preventivo', detail: {}});
            return;
        }  
        this.window.plussimple_signal.emit({name: 'questionario', detail: {pacchetto: this.getPacchetto()}});
    };

  show = (selector:any) => this.$(selector).fadeIn(300);
  hide = (selector:any) => this.$(selector).fadeOut(300);
}
