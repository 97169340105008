import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-convenzione-card',
  templateUrl: './convenzione-card.component.html',
  styleUrls: ['./convenzione-card.component.css']
})
export class ConvenzioneCardComponent {
  @Input() convenzione:any = {
    configuration: {
      description: "",
    },
    name: "",
    public_token: ""
  };
  @Input() box:any = false;
  
  window:any=<any>window;
  AOS:any=<any>this.window.AOS;
  $:any=<any>this.window.$;
  Typed:any=<any>this.window.Typed;
  changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });
  queryparams:any = () => this.route.snapshot.params;

  constructor(
    private route:ActivatedRoute,
    private router:Router
  ) {};

    ngAfterViewInit(): void {
        this.arrangeView();
    };

    arrangeView() {};

    getPicture = (pic:any) => `${this.window.config.urls.url_media}/get.php?private_token=${this.window.usher.getPrivateToken()}&name=${pic}`;

    getDescription = () => this.convenzione.configuration.description.length > 300 ? `${this.convenzione.configuration.description.substr(0, 300)}...` : this.convenzione.configuration.description;

    showConvenzione = () => {
        if(!this.window.usher.isLogged() || !this.window.usher.get()?.manager_configuration?.convenzioni?.includes(this.convenzione.public_token)) return this.show('app-new-convenzione');
        this.window.plussimple_signal.emit({name: 'convenzione', detail: {public_token: this.convenzione.public_token}});
        this.openModal('#Convenzione_modal');
        return;
    };

    showButton = () => !!this.window.usher.isLogged() && !!this.window.usher.get()?.manager_configuration?.convenzioni?.includes(this.convenzione.public_token);

    show = (selector:any) => this.$(selector).fadeIn(300);
    hide = (selector:any) => this.$(selector).fadeOut(300);

    openModal = (selector:any) => this.$(selector).modal('show');
    hideModal = (selector:any) => this.$(selector).modal('hide');
}