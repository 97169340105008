import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  window:any=<any>window;
  AOS:any=<any>this.window.AOS;
  $:any=<any>this.window.$;
  Typed:any=<any>this.window.Typed;
  changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });

  constructor(
    private route:ActivatedRoute,
    private router:Router
  ) {}

  ngAfterViewInit(): void {
      this.arrangeView();
      this.window.scrollTo(0,0);
  }

  arrangeView() {

      this.$('.post-slider').slick({
          dots: false,
          autoplay: false,
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          prevArrow:"<button type='button' class='cus-slick-btn slick-prev'><i class='far fa-chevron-left'></i></button>",
          nextArrow:"<button type='button' class='cus-slick-btn slick-next'><i class='far fa-chevron-right'></i></button>",
          responsive: [
          {
            breakpoint: 1151,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2.2,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1.2,
            }
          }]
      });

      this.$('.assur-slider').slick({
          dots: false,
          autoplay: false,
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          prevArrow:"<button type='button' class='cus-slick-btn slick-prev'><i class='far fa-chevron-left'></i></button>",
          nextArrow:"<button type='button' class='cus-slick-btn slick-next'><i class='far fa-chevron-right'></i></button>",
          responsive: [
          {
            breakpoint: 1151,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2.2,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1.2,
            }
          }
          ]
      });

      this.$('.convenzione-slider').slick({
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow:"<button type='button' class='cus-slick-btn slick-prev'><i class='far fa-chevron-left'></i></button>",
        nextArrow:"<button type='button' class='cus-slick-btn slick-next'><i class='far fa-chevron-right'></i></button>"
      });

      this.$('.partner-slider').slick({
          dots: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 1000,
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          cssEase: 'linear',
          responsive: [
          {
            breakpoint: 1151,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            }
          }
          ]
      });

      this.AOS.init({
          disable: 'mobile',
          duration: 800,
          anchorPlacement: 'center-bottom',
          once: true,
      });

      new this.Typed('.typing', {
        strings: [this.window.dictionary.get("professionisti"), this.window.dictionary.get("aziende"), this.window.dictionary.get("affinity"), this.window.dictionary.get("privati")],
        typeSpeed: 70,
        loop:true,
        startDelay: 3000,
        backDelay: 2500,
        backSpeed: 70,
        showCursor: true,
        cursorChar: '|',
        autoInsertCss: true
      });
  }

  getGaranzie = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'garanzia' && (!pt || b.public_token == pt)) || [];
  getPacchetti = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'pacchetto' && (!pt || b.public_token == pt)) || [];
  getProfessioni = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'professione' && (!pt || b.public_token == pt)) || [];
  getProfessioniPacchetti = (pt:any = undefined) => this.getProfessioni(pt).reduce((a:any, b:any) => {
    a = a || [];
    if(!a.find(e => e.public_token == b.public_token) && this.getPacchetti().some(el => el.configuration.professioni.includes(b.public_token))) a.push(b);
    return a;
  }, []) || [];
  
  getConvenzioni = (pt:any = undefined) => this.window.collection_data?.filter(b => b.configuration.type == 'convenzione' && (!pt || b.public_token == pt)) || [];

  getPicture = (pic:any) => `${this.window.config.urls.url_media}/public/${pic}`;

  show = (selector:any) => this.$(selector).fadeIn(300);
  hide = (selector:any) => this.$(selector).fadeOut(300);
  
  getInfoGenerali = (param:any) => this.window.info_data[param] || [];
}
