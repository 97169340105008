import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent {
    window:any=<any>window;
    AOS:any=<any>this.window.AOS;
    blog:any = {};
    queryparams:any = () => decodeURI(this.window.location.href)
      .split('?')[1]?.split('&')
      .map(param => param.split('='))
      .reduce((values, [ key, value ]) => {
          values[ key ] = value
          return values
      }, {}) || {};
  
    changeQueryParam:any = (values:any = {}) => new Object({ ...this.queryparams(), ...values });
    constructor(private route: ActivatedRoute){}

    isSectionActive(section: string): boolean {
        let element = false;
        this.route.fragment.subscribe((fragment: string) => {
            element = fragment === section.split("#").pop();
        });
        return element;
    };

    ngAfterViewInit(): void {
        this.blog = this.queryparams().blog || {};
        console.log(this.blog);
        this.arrangeView();
        this.window.scrollTo(0,0);
    };

    arrangeView() {
        this.AOS.init({
            disable: 'mobile',
            duration: 800,
            anchorPlacement: 'center-bottom',
            once: true,
        });
    };

}