<div class="login-page">
	<div class="container">
		<div class="inner-login-content">
			<div class="login-logo mb-2" data-aos="fade-up" data-aos-delay="100">
				<a class="back-page cursor-pointer" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></a>
				<a (click)="closeModal()">
					<app-logo></app-logo>
				</a>
			</div>
			<div class="login-title" data-aos="fade-up" data-aos-delay="100">
				<h3>{{window.dictionary.get('inserisci_email_recupero')}}</h3>
			</div>
			<form class="login-form" action="" data-aos="fade-up" data-aos-delay="100">
				<div class="form-group">
					<input type="text" class="form-control" id="login-username" name="login-username" placeholder="{{window.dictionary.get('email')}}" [(ngModel)]="temp.email">
				</div>

				<div class="form-group">
					<button type="button" class="btn btn-primary" (click)="confirm()">Invia</button>
				</div>
			</form>
		</div>
	</div>
</div>