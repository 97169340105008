<section class="title-bar news-bar">
    <div class="container">
        <h2 data-aos="fade-in" data-aos-delay="0">{{ window.dictionary.get('nostre_notizie') }}</h2>
    </div>
</section>
<section class="sec-news">
    <div class="container">
        <div class="sec-filters" data-aos="fade-in" data-aos-delay="100">
            <div class="search-bar">
                <div class="left-icon"><i class="far fa-search text-alt"></i></div>
                <input type="text" name="filter_blog" [(ngModel)]="filter.text" [placeholder]="window.dictionary.get('cerca_articolo')">
            </div>
            <div class="categories-filter">
                <ul>
                    <li *ngFor="let tag of getTags(); let i = index;">
                        <a [routerLink]="" [queryParams]="changeQueryParam({blog_page: 0})" (click)="selectTag(tag)" class="btn btn-light text-capitalize" [ngClass]="{'active': tags.includes(tag)}">{{ tag }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-3" *ngFor="let news of getPages()[getPage()]; let i = index;" data-aos="fade-up" [data-aos-delay]="(i+1)*200">
                <app-news-card [news]="news"></app-news-card>
            </div>
        </div>
        <div class="pagination-wrap">
            <ul class="pagination">
                <li class="page-item" [ngClass]="{'disabled': getPage() == 0}"><a [ngClass]="{'disabled': getPage() == 0}" [routerLink]="" [queryParams]="changeQueryParam({blog_page: getPage()-1})"><i class="far fa-arrow-left"></i></a></li>
                <li class="page-item" *ngFor="let pag of getPagination(); let j = index"><a [ngClass]="{'active': getPage() == pag}" [routerLink]="" [queryParams]="changeQueryParam({blog_page: pag})">{{ pag+1 }}</a></li>
                <li class="page-item" [ngClass]="{'disabled': getPage() == getPagination().length-1}"><a [ngClass]="{'disabled': getPage() == getPagination().length-1}" [routerLink]="" [queryParams]="changeQueryParam({blog_page: getPage()+1})"><i class="far fa-arrow-right"></i></a></li>
            </ul>
        </div>
    </div>
</section>