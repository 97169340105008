<section class="overlay-full-banner">
    <div class="banr-bg">
        <img src="assets/img/background-home.jpg" alt="">
        <div class="w-100 h-100 position-absolute background-opacity"></div>
    </div>
    <div class="banr-overlay">
        <div class="p-3 w-100 position-absolute" style="top: 0;">
            <a class="w-100 text-right float-right black-shadow" href="tel:{{ window.info_data.telefono1 }}">
                {{ window.info_data.telefono1 }}
            </a>
        </div>
        <div class="container">
            <h2 class="black-shadow">{{ getConvenzione().name }}</h2>
            <!--<div class="apst-logo">
                <img src="assets/svg/apst-logo.svg" alt="">
            </div>-->
        </div>
    </div>
</section>
<section class="sec-grassavoye-boxes" id="sezione_assicurazioni">
    <div class="container">
        <div class="row justify-content-center">
            <div *ngFor="let pacchetto of getPacchetti(); index as i" [attr.public_token]="pacchetto.public_token" class="col-sm-6 col-md-4 text-center mb-5">
                <app-pacchetto-convenzione-card [pacchetto]="pacchetto"></app-pacchetto-convenzione-card>
            </div>
        </div>
        <div class="actions text-center" (click)="show('coming-soon-modal')">
            <button type="button" class="btn btn-primary supplementary-btn">{{window.dictionary.get("richiedi_informazioni")}}</button>
        </div>
    </div>
</section>

<section class="sec-insurance-boxes" id="sezione_chisiamo">
    <div class="container">
        <h2 class="sec-title text-center">{{ window.dictionary.get('chi_siamo') }}</h2>
        <div class="row">
            <div class="col-md-4" *ngFor="let description of getConvenzione().configuration.descriptions; index as i">
                <div class="grassavoye-product">
                    <div class="g-product-img d-flex">
                        <img class="descrizione-img" [src]="'assets/img/icons-colored/' + description.icon" alt="">
                    </div>
                    <div class="title">
                        <h3>{{ description.title }}</h3>
                    </div>
                    <div class="g-product-body" [innerHtml]="description.text"></div>
                </div>
            </div>
            <!--<div class="col-md-4">
                <div class="grassavoye-product">
                    <div class="g-product-img">
                        <i class="fas fa-check-circle"></i>
                        <img src="assets/svg/plateforme-v2.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="grassavoye-product">
                    <div class="g-product-img">
                        <i class="fas fa-check-circle"></i>
                        <img src="assets/svg/service-v2.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center" [innerHtml]="getConvenzione().configuration.description"></div>-->
        </div>
    </div>
</section>

<section class="sec-assurance">
    <div class="container">
        <!--<h2 class="sec-title text-center">{{window.dictionary.get('nostri_valori')}}</h2>

        <div class="row text-left">
            <div class="col-sm-6 col-md-6 col-lg-3 pb-4">
                <div class="d-flex align-items-center">
                    <i class="fal fa-piggy-bank h2 mr-3"></i>
                    <h5 class="font-weight-bold mt-0">Les tarifs</h5>
                </div>
                <p class="text-dark mt-2">
                    À partir de 71€/mois. Prix négociés avec Generali et Hiscox, adaptés à vos objectifs et votre
                    budget.
                </p>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 pb-4">
                <div class="d-flex align-items-center">
                    <i class="fal fa-door-open h2 mr-3"></i>
                    <h5 class="font-weight-bold mt-0">La flexibilité</h5>
                </div>
                <p class="text-dark mt-2">
                    C’est sans engagement ! Vous choisissez la date de début et fin du contrat. Sans préavis ni délai de
                    résiliation.
                </p>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 pb-4">
                <div class="d-flex align-items-center">
                    <i class="fal fa-history h2 mr-3"></i>
                    <h5 class="font-weight-bold mt-0">24h/24</h5>
                </div>
                <p class="text-dark mt-2">
                    Souscription, contrat, suivi de paiement et modifiable sur votre espace client internet et mobile.
                </p>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 pb-4">
                <div class="d-flex align-items-center">
                    <i class="fal fa-comment-alt-smile h2 mr-3"></i>
                    <h5 class="font-weight-bold mt-0">Le suivi</h5>
                </div>
                <p class="text-dark mt-2">
                    Un accompagnement individuel : +Simple via Gras Savoye vous accompagne pas à pas dans les étapes de
                    la mise en place de votre contrat.
                </p>
            </div>
        </div>-->

        <h2 class="sec-title text-center">{{window.dictionary.get('nostri_valori')}}</h2>
        <div class="row">
            <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="{{(i+1)*100}}" *ngFor="let punto_forza of getInfoGenerali('punti_forza'); index as i">
                <h3><i class="fal fa-badge-check mr-2 mb-0"></i>{{punto_forza.title}}</h3>
                <p class="cus-typo">{{punto_forza.description}}</p>
            </div>
        </div>
    </div>
</section>

<section class="sec-assurance sec-partenaires">
    <div class="container">
        <!--<h2 class="sec-title text-center">Nos partenaires</h2>

        <div class="icons reveal in">
            <div class="row align-items-center align-content-stretch">
                <div class="col col-xs">
                    <img alt="hiscox" src="assets/svg/hiscox.svg">
                </div>
                <div class="col col-xs">
                    <img alt="generali" src="assets/svg/generali.svg">
                </div>
                <div class="col col-xs">
                    <img alt="cgrm" src="assets/svg/cgrm.svg">
                </div>
                <div class="col col-xs">
                    <img alt="datarobot" src="assets/svg/datarobot.svg">
                </div>
                <div class="col col-xs">
                    <img alt="apst" src="assets/svg/apst.svg">
                </div>
                <div class="col col-xs">
                    <img alt="tokio-marine" src="assets/svg/tokio-marine.svg">
                </div>
            </div>
        </div>-->
        
        <h2>{{window.dictionary.get("nostri_partner")}}</h2>
        <div class="partner-slider">
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2020/11/25/5fbe640153eb5logo-theoreme-alt.png"
                    alt="">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2019/12/12/5df23a5f63f0ala-banque-postale.svg"
                    alt="La Banque Postale">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2019/12/12/5df24abaadb49grassavoye-alt.svg"
                    alt="Gras Savoye">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2019/12/12/5df269b117d8cbnp-alt.svg"
                    alt="BNP">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2020/07/27/5f1ef58a25ea2ticker-beazley.svg"
                    alt="Beazley">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2019/08/19/5d5b17f5a8d00generali.svg"
                    alt="Generali">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2019/08/19/5d5b190401c67mapa.svg"
                    alt="MAPA">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2019/12/12/5df23a5f63f0ala-banque-postale.svg"
                    alt="La Banque Postale">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2019/08/19/5d5b1980c8babtokio-marine.svg"
                    alt="Tokio Marine">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2019/08/19/5d5b17c7a7035datarobot.svg">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2019/08/19/5d5b161435258allianz.svg"
                    alt="Allianz">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2019/12/26/5e0537bcd22c2besseagro-alt.svg"
                    alt="Besse Agro">
            </div>
            <div class="img-slide">
                <img src="https://s3.plussimple.fr/content/img/2020/11/30/5fc52974b49eewakam-gris.svg"
                    alt="Wakam (ex La Parisienne)">
            </div>
        </div>
    </div>
</section>