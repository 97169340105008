<section class="title-bar">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/news">Toutes les actus</a></li>
                    <li class="breadcrumb-item">Protection</li>
                </ol>
                <h1 data-aos="fade-up" data-aos-delay="200">Quelle est la différence entre RC et décennale ?</h1>
            </div>
        </div>
    </div>
</section>
<section class="sec-featured" data-aos="fade-up">
    <div class="container">
        <img src="https://s3.plussimple.fr/content/img/2020/12/01/5fc65a3abcb19difference-rc-decennale-1350.jpg" alt="">
    </div>
</section>
<section class="sec-details">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <div class="stick-sidebar">
                    <div class="accordion">
                        <div class="accordion-title">
                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#sommaire"><i
                                    class="far fa-angle-down"></i> Sommaire</button>
                        </div>
                        <div class="accordion-body">
                            <div class="collapse show" id="sommaire">
                                <ul class="nav-list">
                                    <li [class.active]="isSectionActive('lassurance-decennale-obligatoire')">
                                        <a [routerLink]="['/news/detail']"
                                            fragment="lassurance-decennale-obligatoire">L'assurance décennale,
                                            obligatoire</a>
                                    </li>
                                    <li
                                        [class.active]="isSectionActive('constructeurs-quelles-sont-vos-responsabilites')">
                                        <a [routerLink]="['/news/detail']"
                                            fragment="constructeurs-quelles-sont-vos-responsabilites">Constructeurs :
                                            quelles sont vos responsabilités ?</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card round-card offer-card">
                        <div class="card-body">
                            <div class="card-head d-flex">
                                <div class="card-icon mr-auto">
                                    <i class="fal fa-user-hard-hat"></i>
                                </div>
                                <div class="ml-auto text-right">
                                    <h5>68,50 €</h5>
                                    <span>Minimum /mois</span>
                                </div>
                            </div>
                            <h5>Décennale Maçon</h5>
                        </div>
                        <div class="card-footer text-center">
                            <a class="btn btn-primary btn-lg">Faire un devis</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-8">
                <div class="top-meta d-flex">
                    <span>11 novembre 2020</span>
                    <div>•</div>
                    <a href="#">Protection</a>
                </div>
                <article>
                    <p>Créer son activité professionnelle peut s’avérer être un véritable casse-tête
                        administratif.Pourtant un oubli peut s’avérer dramatique en cas de problème. Pour être certain
                        d’être protégé, nous vous aidons à faire le point.</p>
                    <h3 id="lassurance-decennale-obligatoire">L'assurance décennale, obligatoire</h3>
                    <p>Que vous soyez déjà en activité ou que vous vous lanciez, <strong>l’assurance
                            décennale</strong> est obligatoire pour tous les professionnels du BTP et doit être
                        souscrite avant tout démarrage des travaux que vous réalisez.</p>
                    <p>La loi vous oblige à souscrire une assurance décennale pour couvrir cette
                        responsabilité&nbsp;: en qualité de constructeur vous devez souscrire une assurance de
                        responsabilité civile décennale à l’ouverture de tout chantier (article L. 241-1 du Code des
                        assurances). Le non-respect de cette obligation est passible d’une peine d’emprisonnement de
                        six mois et/ou d’une amende de 75 000 euros.</p>
                    <p>Tout constructeur impliqué dans la construction d'un ouvrage neuf ou sur
                        existant (bâtiment divers, logement,...), est soumis à un régime de responsabilité. Cette
                        responsabilité varie en fonction de l’étape des travaux (en cours de travaux ou après
                        réception des travaux) et de la nature des dommages.
                        Le constructeur peut être l'une des personnes suivantes&nbsp;:</p>
                    <ul class="ml-12 mb-12 md:ml-16 list-disc">
                        <li>Architecte</li>
                        <li>Entrepreneur</li>
                        <li>Technicien</li>
                        <li>Prestataire lié au maître d'ouvrage par un contrat de louage d'ouvrage</li>
                        <li>Personne ou mandataire qui vend, après achèvement, un ouvrage qu'elle a construit ou
                            fait construire</li>
                        <li>Fabriquant d'un ouvrage, d'une partie d'ouvrage ou d'un élément d'équipement.</li>
                    </ul>
                    <p>Lorsque l’assurance est obligatoire, les constructeurs doivent indiquer sur
                        leurs devis et factures&nbsp;:</p>
                    <ul class="ml-12 mb-12 md:ml-16 list-disc">
                        <li>L’assurance souscrite au titre de leur activité</li>
                        <li>Le nom de l’assureur,</li>
                        <li>La couverture géographique de leur contrat ou garantie.</li>
                    </ul>
                    <h3 id="constructeurs-quelles-sont-vos-responsabilites">Constructeurs&nbsp;: quelles sont vos
                        responsabilités&nbsp;?</h3>
                    <h6>1. Pendant les travaux&nbsp;:</h6>
                    <p>Du début des travaux à leur réception, vous supportez tous les risques affectant
                        vos travaux (article 1788 du Code civil).
                        Ainsi vous êtes responsable des dommages qui surviennent à vos travaux pendant leur
                        exécution quelle qu’en soit la cause&nbsp;: le vol, la dégradation des matériaux, ou la
                        perte de l’ouvrage, détruit par un incendie de cause inconnue, alors que la réception n’a
                        pas eu lieu, restent à votre charge.</p>
                    <h6>2. Après la réception des travaux&nbsp;:</h6>
                    <p>Les constructeurs sont responsables envers le maître ou l’acquéreur de l’ouvrage
                        en cas de malfaçons affectant les travaux de construction réalisés. Ainsi, après la
                        réception des travaux, vous êtes tenu à trois types d’obligations légales limitées dans le
                        temps.</p>
                    <h6>La garantie de parfait achèvement</h6>
                    <p>Pendant un an après la réception, vous devez réparer tous les désordres, de
                        nature décennale ou non, signalés par le maître d’ouvrage lors de la réception ou durant
                        l’année qui suit.
                        Cette garantie n’est jamais assurée.</p>
                    <h6>La garantie de bon fonctionnement</h6>
                    <p>Pendant deux ans, vous êtes tenu de réparer les défauts qui affectent le bon
                        fonctionnement des équipements dissociables du corps de l’ouvrage sans détérioration de leur
                        support tels que des radiateurs électriques, une climatisation…</p>
                    <p>L’assurance de cette garantie est facultative mais vivement recommandée.</p>
                    <h6>La responsabilité civile décennale</h6>
                    <p>Pendant dix ans après la réception, vous êtes responsable envers le maître de
                        l’ouvrage des dommages, y compris ceux provenant d’un vice du sol, qui&nbsp;:</p>
                    <ul class="ml-12 mb-12 md:ml-16 list-disc">
                        <li>compromettent la solidité de l’ouvrage ;</li>
                        <li>affectent la solidité d’un de ses éléments indissociables. Un élément
                            est considéré comme indissociable lorsque sa dépose, son démontage ou son
                            remplacement ne peuvent s’effectuer sans détérioration ou enlèvement de matière de
                            l’ouvrage ;</li>
                        <li>rendent l’ouvrage impropre à sa destination, celui-ci ne pouvant dans ce
                            cas remplir la fonction à laquelle il est destiné.</li>
                    </ul>
                    <p>Cette responsabilité s'applique sauf si le constructeur prouve que les dommages
                        proviennent d'une cause étrangère. Le constructeur est donc présumé responsable ; il lui
                        appartient donc de démontrer qu’il n’est pas à l’origine des dommages allégués.</p>
                    <p>Soyez vigilent dans la déclaration de vos activités auprès de votre assureur.
                    </p>
                    <p>Vous devez définir précisément les activités que vous exercez&nbsp;: on entend
                        par activité les lots techniques (plomberie, électricité, carrelage, …) tels qu’ils sont
                        répertoriés dans la nomenclature de l’assureur.
                        Ce qui importe, c’est que les travaux que vous exécutiez correspondent aux secteurs
                        d’activité professionnelle déclarés. Si vous effectuez des travaux que vous n’avez pas
                        déclarés, vous ne serez pas couvert par votre assurance en cas de sinistre.</p>
                    <h6>3. Les assurances responsabilité civile avant et après réception</h6>
                    <p>Vos responsabilités du fait des travaux sont couvertes, d’une part, par
                        l’assurance de responsabilité civile générale, dite responsabilité civile exploitation et
                        d’autre part, par l’assurance de la responsabilité civile après travaux qui couvre les
                        dommages causés, après leur livraison.
                        Votre responsabilité civile peut être recherchée par le maître d’ouvrage et des tiers pour
                        des dommages corporels, matériels ou immatériels causés à l’occasion des travaux réalisés
                        qui ne touchent pas l’ouvrage.</p>
                </article>

                <div class="sec-related">
                    <h2>À lire aussi</h2>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                            <app-news-card></app-news-card>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <app-news-card></app-news-card>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <app-news-card></app-news-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>