
<section class="title-bar">
    <div class="container">
        <h1 data-aos="fade-up" data-aos-delay="100">{{window.dictionary.get('team')}}</h1>
        <h2 data-aos="fade-up" data-aos-delay="200">{{window.dictionary.get("nostra_storia")}}</h2>
    </div>
</section>
<section class="sec-gallery">
    <div class="container">
        <div class="row mb-5">
            <div class="col-12 mb-5" data-aos="fade-up" [hidden]="!window.info_data.team.immagini.img1"> <!--col-md-6 col-lg-8-->
                <img style="width: 100%;" [src]="getLink(window.info_data.team.immagini.img1)" alt="">
                <!--<p class="text-justify" [innerHtml]="window.info_data.team.chi_siamo"></p>
                <div class="content-action" (click)="show('coming-soon-modal')">
                    <a [routerLink]="" class="btn btn-primary d-table m-auto">{{window.dictionary.get("richiedi_informazioni")}}</a>
                </div>-->
            </div>
            <!--<div class="col-12 col-md-6 col-lg-4" [hidden]="!window.info_data.team.immagini.img2 || !window.info_data.team.immagini.img3">
                <div class="mb-8" data-aos="fade-up">
                    <img [src]="getLink(window.info_data.team.immagini.img2)" alt="">
                </div>
                <div data-aos="fade-up">
                    <img [src]="getLink(window.info_data.team.immagini.img3)" alt="">
                </div>
            </div>-->
        </div>
    </div>
</section>

<section class="sec-content sec-padding text-center" data-aos="fade-up">
    <div class="container">
        <p [innerHtml]="window.info_data.team.chi_siamo"></p>
        <div class="content-action" (click)="show('coming-soon-modal')">
            <a [routerLink]="" class="btn btn-primary">{{window.dictionary.get("richiedi_informazioni")}}</a>
        </div>
    </div>
</section>
<section class="sec-team sec-padding-bottom">
    <div class="container">
        <h2 data-aos="fade-up" data-aos-delay="200">{{window.dictionary.get("team")}}</h2>
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-6 col-lg-4 mt-3" data-aos="fade-up" data-aos-delay="{{(i+1)*100}}" *ngFor="let member of getMembers(); index as i">
                <div class="card card-shadow">
                    <img [src]="getLink(member.image)" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <div class="card-title">
                            <h3>{{member.name}}</h3>
                            <h5>{{member.job}}</h5>
                        </div>
                        <p>{{member.description}}</p>
                        <!--<div class="card-tags">
                            <span>Technologie</span>
                            <span>Innovation</span>
                            <span>Développement web</span>
                        </div>
                        <div class="card-action">
                            <a href="#" class="btn btn-link">En savoir plus</a>
                        </div>-->
                    </div>
                </div>
            </div>
            <!--
            <div class="col-12 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
                <div class="card card-shadow">
                    <img src="https://s3.plussimple.fr/content/img/2020/02/14/5e46bc31d577fanthony-jouannau.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <div class="card-title">
                            <h3>Anthony Jouannau</h3>
                            <h5>Directeur Général</h5>
                        </div>
                        <p>Fondateur du cabinet Condorcet, courtier spécialisé pour une clientèle d’entreprise (vendu en 2017).</p>
                        <div class="card-tags">
                            <span>Partenariats</span>
                            <span>Opérationnel</span>
                            <span>Clientèle</span>
                        </div>
                        <div class="card-action">
                            <a href="#" class="btn btn-link">En savoir plus</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
                <div class="card card-shadow">
                    <img src="https://s3.plussimple.fr/content/img/2020/02/14/5e46bc327e3fderic-mignot.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <div class="card-title">
                            <h3>Eric Mignot</h3>
                            <h5>Président</h5>
                        </div>
                        <p>Plus de 15 ans d’expérience dans l’assurance. Ex-Directeur Général chez Caixa Bank, Boursorama et Hiscox en France.</p>
                        <div class="card-tags">
                            <span>Relations investisseurs</span>
                            <span>Développement international</span>
                        </div>
                        <div class="card-action">
                            <a href="#" class="btn btn-link">En savoir plus</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
                <div class="card card-shadow">
                    <img src="https://s3.plussimple.fr/content/img/2020/02/14/5e46bc3302a6asalah-hamida.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <div class="card-title">
                            <h3>Salah Hamida</h3>
                            <h5>DGD et CTO</h5>
                        </div>
                        <p>Ex-Directeur en R&D Web chez Boursorama. 1er salarié de la startup jusqu’au leadership de la première banque en ligne.</p>
                        <div class="card-tags">
                            <span>Technologie</span>
                            <span>Innovation</span>
                            <span>Développement web</span>
                        </div>
                        <div class="card-action">
                            <a href="#" class="btn btn-link">En savoir plus</a>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</section>
<section class="sec-chiffres sec-padding">
    <div class="container">
        <h2 class="sec-title text-center pb-3" data-aos="fade-up">{{window.dictionary.get("nostri_numeri")}}</h2>
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-4 col-lg-4 mt-5" data-aos="fade-up" data-aos-delay="{{(i+1)*100}}" *ngFor="let cifra of getInfoGenerali('cifre'); index as i">
                <div class="card card-shadow">
                    <div class="status-card">
                        <h2>{{cifra.description}}</h2>
                        <p>{{cifra.title}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-valeurs sec-padding-top">
    <div class="container">
        <h2 class="sec-title text-center">{{window.dictionary.get('nostri_valori')}}</h2>
        <div class="row">
            <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="{{(i+1)*100}}" *ngFor="let punto_forza of getInfoGenerali('punti_forza'); index as i">
                <h3><i class="fal fa-badge-check mr-2 mb-0"></i>{{punto_forza.title}}</h3>
                <p class="cus-typo">{{punto_forza.description}}</p>
            </div>
        </div>
    </div>
</section>