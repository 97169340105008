<div class="login-page">
	<div class="container">
		<div class="inner-login-content">
			<div class="login-logo mb-2" data-aos="fade-up" data-aos-delay="100">
				<a class="back-page cursor-pointer" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></a>
				<a (click)="closeModal()">
					<app-logo></app-logo>
				</a>
			</div>
			<div class="login-title" data-aos="fade-up" data-aos-delay="100">
				<h3>{{ title }}</h3>
			</div>
            <div class="row" id="rich_text_element" [innerHtml]="rich_text"></div>
		</div>
	</div>
</div>