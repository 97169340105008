import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-professione-card',
  templateUrl: './professione-card.component.html',
  styleUrls: ['./professione-card.component.css']
})
export class ProfessioneCardComponent {
  @Input() professione:any = {
    public_token: "",
    name: "",
    configuration: {
      description: ""
    }
  };
  @Input() box:any = false;

  window:any=<any>window;
  AOS:any=<any>this.window.AOS;
  $:any=<any>this.window.$;
  Typed:any=<any>this.window.Typed;
  changeQueryParam:any = (values:any = {}) => new Object({ ...this.route.snapshot.queryParams, ...values });
  queryparams:any = () => this.route.snapshot.params;

  constructor(
    private route:ActivatedRoute,
    private router:Router
  ) {};

  getPicture = (pic:any) => `${this.window.config.urls.url_media}/public/${pic}`;

}