<div class="login-page">
	<div class="container">
		<div class="inner-login-content">
			<div class="login-logo mb-2" data-aos="fade-up" data-aos-delay="100">
				<a class="back-page cursor-pointer" (click)="switchModal()"><i class="fa fa-long-arrow-left"></i></a>
				<a (click)="closeModal()">
					<app-logo></app-logo>
				</a>
			</div>
			<div class="login-title" data-aos="fade-up" data-aos-delay="100">
				<h3>Benvenuto, <br> Registrati per accedere</h3>
			</div>
			<form class="login-form" action="" data-aos="fade-up" data-aos-delay="100">
				<!--<div class="form-group">
					<label>Username</label>
					<input type="text" class="form-control" id="register-username" name="register-username" placeholder="Username" [(ngModel)]="register_model.username">
				</div>-->
				<div class="form-group">
					<label>Email*</label>
					<input type="text" class="form-control" id="register-email" name="register-email" placeholder="Email" [(ngModel)]="register_model.email">
				</div>
				<div class="form-group">
					<label>Password*</label>
					<input type="password" class="form-control" id="register-password" name="register-password" placeholder="Password" [(ngModel)]="register_model.password">
				</div>
				<div class="form-group">
					<label>Conferma password*</label>
					<input type="password" class="form-control" id="register-confirm-password" name="register-confirm-password" placeholder="Conferma" [(ngModel)]="register_model.confirm_password">
				</div>
				<div class="form-group">
					<label>Persona fisica / persona giuridica</label>
					<select class="form-control" [(ngModel)]="model.type" id="tipologia" name="tipologia">
						<option value="fisica">Persona fisica</option>
						<option value="giuridica">Persona giuridica</option>
					</select>
				</div>

				<!-- SE AZIENDA -->
				<div class="form-group" [hidden]="model.type != 'giuridica'">
					<label>Ragione sociale*</label>
					<input type="text" class="form-control" id="ragione_sociale" name="ragione_sociale" placeholder="Ragione sociale" [(ngModel)]="model.ragione_sociale">
				</div>
				<div class="form-group" [hidden]="model.type != 'giuridica'">
					<label>Partita IVA*</label>
					<input type="text" class="form-control" id="p_iva" name="p_iva" placeholder="Partita IVA" [(ngModel)]="model.p_iva">
				</div>

				<!-- SE UTENTE PRIVATO -->
				<div class="form-group" [hidden]="model.type != 'fisica'">
					<label>Nome*</label>
					<input type="text" class="form-control" id="nome" name="nome" placeholder="Nome" [(ngModel)]="model.nome">
				</div>
				<div class="form-group" [hidden]="model.type != 'fisica'">
					<label>Cognome*</label>
					<input type="text" class="form-control" id="cognome" name="cognome" placeholder="Cognome" [(ngModel)]="model.cognome">
				</div>
				<!--<div class="form-group" [hidden]="model.type != 'fisica'">
					<label>Codice fiscale</label>
					<input type="text" class="form-control" id="codice_fiscale" name="codice_fiscale" placeholder="Codice fiscale" [(ngModel)]="model.cf">
				</div>-->

				<!--<div class="form-group">
					<label>Indirizzo email</label>
					<input type="email" class="form-control" id="email" name="email" placeholder="Email" [(ngModel)]="register_model.email">
				</div>-->
				<div class="form-group">
					<label>Telefono</label>
					<input type="number" class="form-control" id="telefono" name="telefono" placeholder="Telefono" [(ngModel)]="model.telefono">
				</div>
				<!--<div class="form-group">
					<label>Indirizzo</label>
					<input type="text" class="form-control" id="indirizzo" name="indirizzo" placeholder="Indirizzo" [(ngModel)]="model.indirizzo">
				</div>
				<div class="form-group">
					<label>Cap</label>
					<input type="number" class="form-control" id="cap" name="cap" placeholder="Cap" [(ngModel)]="model.cap">
				</div>
				<div class="form-group">
					<label>Citta</label>
					<input type="text" class="form-control" id="citta" name="citta" placeholder="Città" [(ngModel)]="model.citta">
				</div>
				<div class="form-group">
					<label>Provincia</label>
					<input type="text" class="form-control" id="provincia" name="provincia" placeholder="Provincia" [(ngModel)]="model.provincia">
				</div>-->

				<!-- SELECT DELLE PROFESSIONI -->
				<div class="form-group">
					<label>Professione</label>
					<select class="form-control" [(ngModel)]="model.professione" id="professione" name="professione">
						<option [value]="p.public_token" *ngFor="let p of getProfessioni(); index as i">{{p.name}}</option>
					</select>
				</div>

				<div class="form-group">
					<button type="button" class="btn btn-primary" (click)="register()">Registrati</button>
				</div>
			</form>
		</div>
	</div>
</div>