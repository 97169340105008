<div class="lg-modal-custom first position-absolute" id="addclientModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="closeModal()"><i class="fa fa-long-arrow-left"></i></button>
            </div>
            <div class="modal-body custom-product-modal">
                <section class="title-bar insurance-bar space-remove">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-7 left-text">
                                <h2>{{ window.dictionary.get('info_preventivo_modal') + " - " + getStatus() }}</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="sec-popup-boxes mt-4">
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-6 col-sm-12">
                                <label for="client_name">{{ window.dictionary.get('pacchetto_richiesto') }}</label>
                                <span class="form-control">{{ getPreventivo()?.pacchetto?.name }}</span>
                            </div>
                            <div class="form-group col-md-6 col-sm-12">
                                <label for="client_name">{{ window.dictionary.get('data_creazione_preventivo') }}</label>
                                <span class="form-control">{{ window.moment(getPreventivo()?.creation_timestamp).format('DD-MM-YYYY') }}</span>
                            </div>
                        </div>
                    </form>
                </section>

                <section class="sec-popup-text sec-popup-boxes mt-5">
                    <h2>{{ window.dictionary.get('garanzie_indispensabili_richieste') }}</h2>
                    
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-sm-12 aos-init aos-animate sm-box" *ngFor="let table of getPreventivo().table_selected_indispensabili; index as i" data-aos="fade-up" data-aos-delay="100">
                            <app-garanzia-card [pt_garanzia]="table.public_token" [preventivo_info]="table"></app-garanzia-card>
                        </div>
                    </div>

                </section>

                <section class="sec-popup-text sec-popup-boxes mt-5" [hidden]="!getPreventivo().table_selected_opzionali.length">
                    <h2>{{ window.dictionary.get('garanzie_opzionali_richieste') }}</h2>
                    
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-sm-12 aos-init aos-animate sm-box" *ngFor="let table of getPreventivo().table_selected_opzionali; index as i" data-aos="fade-up" data-aos-delay="100">
                            <app-garanzia-card [pt_garanzia]="table.public_token" [preventivo_info]="table"></app-garanzia-card>
                        </div>
                    </div>
                </section>

                <section class="sec-popup-text sec-popup-boxes mt-5">
                    <h2>{{ window.dictionary.get('questionario') }}</h2>
                    <div class="col-12 d-inline-block mb-4" *ngFor="let questionario of getFormattedQuestionario(); let i = index;">
                        <div class="card round-card">
                            <div class="card-body">
                                <div class="card-head d-flex"></div>
                                <h5>{{ questionario?.name }}</h5>
                                <div *ngFor="let question of questionario.questions; let i = index;" class="mb-2">
                                    <p class="card-text m-0 mb-1"><b>{{ question?.title }}</b></p>
                                    <p class="card-text ">{{ question?.answer }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="sec-popup-text sec-popup-boxes" *ngIf="!!getPreventivo()?.templates?.length">
                    <h2>{{ window.dictionary.get('documenti_precompilati') }}</h2>
                    <div class="row">
                        <div class="col-12 col-md-4 sm-box" *ngFor="let doc of getPreventivo()?.templates; index as i">
                            <a [href]="downloadPdf(doc)" target="_blank"><h3><i class="text-3xl fal pr-3 fas fa-paperclip"></i> {{ window.dictionary.get('documento') + " " + (i+1) }}</h3></a>
                        </div>
                    </div>
                </section>

                <section class="sec-popup-text sec-popup-boxes" *ngIf="!!getPreventivo()?.documents?.length">
                    <h2>{{ window.dictionary.get('documenti_agenzia') }}</h2>
                    <div class="row">
                        <div class="col-12 col-md-4 sm-box" *ngFor="let doc of getPreventivo()?.documents; index as i">
                            <a [href]="getLink(doc)" target="_blank"><h3><i class="text-3xl fal pr-3 fas fa-paperclip"></i> {{ doc.file_name || window.dictionary.get('documento') }}</h3></a>
                        </div>
                    </div>
                </section>

                <button class="btn btn-danger float-left mt-3" (click)="closeModal()">{{ window.dictionary.get('chiudi') }}</button>
                <!--<button class="btn btn-primary float-right mt-3" [hidden]="!canBuy()" (click)="acquista()">{{ window.dictionary.get('acquista') }}</button>-->
            </div>
        </div>
    </div>
</div>