import { Component } from '@angular/core';

@Component({
  selector: 'app-info-preventivo',
  templateUrl: './info-preventivo.component.html',
  styleUrls: ['./info-preventivo.component.css']
})
export class InfoPreventivoComponent {
    window:any=<any>window;
    $:any=<any>this.window.$;

    constructor() {
        this.window.plussimple_signal.signaller.addEventListener('open-info-preventivo', this.openModal.bind(this));
    }

    openModal = () => {
        this.$("app-info-preventivo").fadeIn(300);
    };
    closeModal = () => {
        this.$("app-info-preventivo").fadeOut(300);
    };
}