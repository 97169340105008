import { Component } from '@angular/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent {
  window:any=<any>window;
  notification:any = new this.window.HPNotification({parent: 'app-signup'});
  type:any = "login";
  $:any=<any>this.window.$;

  model:any = {
    type: 'fisica',
    nome: '',
    cognome: '',
    ragione_sociale: '',
    cf: '',
    p_iva: '',
    telefono: '',
    indirizzo: '',
    cap: '',
    provincia: '',
    citta: '',
    professione: ''
  };
  professioni:any = [{
    name: "Commercialista",
    public_token: "pr0"
  }, {
    name: "Avvocato",
    public_token: "pr1"
  }, {
    name: "Architetto",
    public_token: "pr2"
  }, {
    name: "Geologo",
    public_token: "pr3"
  }, {
    name: "Avvocato di stato",
    public_token: "pr4"
  }, {
    name: "Ingegnere",
    public_token: "pr5"
  }, {
    name: "Geometra",
    public_token: "pr6"
  }, {
    name: "Agente immobiliare",
    public_token: "pr7"
  }, {
    name: "Amministratore di condominio",
    public_token: "pr8"
  }, {
    name: "Agronomo agrotecnico",
    public_token: "pr9"
  }, {
    name: "Perito industriale",
    public_token: "pr10"
  }, {
    name: "Medico",
    public_token: "pr11"
  }, {
    name: "Altro",
    public_token: "pr12"
  }];
  register_model:any = {
    public_token_manager: this.window.config.public_token_parent,
    username: "",
    password: "",
    confirm_password: "",
    email: ""
  };

  constructor() {}

  openModal = () => this.$("app-signup").fadeIn(300);
  closeModal = () => this.$("app-signup").fadeOut(300);

  switchModal = () => {
    this.closeModal();
    this.$("app-login").show();
  };

  getProfessioni = () => this.professioni;

  getTitle = () => this.window.dictionary.get('registrati_sistema');

  check = () => {
    if(!this.register_model.email) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_email')});
        return false;
      }
    if(!this.register_model.password || !this.register_model.confirm_password || this.register_model.password != this.register_model.confirm_password) {
      this.notification.danger({text: this.window.dictionary.get('password_non_coincidono')});
      return false;
    }
    if(this.model.type == 'fisica') {
      if(!this.model.nome) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_nome')});
        return false;
      }
      if(!this.model.cognome) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_cognome')});
        return false;
      }
      /*if(!this.model.cf) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_codice_fiscale')});
        return false;
      }*/
    }
    if(this.model.type == 'giuridica') {
      if(!this.model.ragione_sociale) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_ragione_sociale')});
        return false;
      }
      if(!this.model.p_iva) {
        this.notification.danger({text: this.window.dictionary.get('inserisci_partita_iva')});
        return false;
      }
    }
    /*if(!this.model.telefono) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_telefono')});
      return false;
    }
    if(!this.model.indirizzo) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_indirizzo')});
      return false;
    }
    if(!this.model.cap) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_cap')});
      return false;
    }
    if(!this.model.provincia) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_provincia')});
      return false;
    }
    if(!this.model.citta) {
      this.notification.danger({text: this.window.dictionary.get('inserisci_citta')});
      return false;
    }
    if(!this.register_model.username || this.register_model.length < 4) {
      this.notification.danger({text: this.window.dictionary.get('username_corto')});
      return false;
    }*/
    return true;
  };

  register = async () => {
    if(!this.check()) return;
    try {
        this.register_model.username = this.register_model.email;
        await this.window.usher.register({
            username: this.register_model.username,
            password: this.register_model.password,
            token_manager: this.register_model.public_token_manager,
            email: this.register_model.email,
            configuration: this.model,
            public_configuration: {
                type: "cliente"
            }
        });
        this.initModel();
        this.notification.success({text: this.window.dictionary.get('registrazione_successo')});
        return this.closeModal();
    } catch(e) {
        console.log(e);
        return this.notification.danger({text: this.window.dictionary.get('errore_generico')});
    }
  };

  initModel = () => {
    this.register_model = {
      public_token_manager: this.window.config.public_token_parent,
      username: "",
      password: "",
      confirm_password: "",
      email: ""
    };
    this.model = {
      type: 'fisica',
      nome: '',
      cognome: '',
      ragione_sociale: '',
      cf: '',
      p_iva: '',
      telefono: '',
      indirizzo: '',
      cap: '',
      provincia: '',
      citta: '',
      professione: ''
    };
  };
}
