import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css']
})
export class ModalConfirmComponent {
  window:any=<any>window;
  notification:any = new this.window.HPNotification({parent: 'app-modal-confirm'});
  $:any=<any>this.window.$;

  queryparams:any = () => decodeURI(this.window.location.href)
    .split('?')[1]?.split('&')
    .map(param => param.split('='))
    .reduce((values, [ key, value ]) => {
      values[ key ] = value
      return values
    }, {}) || {};

  changeQueryParam:any = (values:any = {}) => new Object({ ...this.queryparams(), ...values });

  constructor() {}

  confirm = async () => {
    if(!this.queryparams()['id_cliente']) return this.notification.danger({text: this.window.dictionary.get('cliente_non_trovato')});
    try {
      let index = this.window.usher.get().public_configuration.clients?.findIndex(e => e.id == this.queryparams()['id_cliente']);
      if(!index || index < 0) return this.notification.danger({text: this.window.dictionary.get('cliente_non_trovato')});
      this.window.usher.get().public_configuration.clients?.splice(index, 1);
      await this.window.usher.get().update();
      this.notification.success({text: this.window.dictionary.get('cliente_eliminato')});
      return this.closeModal();
    } catch(e) {
      console.log(e);
      return this.notification.danger({text: this.window.dictionary.get('errore_generico')});
    }
  }

  openModal = () => this.$("app-modal-confirm").fadeIn(300);
  closeModal = () => this.$("app-modal-confirm").fadeOut(300);
}